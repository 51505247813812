export default {
  "signStr": "1001",
  "tab": {
    "my": "saya",
    "home": "rumah",
    "record": "rekod",
    "grab": "rampasan",
    "financing": "pembiayaan",
    "make_money": "rampasan"
  },
  "newTab": {
    "home": "rumah",
    "money": "wang",
    "shop": "kedai",
    "order": "pesanan",
    "my": "saya",
    "team": "pasukan"
  },
  "common": {
    "unrealized": "dalam pergerakan",
    "all": "semua",
    "distributionTip": "0 pengagihan 0 yuran pengedaran",
    "easeBack": "kembali",
    "addCarSuccess": "berjaya ditambah ke keranjang beli-beli",
    "deleteConfirm": "Adakah anda pasti ingin membuang item yang dipilih?"
  },
  "My": {
    "download": "Muat turun apl",
    "personal": "Maklumat peribadi",
    "preOrde": "Rekod tempahan awal",
    "account": "Butiran akaun",
    "recharge": "Rekod tambah nilai",
    "withdrawal": "Pengeluaran",
    "invite": "Jemput rakan-rakan",
    "announcement": "Pengumuman",
    "service": "Perkhidmatan",
    "team": "Laporan pasukan",
    "quit": "Keluar",
    "logoutEvent": "Sahkan daftar keluar?",
    "language": "Tetapan bahasa",
    "languageButton": {
      "english": "Bahasa Inggeris",
      "indonesian": "Bahasa Indonesia"
    },
    "logout": "Log keluar",
    "MemberInformation": {
      "SilverMember": "Ahli Perak",
      "Invitation": "Kod jemputan",
      "balance": "Baki akaun",
      "Recharge": "Tambah nilai",
      "Withdraw": "Pengeluaran",
      "forzenAll": "Dana beku"
    },
    "PersonalInformation": {
      "Page": {
        "title": "Maklumat saya",
        "Avatar": "Avatar",
        "Nickname": "Nama samaran",
        "PhoneNumber": "Nombor telefon",
        "BankAccounts": "Butiran dompet saya",
        "Password": "Kata laluan",
        "TransactionPassword": "Kata laluan transaksi",
        "ReceivingAddress": "Alamat penerimaan"
      },
      "portrait": {
        "SetAvatar": "Tetapkan avatar",
        "button": "Tetapkan sekarang"
      },
      "Name": {
        "ModifyNickname": "Ubah nama samaran",
        "title": "Ubah nama samaran anda",
        "name": "Nama samaran",
        "Modify": "Ubah",
        "Please": "Sila masukkan nama samaran"
      },
      "BankAccounts": {
        "BankAccounts": "Kad bank",
        "YourInformation": "Maklumat anda",
        "RealName": "Nama sebenar",
        "RealNameNote": "Sila tetapkan nama sebenar anda",
        "PhoneNo": "Nombor telefon",
        "PhoneNoNote": "Sila tetapkan nombor telefon",
        "YourBankCard": "Maklumat kad bank anda",
        "BankAccount": "Akaun bank",
        "BankAccountNote": "Sila tetapkan akaun bank anda",
        "BankName": "Nama bank",
        "BankNameData": {
          "option1": "Pilih bank akaun",
          "option2": "Bank Asia Tengah",
          "option3": "Bank Negara Indonesia",
          "option4": "Bank Rakyat Indonesia",
          "option5": "Bank Mandiri"
        },
        "BankBranch": "Cawangan bank",
        "BankBranchNote": "Sila tetapkan cawangan bank anda",
        "MoreInformation": "Maklumat lanjut",
        "WhatsApp": "WhatsApp",
        "WhatsAppNote": "Sila tetapkan nombor WhatsApp anda",
        "Text": "Ingatan: Halo, untuk menjamin hak anda, sila ikat nombor kad dan maklumat bank yang betul. Jika akaun tidak diterima kerana pengisian yang salah, platform ini tidak bertanggungjawab!",
        "Settings": "Tetapan"
      },
      "Password": {
        "title": "Ubah kata laluan log masuk",
        "PleaseEnter": "Sila masukkan kata laluan baharu anda",
        "phone": "Telefon",
        "NewPhone": "Sila masukkan nombor telefon bimbit anda",
        "verificationCode": "Kod pengesahan",
        "verificationCodeNote": "Sila masukkan kod pengesahan",
        "oldPassword": "Kata laluan lama",
        "oldPasswordNote": "Masukkan kata laluan lama anda",
        "ConfirmPassword": "Kata laluan baharu",
        "ConfirmPasswordNote": "Masukkan kata laluan baharu anda",
        "Text": "Sila simpan kata laluan anda, jika anda lupa kata laluan anda, sila hubungi perkhidmatan pelanggan.",
        "ButtonCode": "Dapatkan kod pengesahan",
        "Button": "Baik untuk mengubah suai",
        "verifyPhone": "Sila masukkan nombor telefon bimbit anda",
        "PhoneNew": "Sila sahkan bahawa anda telah selesai"
      },
      "Site": {
        "title": "Dapatkan perubahan alamat",
        "ReceivingIdentity": "Dapatkan maklumat identiti",
        "ReceivingAddress": "Dapatkan maklumat alamat",
        "DetailedAddress": "Alamat terperinci",
        "DetailedAddressNote": "Sila masukkan alamat terperinci",
        "Set": "Tetapkan sebagai alamat biasa",
        "Button": "Sahkan",
        "hint": "Sila sahkan bahawa anda telah selesai"
      },
      "AccountData": {
        "title": "Butiran transaksi",
        "To": "Kepada",
        "search": "Carian",
        "Cancel": "Batal",
        "Semua": "Semua jenis",
        "Penarikan": "Nota pengeluaran",
        "IsiUlang": "Mutasi Top-up"
      },
      "RechargeRecord": {
        "title": "Rekod Top-up",
        "Recharge": "Top-up luar talian",
        "Online": "Top-up dalam talian",
        "Amount": "Jumlah Top-up:",
        "Order": "Nombor pesanan:",
        "Status": "Status Top-up:"
      },
      "WithdrawalRecord": {
        "title": "Rekod Pengeluaran",
        "withdrawal": "Pengeluaran kad bank"
      },
      "InviteFriends": {
        "Invite": "Pautan: Klik untuk salin pautan",
        "InvitationCode": "Kod jemputan:",
        "Click": "Salin pautan"
      },
      "Announcement": {
        "title": "Pengumuman",
        "System": "Pengumuman sistem"
      },
      "TheTeamReport": {
        "title": "Laporan Pasukan",
        "To": "Kepada",
        "search": "Carian",
        "Cancel": "Batal",
        "balance": "Baki pasukan",
        "turnover": "Pasukan Top-up",
        "recharge": "Keuntungan Pesanan Pasukan",
        "withdrawal": "Pengeluaran pasukan",
        "Commission": "Keuntungan Pesanan Pasukan",
        "charge": "Jumlah pengguna",
        "people": "Bilangan pengguna yang membuat Top-up",
        "members": "Bilangan pengguna baharu",
        "NewPeople": "Pengguna baharu Top-up",
        "Active": "Bilangan pengguna baharu",
        "Level1": "Peringkat 1",
        "Level2": "Peringkat 2",
        "Level3": "Peringkat 3"
      }
    },
    "PlatformIntroduction": {
      "PlatformIntroduction": "Pengenalan platform",
      "Two": "Platform ini terdiri daripada gabungan jutaan pedagang. Prinsip perkhidmatannya adalah: pedagang platform meningkatkan jumlah dagangan, dan ahli platform memperoleh pendapatan Keuntungan dengan menerima pesanan.",
      "Three": "Awan Pintar pihak ketiga mengawal platform padanan pesanan hos. Platform bekerjasama dengan pedagang platform membeli-belah dalam talian lain untuk padankan pesanan perniagaan dengan pengguna platform melalui sistem awan pintar, untuk meningkatkan jumlah transaksi dan reputasi penjual di platform utama. Kaedah penghantaran pesanan pembelian diperbaiki. Pesanan dihantar secara automatik oleh pelayan awan pintar jarak jauh. Pengguna platform hanya perlu memberikan alamat penerima lengkap, nombor telefon dan beberapa maklumat membeli-belah secara langsung di platform untuk memasuki hos awan pintar.",
      "Four": "Untuk membantu penjual berkesan meningkatkan kadar kejayaan pesanan dan mengelakkan risiko penjual memeriksa pesanan palsu, platform akan mengoptimumkan padanan pesanan harian mengikut IP operasi semasa dan jenis peralatan anda. Oleh itu, padanan pesanan perlu direalisasikan oleh algoritma pengkomputeran awan pintar. Sila tunggu dengan sabar. Pengguna platform akan menjadi ejen platform secara automatik dengan menggalakkan orang baru menyertai platform, dan mereka boleh mendapat Keuntungan tambahan.",
      "Five": "Anda hanya perlu menggunakan masa lapang anda untuk melayari Internet, dan anda boleh dengan mudah memperoleh Keuntungan yang kaya setiap hari. Selepas anda mengklik dan berjaya menghantar pesanan, modal dan Keuntungan akan dikembalikan."
    },
    "RuleDescription": {
      "RuleDescription": "Penerangan Peraturan",
      "One": "1. Ahli mesti mengisi alamat penerima dan memperluaskan maklumat peribadi sebelum memesan. Komisen ahli berbeza mengikut platform yang berbeza. Bilangan pesanan yang dijana oleh sistem adalah berdasarkan 30% -70% baki akaun anda.",
      "Two": "2. Memandangkan masa tertentu diperlukan untuk melaksanakan pesanan barang, pesanan hanya boleh diterima daripada penjual selepas pesanan sah, oleh itu ahli perlu melengkapkan komisen platform dan bank trilateral dan menggunakan sistem T + 1, iaitu untuk mengambil wang kembali hari ini dan wang akan diterima sebelum 23:59 hari berikutnya. Jika pesanan gagal untuk berada dalam masa yang ditetapkan, platform akan membayar wang, melengkapkan akaun dan mengeluarkan pembayaran dalam tempoh yang ditetapkan.",
      "Three": "3. Nisbah status dewan yang sesuai dan komisen ahli perak: Semua ahli perak boleh mengambil 10 pesanan sehari, dan komisen untuk pesanan adalah 0.30% daripada jumlah barangan.",
      "Four": "Ahli Emas: Untuk membuka menjadi ahli emas, anda perlu menjemput 3 orang memasuki dewan ini. Anda boleh mengambil 35 pesanan sehari, dan komisen untuk pesanan adalah 0.35% daripada jumlah barangan.",
      "Five": "Ahli Platinum: Untuk membuka menjadi ahli platinum, anda perlu menjemput 10 orang memasuki dewan ini. Anda boleh mengambil 40 pesanan sehari, dan komisen untuk pesanan adalah 0.40% daripada jumlah barangan.",
      "Six": "Ahli Platinum: Untuk membuka menjadi ahli platinum, anda perlu menjemput 10 orang memasuki dewan ini. Anda boleh mengambil 40 pesanan sehari, dan komisen untuk pesanan adalah 0.40% daripada jumlah barangan.",
      "Seven": "Ahli Berlian: Untuk membuka menjadi ahli berlian, anda perlu menjemput 30 orang memasuki dewan ini. Anda boleh mengambil 45 pesanan sehari, dan komisen untuk pesanan-pesanan ini adalah 0.45% daripada jumlah barangan.",
      "Eight": "4. Sila semak nombor kad deposit platform sebelum mengisi dan platform tidak akan menukar nombor akaun dengan sewenang-wenangnya. Platform tidak akan bertanggungjawab atas kerugian harta benda disebabkan oleh kesilapan pengendalian peribadi.",
      "Nine": "Nota: Sila rujuk kepada platform untuk melihat sama ada platform ini adalah untuk mengelakkan kerugian kewangan anda untuk setiap pemindahan.",
      "Ten": "5. Untuk melindungi keuntungan antara penjual dan platform, maka setiap IP, nombor kad bank dan nombor telefon bimbit hanya boleh digunakan untuk satu akaun sahaja. Semua platform e-niaga utama mempunyai mesin kawalan risiko, jika terdapat beberapa pengguna di kawasan yang sama dan menggunakan IP yang sama yang membeli dari kedai yang sama, kedai tersebut akan menyahaktifkan produk yang boleh meletup dan akan menjejaskan kredibiliti penjual dan jualan.",
      "Eleven": "Jika seseorang mengemukakan permohonan untuk beberapa akaun, pelanggar akan membekukan akaun-akaun itu secara tetap dan membekukan baki untuk tempoh 30 hari. Apabila anda perlu mendapatkan semula baki yang dibekukan (pada akhir tempoh), sila hubungi khidmat pelanggan kami dalam talian untuk mengaktifkan semula akaun untuk mendapatkan kembali.",
      "OneData": {
        "One": "Penerangan Tugas:",
        "Two": "Jenis Tugas Takrif Komisyen",
        "Three": "LAZADA 0.30%",
        "Four": "BUKALAPAK 0.35%",
        "Five": "SHOPEE 0.40%",
        "Six": "TOKOPEDIA 0.45%"
      },
      "TwoData": {
        "One": "Butiran Tahap Keahlian:",
        "Two": "Jenis Keahlian Ahli Perak",
        "Three": "Had Pengeluaran 50,000,000",
        "Four": "Bilangan Pesanan 30/hari",
        "Five": "Tahap Komisen 0.30%",
        "Six": "Jemput -"
      },
      "FourData": {
        "One": "Jenis Keahlian Ahli Platinum",
        "Two": "Had Pengeluaran 50,000,000",
        "Three": "Bilangan Pesanan 40/hari",
        "Four": "Tahap Komisen 0.40%",
        "Five": "Jemput 10"
      },
      "FiveData": {
        "One": "Jenis Keahlian Ahli Diamond",
        "Two": "Had Pengeluaran 50,000,000",
        "Three": "Bilangan Pesanan 45/hari",
        "Four": "Tahap Komisen 0.45%",
        "Five": "Jemput 30"
      },
      "Twelve": "*Pelbagai tahap mungkin mempunyai nombor ganjil dan nisbah komisen yang berbeza. Semakin tinggi tahap keahlian, semakin banyak nombor ganjil yang anda boleh ambil. Semakin banyak pesanan, semakin tinggi komisen."
    },
    "AgentCooperation": {
      "AgentCooperation": "Kerjasama Agen",
      "One": "Lancarkan standard peringkat ketiga untuk proksi",
      "Two": "Ahli boleh menjadi platform proksi dengan mengesyorkan anggota baru di mana mereka akan menyertai untuk menerima ganjaran tambahan.",
      "Three": "Galakkan ganjaran pengguna peringkat pertama untuk mendapatkan 9% bonus komisen harian. Ganjaran pengguna peringkat kedua ialah 6% bonus komisen harian, atau 3% bonus komisen harian.",
      "Four": "Sebagai contoh:",
      "Five": "Proksi peringkat pertama anda telah mengundang 10 orang menyertai sebagai ahli, proksi peringkat kedua telah mengundang 20 orang menyertai sebagai ahli dan proksi peringkat ketiga telah mengundang 40 orang menyertai sebagai ahli. Anggap penghasilan harian setiap orang ialah 200,000 INR, jadi anda boleh bayangkan pendapatan harian yang anda akan terima.",
      "Six": "Peringkat pertama: 200,000 x 10 x 9% = 180,000 INR",
      "Seven": "Peringkat kedua: 200,000 x 20 x 6% = 240,000 INR",
      "Eight": "Peringkat ketiga: 200,000 x 40 x 3% = 240,000 INR",
      "Nine": "Peringkat pertama + peringkat kedua + peringkat ketiga = 660,000 INR",
      "Ten": "Katakan anda mengundang ahli untuk mendaftar, di mana A mengundang B, dan B mengundang C,",
      "Eleven": "Maka ahli A adalah pengguna peringkat pertama anda, B adalah pengguna peringkat kedua anda, dan C adalah pengguna peringkat ketiga anda.",
      "Twelve": "Jumlah ganjaran = Komisen Pesanan A x 9% + Komisen Pesanan B x 6% + Komisen Pesanan C x 3%.",
      "Thirteen": "*Ingatan: Semua komisen ganjaran untuk semua agen yang adalah ahli luar talian yang diterbitkan oleh platform ini, dan tidak akan menjejaskan komisen semua agen dan ahli luar talian!"
    },
    "CommonProblem": {
      "CommonProblem": "Masalah umum",
      "One": "Soalan: Mengapa perniagaan memerlukan arahan maya?",
      "Two": "Mengikut peraturan platform e-dagang utama, semakin banyak orang membeli produk, semakin popular produk dan semakin banyak peluang yang akan dipromosikan dan ditunjukkan oleh platform e-dagang. Perniagaan e-dagang adalah menakutkan. Untuk meningkatkan risiko produk, peniaga perlu meningkatkan jumlah transaksi komoditi, oleh itu mereka memilih untuk menghasilkan arahan maya di platform.",
      "Three": "Soalan: Bagaimana anda melengkapkan proses memesan?",
      "Four": "Pilih saluran E-Niaga, klik secara automatik untuk memesan, sistem memadankan produk untuk menerima pesanan dengan bijak. Platform menggunakan maklumat peribadi anda sebenar, alamat penghantaran dan IP rangkaian untuk menghantar pesanan ke platform e-dagang untuk meniru membeli-belah dalam talian. Walau bagaimanapun, peniaga sebenarnya tidak menghantar barang mereka. Selepas menghantar pesanan berjaya, jumlah bayaran utama dan komisen pesanan akan dikreditkan semula ke akaun anda.",
      "Five": "Soalan: Mengapa saya perlu mengisi semula sebelum menerima pesanan?",
      "Six": "Untuk memastikan kesahihan pesanan, setiap pesanan simulasi memerlukan pembayaran sebenar ke laman web E-Niaga. Untuk melindungi kepentingan platform dan peniaga, baki akaun anda akan berkurang apabila anda menghantar pesanan, dan akan dikembalikan selepas pesanan selesai.",
      "Seven": "Soalan: Bagaimana cara meningkatkan jumlah pesanan dan komisen?",
      "Eight": "Dengan mengundang rakan-rakan anda untuk mendaftar, anda akan mendapat tahap yang lebih tinggi, jumlah pesanan harian yang lebih banyak, dan nisbah komisen yang lebih tinggi; sistem secara automatik sepadan antara 30% hingga 70% baki akaun anda dengan jumlah pesanan, oleh itu, baki akaun yang lebih tinggi, jumlah pesanan yang lebih tinggi dan komisen yang lebih tinggi akan semakin baik.",
      "Nine": "Soalan: Bagaimana cara untuk mengundang rakan-rakan untuk mendaftar?",
      "Ten": "Klik pada 'Undang Rakan- rakan', salin pautan perkongsian atau kod QR, dan hantar melalui aplikasi media sosial kepada rakan-rakan, rakan sekelas, rakan sekerja dan keluarga anda.Anda perlu mengisi kod jemputan untuk pendaftaran di platform ini, dan pihak lain akan menjadi ahli pasukan anda selepas mendaftar.",
      "Eleven": "Soalan: Bagaimana cara mengeluarkan tunai?",
      "Twelve": "Anda boleh mendepositkan pengeluaran tunai selepas memenuhi jumlah pesanan minima.Jika jumlah pesanan minima tidak dipenuhi, teruskan sahaja sehingga keperluan dipenuhi.Selepas kelulusan pengembalian, platform akan mendepositkan wang ke akaun bank yang ditetapkan, dan masa resit yang betul bergantung pada bank anda."
    },
    "MemberUpgrade": {
      "MemberUpgrade": "Naik Taraf Keahlian",
      "Member": "Tahap Keahlian :",
      "Orders": "Arahan Harian yang Tersedia:",
      "Commission": "Peningkatan Komisen",
      "DedicatedOne": "Perkhidmatan Pelanggan Dedikasi",
      "DedicatedTwo": "Perkhidmatan Pelanggan Dedikasi",
      "Swipe": "Seret ke kiri dan kanan untuk melihat lebih banyak",
      "One": {
        "Silver": "Ahli Perak",
        "Need": "Jemputan diperlukan untuk 0 orang",
        "Withdrawal": "Had Pengeluaran: 50.000.000",
        "quantity": "Kuantiti Arahan: 10/hari",
        "rate": "Kadar Komisen: 0.30%",
        "fee": "Caj Pemulangan: 2.00%",
        "Activated": "Diaktifkan"
      },
      "Two": {
        "Silver": "Ahli Emas",
        "Need": "Jemputan diperlukan untuk 3 orang",
        "People": "Masih kurang 3 orang",
        "Withdrawal": "Had Pengeluaran: 50.000.000",
        "quantity": "Kuantiti Arahan: 35/hari",
        "rate": "Kadar Komisen: 0.35%",
        "fee": "Caj Pemulangan: 2.00%",
        "Activated": "Amaun Top-Up Harian: 1.000.000",
        "Invite": "Jemput sekarang"
      },
      "Three": {
        "Silver": "Ahli Platinum",
        "Need": "Jemputan diperlukan untuk 10 orang",
        "People": "Masih kurang 10 orang",
        "Withdrawal": "Had Pengeluaran: 50.000.000",
        "quantity": "Kuantiti Arahan: 40/hari",
        "rate": "Kadar Komisen: 0.40%",
        "fee": "Caj Pemulangan: 2.00%",
        "Activated": "Amaun Top-Up Harian: 1.500.000",
        "Invite": "Jemput sekarang"
      },
      "Four": {
        "Silver": "Ahli Bintang",
        "Need": "Jemputan diperlukan untuk 30 orang",
        "People": "Masih kurang 30 orang",
        "Withdrawal": "Had Pengeluaran: 50.000.000",
        "quantity": "Kuantiti Arahan: 45/hari",
        "rate": "Kadar Komisen: 0.45%",
        "fee": "Caj Pemulangan: 2.00%",
        "Activated": "Amaun Top-Up Harian: 2.000.000",
        "Invite": "Jemput sekarang"
      }
    },
    "PhoneNew": "Sila sahkan anda telah selesai",
    "Toast": {
      "One": "Simpan dengan berjaya",
      "Two": "Gagal"
    },
    "Button": {
      "One": "Batalkan",
      "Two": "Sahkan"
    }
  },
  "Financing": {
    "time": "Masa",
    "day": "Hari",
    "Fina": "Dana",
    "assets": "Jumlah aset",
    "amount": "Jumlah kewangan",
    "revenue": "Jumlah pendapatan",
    "earnings": "Pendapatan semalam",
    "Balance": "Baki pemindahan",
    "RP": " ",
    "Estimated": "Anggaran pendapatan",
    "Income": "Pendapatan piawai",
    "TransferOut": "Pemindahan keluar",
    "ransferIn": "Pemindahan masuk",
    "SukuBung": "Sukuk Bonga",
    "Transfer": "Pemindahan gagal",
    "Pleaseint": "Sila masukkan jumlah",
    "Successful": "Pemindahan berjaya",
    "Please": "Sila masukkan jumlah untuk pemindahan",
    "RansferInSU": {
      "FinancialAcc": "Rekod akses kewangan",
      "Acc": "Log akses"
    },
    "ServiceSu": {
      "Please": "Sila masukkan...",
      "send": "Hantar",
      "Online": "Perkhidmatan dalam talian",
      "Service": "Perkhidmatan",
      "Customer": "Khidmat pelanggan",
      "Ifyou": "Jika anda menghadapi sebarang masalah dan memerlukan bantuan, sila hubungi Khidmat Pelanggan kami secara dalam talian",
      "Layanana8": "Pusat Khidmat Pelanggan No.8 (WhatsApp)",
      "Layanana9": "Pusat Khidmat Pelanggan No.9 (WhatsApp)",
      "Dedicated": "Khidmat pelanggan yang didedikasikan",
      "Telegram8": "Layan Pelanggan No.8 (Telegram)",
      "Telegram008": "Tegervam008",
      "Telegram9": "Layan Pelanggan No.9 (Telegram)",
      "Telegram009": "Telegram009"
    }
  },
  "Home": {
    "HallofFame": "Dewan Keagungan",
    "Member": "Ahli NWES",
    "Mission": "Misi Dewan",
    "Learnde": "Belajar",
    "Hello": "Hai",
    "Let": "Pusat beli-belah ini telah membuka laluan untuk kekayaan",
    "total": "Jumlah harta saya",
    "Withdraw": "Pengeluaran",
    "Recharge": "Isi semula",
    "RP": " ",
    "earningsRp": "Perolehan semalam",
    "incomeRp": "Akumulasi pendapatan",
    "rarningsRp": "Perolehan hari ini",
    "Service": "Perkhidmatan",
    "Invite": "Jemput",
    "Lazada": "Lazada",
    "Bukalapak": "Bukalapak",
    "Shopee": "Shopee",
    "Tokopedia": "Tokopedia",
    "silver": "Saluran untuk ahli saluran",
    "gold": "Saluran untuk ahli emas",
    "platinum": "Saluran untuk ahli platinium",
    "diamond": "Saluran untuk ahli berlian",
    "Agent": "Keuntungan pesanan ejen",
    "Order": "Keuntungan pesanan",
    "RechargeSu": {
      "Recharge": "Isi semula",
      "Catatan": "Nota khas:",
      "Next": "Seterusnya",
      "RechargeAm": "Jumlah isi semula",
      "Enter": "Masukkan jumlah isi semula",
      "Quick": "Pilihan pantas",
      "nameP": "Sila masukkan nama sebenar anda",
      "name": "Nama",
      "Pleaseamount": "Sila masukkan jumlah",
      "Pleaseint": "Sila masukkan jumlah",
      "Pleasename": "Sila masukkan nama anda"
    },
    "WithdrawSu": {
      "Withdraw": "Pengeluaran",
      "Withdrawal": "Jumlah pengeluaran",
      "WithdrawalRe": "Kadar pengeluaran",
      "Input": "Input jumlah pengeluaran",
      "Balance": "Baki",
      "WithdrawAll": "Keluarkan semua",
      "Mobile": "Nombor telefon bimbit",
      "Bank": "Nombor kad bank",
      "Owned": "Bank milik",
      "Account": "Nama akaun",
      "Transaction": "Kata laluan transaksi",
      "Withdrawim": "Pengeluaran segera",
      "Pleaseamount": "Sila masukkan jumlah",
      "Pleasephone": "Sila masukkan nombor telefon anda",
      "Pleasecard": "Sila masukkan nombor kad bank anda",
      "Pleasebank": "Sila masukkan nama bank anda",
      "Pleasename": "Sila masukkan nama anda",
      "Pleasepassword": "Sila masukkan kata laluan transaksi",
      "Pleaseint": "Sila masukkan jumlah",
      "PasswordError": "Kata laluan salah",
      "Successful": "Telah memohon pengeluaran sebelum ini",
      "Insufficient": "Baki pengeluaran tidak mencukupi",
      "Unbound": "Kad bank belum terikat",
      "Binding": "Ikatlah kad bank anda",
      "idCard": "Nombor kad pengenalan"
    }
  },
  "Login": {
    "indexTitle": "Daftar Masuk",
    "remenber": "Ingat Kata Laluan",
    "versions": "V.86",
    "masuk": "Masuk",
    "name": "Muat Turun Apl",
    "forgot": "Lupa",
    "registrasi": "Daftar",
    "loginButton": "Masuk",
    "nameInput": "Masukkan Nombor Telefon",
    "pwdInput": "Masukkan Kata Laluan Anda",
    "notLoginHint": "Daftar Masuk",
    "goLoginText": "Sahkan",
    "Forgot": {
      "title": "Dapatkan Semula Kata Laluan",
      "registerTitle": "Daftar",
      "phone": "Masukkan Nombor Telefon Anda",
      "code": "Masukkan Kod Pengesahan",
      "area": "Sila Masukkan Kod Kawasan",
      "sendCode": "Hantar Kod",
      "password": "Kata Laluan",
      "againPassword": "Sahkan Kata Laluan",
      "confirm": "Hantar",
      "goBack": "Ada Akaun, Log Masuk Sekarang",
      "register": "Daftar"
    },
    "Daftar": {
      "username": "Nama Pengguna",
      "title": "Daftar",
      "area": "Sila Masukkan Kod Kawasan",
      "phone": "Masukkan Nombor Telefon",
      "code": "Masukkan Kod Pengesahan",
      "userName": "Nama Pengguna",
      "password": "Kata Laluan",
      "againPassword": "Sahkan Kata Laluan",
      "payPassword": "Kata Laluan Pembayaran",
      "referrer": "Kod Rujukan"
    }
  },
  "Cart": {
    "title": "Kedai Pesanan",
    "explain": "Penerangan",
    "grade": {
      "SliverMember": "Ahli\nPremium Perak",
      "GoldMember": "Ahli\nPremium Emas",
      "PlatinumMember": "Ahli\nPremium Platinum",
      "DiamondMember": "Ahli\nPremium Berlian"
    },
    "info": {
      "allMoney": "Jumlah\nKewangan Saya",
      "dayAllOrder": "Jumlah\nPesanan Hari Ini",
      "dayTrustMoney": "Komisen\nHari Ini",
      "yesterdayMoney": "Pendapatan\nKemarin"
    },
    "text": {
      "rebateAndPrincipal": "Rebat\n dan\n Prinsipal",
      "residue": "Baki",
      "one": "Pesanan",
      "rebate": "Rebat Pembelian",
      "buyBtn": "Beli Sekarang",
      "toGet": "Untuk Mendapatkan",
      "orderSuccess": "Pesanan Berjaya",
      "orderGetMoney": "Pembayaran Berjaya",
      "orderWaiting": "Sedang Menunggu",
      "orderOver": "Selesai"
    },
    "state": {
      "all": "Semua\nSedang diproses\nDibayar\nSelesai",
      "going": "Sedang Berlangsung",
      "paid": "Dibayar",
      "frozen": "Dibekukan",
      "over": "Selesai"
    },
    "zzInfo": {
      "buyIng": "Pembelian",
      "buySucceedFindDT": "Klik pada status setelah pembelian berjaya",
      "ok": "Ok",
      "buyFailed": "Pembelian Gagal",
      "notLevel": "Tahap VIP anda tidak mencukupi",
      "buyJurisdiction1": "Jika anda ingin membeli pesanan keahlian Emas",
      "buyJurisdiction2": "Jika anda ingin membeli pesanan keahlian Platinum",
      "buyJurisdiction3": "Jika anda ingin membeli pesanan keahlian Berlian",
      "meet": "Salah satu daripada syarat berikut perlu dipenuhi",
      "invite1": "Jemput 3 rakan untuk mengumpul wang bersama-sama",
      "invite4": "Jemput 10 rakan untuk mengumpul wang bersama-sama",
      "invite3": "Jemput 30 rakan untuk mengumpul wang bersama-sama",
      "buySendMoney": "2. Belanjakan RS2000 untuk membeli keahlian Berlian",
      "invite2": "Jemput rakan-rakan",
      "buy": "Beli Sekarang",
      "myMoney": "Baki Akaun Anda",
      "under": "di bawah",
      "notSendMoney": "Transaksi Tidak Sah",
      "goTopUp": "Sila isi semula",
      "know": "Saya tahu",
      "account": "Akaun"
    },
    "hint": {
      "p1": "1. Terdapat pesanan pembelian yang berbeza untuk setiap tahap keahlian",
      "p2": "2. Semakin tinggi tahap keahlian anda, semakin besar jumlah keuntungan yang dapat dibeli",
      "p3": "3. Untuk pesanan yang telah dibeli, anda boleh memantau status pesanan pada bila-bila masa"
    }
  },
  "Hint": {
    "SendCodeSuccess": "Kod pengesahan berjaya dihantar!",
    "closed": "ditutup",
    "notTime": "Tiada masa dipilih",
    "notTimeFailed": "Pemilihan masa gagal",
    "success": "Berjaya",
    "failed": "Gagal",
    "phoneNot": "Tiada akaun HP/Kad SIM yang didaftarkan",
    "pwdInconformity": "Kata laluan tidak sepadan",
    "notMoney": "Wang tidak mencukupi",
    "notEvent": "Ciri ini belum boleh digunakan",
    "notInput": "Data input tidak lengkap",
    "loading": "Sedang dimuatkan",
    "notMore": "Tiada lagi",
    "notPhone": "Akaun tidak wujud",
    "yesPhone": "Akaun sedia ada",
    "levelNot": "LevelNot",
    "serverMaintenance": "Harap bersabar, permainan dalam penyelenggaraan",
    "invite": "Jemput kawan\n untuk menjana wang\n bersama-sama",
    "goodsNumNo": "Stok mencukupi",
    "haveBuy": "Telah Dibeli",
    "depositBtnText1": "Simpan di",
    "depositBtnText2": "Tersedia",
    "depositBtnText3": "Dapatkan",
    "commissionMoney": "Pulangan komisen:",
    "bill": "Bil",
    "billRecordTitle": "Rekod Bil",
    "balance": "Baki",
    "reference": "Tampalkan Kod Jemputan",
    "updateText": "Sila kemaskini ke versi terbaru"
  },
  "newAdd": {
    "all": "semua",
    "Completed": "Lengkap",
    "unfinished": "belum selesai",
    "GetCodeBtn": "Dapatkan Kod Pengesahan",
    "copySuccess": "Salinan berjaya!",
    "copyError": "Salinan gagal!",
    "noLoginUserName": "Sila log masuk",
    "Withdraw": "Tarik",
    "Recharge": "Mengisi semula",
    "commission": "Komisyen gerai membeli-belah",
    "single": "tunggal",
    "missionHall": "Dewan Misi",
    "videoTutorial": "Tutorial video",
    "helpCenter": "Pusat Bantuan",
    "inviteFriends": "Jemput kawan-kawan",
    "play": "Mari bermain",
    "help1Title": "Bagaimana untuk membuat komisyen dengan Mudo",
    "help1Context": "Pemilik perniagaan e-dagang perlu meningkatkan jualan kedai, jadi kita hanya perlu memenuhi keperluan kedai e-dagang dan menyelesaikan pesanan pada perisian Mudo. Pemilik akan membayar kita.Setiap pengguna boleh mendapat 10 tugas pesanan sehari. Anda boleh menghasilkan keuntungan 10% hingga 30% sehari.",
    "help2Title": "Bagaimana untuk meningkatkan bilangan Misi",
    "help2Context": "Oleh kerana terdapat banyak pekerja separuh masa, syarikat memberitahu bahawa semua pekerja separuh masa atas talian hanya menggunakan WhatsApp untuk berkomunikasi dan berhubung.",
    "help3Title": "Bagaimana untuk menyelesaikan Misi",
    "help3Context": "Ini untuk membantu pedagang utama meningkatkan jualan dan memberi ganjaran anda mengikut bilangan transaksi. Untuk setiap pesanan yang berjaya, anda mendapat komisyen dan reputasi penjual.",
    "referralLink": "Pautan perujukan:",
    "copy": "Salin",
    "paste": "Tampal",
    "blance": "Baki",
    "todayOrder": "Pesanan hari ini",
    "provided": "Data disediakan oleh",
    "Transaction": "Transaksi",
    "Frozen": "Beku",
    "Done": "Selesai",
    "Cancel": "Batal",
    "ExpectedReturn": "Pulangan yang dijangka:",
    "ImmediatePayment": "Pembayaran segera",
    "AvailableNum": "Masih ada",
    "OrderImmediately": "Tempah segera",
    "MakeMoneyEveryDay": "Buat duit setiap hari",
    "TodayRecord": "Rekod hari ini",
    "AllocatCommissions": "Peruntukan komisyen",
    "FrozenAmount": "Jumlah beku",
    "TaskMatch": "Tugas Aturan Randomisasi",
    "AvailableBalance": "Baki Baki yang Tersedia",
    "WaitDispatch": "Menunggu untuk penghantaran, sila hubungi pengurus kedai!",
    "OrderDescription": "Penerangan Pesanan",
    "CouldMatch": "Tugas dipadankan secara rawak oleh sistem",
    "OrderPerDay": "Satu sahaja.",
    "DescriptionRate": "Kadar Penempatan Pesanan adalah sama rata",
    "DescriptionPeriod": "Jumlah Pesanan.",
    "Description3": "Sistem berdasarkan teknik LBS dan secara automatik mencocokkan produk melalui awan.",
    "Description4": "Untuk mengelakkan pemantauan platform, jika pesanan tidak disahkan dan dikemukakan dalam tempoh 20 minit, sistem akan membekukan jumlah pesanan.",
    "Note": "Catatan:",
    "DescriptionNote": "Sila baca dengan teliti: Selepas sistem blok rantai P2P menyesuaikan pesanan pengguna, Nota: Platform pedagang akan mengemukakan maklumat pesanan. Jika pengguna tidak mengemukakan pesanan dalam tempoh 60 minit, jumlah pesanan akan disimpan oleh sistem. Untuk mengelakkan system pemantauan, sila terima selepas pengesahan, semua tindakan tidak dapat dibatalkan atau digantikan selepas penerimaan! Jika ada masalah lain yang perlu ditangani, sila klik berhenti pesanan pada waktu yang sesuai untuk mengelakkan pembekuan lebih masa, terima kasih atas sokongan anda",
    "OrderNum": "Nombor Pesanan:",
    "TotalOrderPrice": "Jumlah Harga Pesanan",
    "SubmitNow": "Hantar sekarang",
    "Record": "Rekod",
    "MemberAccount": "Akaun Ahli",
    "AccountPlaceholder": "Sila masukkan akaun ahli anda",
    "RechargeAmount": "Amaun Tambah Nilai",
    "RechargeAmountPlaceholder": "Sila masukkan amaun tambah nilai",
    "SelectRechargeMethod": "Pilih Kaedah Tambah Nilai",
    "NotBeenSentOrder": "Pesanan belum dihantar, sila hubungi kakitangan untuk menghantar pesanan!",
    "TodayOrderOver": "Hubungi Khidmat Pelanggan atas talian",
    "NotLogin": "Anda tidak log masuk, sila log masuk dan cuba lagi!",
    "HaveNoteMethod": "Pembukaan terhad, sila hubungi pasukan jualan!",
    "Login": "Log Masuk",
    "AccountRecord": "Pendapatan dan Perbelanjaan",
    "RealName": "Nama sebenar",
    "IFSCCODE": "Kod PICS",
    "BankName": "Akaun PICS",
    "ChainType": "Jenis Rantaian",
    "BankNum": "Jenis PICS",
    "Mobile": "Mudah Alih",
    "MobileNum": "Nombor Telefon",
    "Email": "E-mel",
    "EmailRequired": "E-mel diperlukan",
    "AddBankCardNote": "Maklumat pengembalian seharusnya diisi dengan betul",
    "AddWalletNote": "Nota: Alamat dompet adalah maklumat penting, sila isi dengan teliti!",
    "Submit": "Hantar",
    "AddBankCardTitle": "Tambah PICS",
    "AddWalletTitle": "Tambah Alamat Dompet",
    "BankCard": "Kad Bank",
    "WalletInfo": "Maklumat Dompet",
    "Name": "Nama",
    "CardNum": "Nombor Kad (ID AC)",
    "WalletAddress": "Alamat",
    "Reading": "Membaca...",
    "AllBalance": "Baki Keseluruhan",
    "EnterWithdrawalAmount": "Sila masukkan amaun pengeluaran",
    "AllOut": "Keluar Semua",
    "AskNow": "Sahkan",
    "EnterPwdPlaceholder": "Sila masukkan kata laluan",
    "NotHaveCard": "Anda belum memberikan maklumat pengembalian, adakah anda ingin pergi ke tetapan?",
    "NotHaveWallet": "Anda belum menetapkan alamat dompet anda, adakah anda ingin pergi ke tetapan?",
    "GetBankInfoFail": "Gagal mendapatkan maklumat bank",
    "EnterValidPayPwd": "Sila masukkan kata laluan pembayaran yang sah!",
    "Balance": "Baki",
    "Total": "Jumlah",
    "total": "Jumlah",
    "records": "Rekod",
    "DisplayPerPage": "Memaparkan setiap halaman",
    "NullNum": "",
    "pages": "Halaman",
    "toAudit": "Untuk Audit",
    "succeed": "Berjaya",
    "RechargeRecordTitle": "Rekod Tambah Nilai",
    "rests": "Dibawa ke hadapan",
    "Arrive": "Tiba",
    "Income": "Pendapatan",
    "Expenditure": "Perbelanjaan",
    "forzenCommission": "Keuntungan Yang Disimpan",
    "companyIntro": "Profil Syarikat",
    "companyTitle": "Tata Mall adalah sebahagian daripada Kumpulan Tata",
    "warmTitle": "Amaran",
    "companyText": "Kumpulan Tata adalah kumpulan perniagaan terbesar di India, yang ditubuhkan pada tahun 1868 dan berpusat di Mumbai, India. Tujuh sektor perniagaan termasuk dalam operasi perniagaan: komunikasi dan teknologi maklumat, kejuruteraan, bahan, perkhidmatan, tenaga, produk pengguna dan produk kimia. Sebagai kumpulan perniagaan yang berkembang pesat di India, Kumpulan Tata mempunyai lebih daripada 200 anak syarikat, beroperasi di lebih daripada 80 negara di 6 benua, dan mengeksport produk dan perkhidmatan ke 85 negara. Jumlah hasil Kumpulan Tata untuk tahun kewangan 2020-2021 ialah $ 100.09 bilion, di mana 58% daripadanya datang dari operasi luar negara. Jumlah pekerja kumpulan di seluruh dunia adalah kira-kira 450,000. Selama 153 tahun, nama Tata di India telah dihormati secara meluas kerana mematuhi nilai-nilai yang baik dan etika perniagaan. Ia mendapat kedudukan ke-11 syarikat paling dihormati di dunia mengikut Forbes.",
    "depositAddress": "Alamat Deposit",
    "rechargeSteps": "Langkah Top-Up",
    "completePayment": "Lengkapkan Pembayaran",
    "rechargeNow": "Top-Up Sekarang",
    "rechargeCancel": "Batalkan Pembayaran",
    "bankName": "Nama Bank",
    "payee": "Penerima",
    "cardNum": "Nombor Kad Bank",
    "reviewing": "Sedang Disemak",
    "remittance": "Berjaya",
    "notThrough": "Tidak Berjaya",
    "OrderID": "ID Pesanan",
    "Amount": "Jumlah",
    "toCutScreen": "Sila ambil tangkap layar jika tiada gambar!",
    "cardTabBank": "Bank",
    "cardTabUSDT": "USDT",
    "name": "Nama",
    "chainType": "Jenis Rantaian",
    "newNavTitle": "Hantar Pesanan",
    "shopMoney": "Jumlah Produk",
    "actualMoney": "Penghantaran Sebenar",
    "commitBtnText": "Hantar Pesanan",
    "commitHint": "Input tidak lengkap"
  },
  "mine": {
    "servier": "pelayan",
    "integral": "integral",
    "balance": "baki",
    "record": "rekod",
    "wtdAmt": "jumlah yang dicairkan",
    "avaBlc": "baki tersedia",
    "allWtd": "semua",
    "close": "tutup",
    "commit": "komited",
    "wtdHint": "Sila masukkan amaun pengeluaran yang betul"
  },
  "add": {
    "Commission": "Komisen Hari Ini",
    "Yesterday": "Semalam",
    "Cumulative": "Kumulatif",
    "Today": "Hari Ini",
    "TeamNum": " Jumlah Anggota Pasukan",
    "TeamMoney": " Jumlah Keuntungan Pasukan",
    "Personalcenteraccountchangerecord": "Rekod Perubahan Akaun",
    "lockedbalance": "Baki Terkunci",
    "Cumulativeinvitation": "Jemputan Kumulatif",
    "Validinvitation": "Jemputan Sah",
    "Accumulatedrebate": "Rebat Terkumpul"
  },
  "home.btn.wallet": "Dana",
  "home.btn.recharge": "Deposit",
  "home.btn.withdraw": "Tarik",
  "home.btn.invite": "Jemput",
  "home.btn.message": "Mesej",
  "page.grab": "Rampas",
  "orderCommission": "Komisen Pesanan",
  "commission": "Komisen",
  "todayIncome": "Pendapatan Hari Ini",
  "personalEarnings": "Pendapatan Peribadi",
  "completedOrders": "Pesanan Selesai",
  "lockedOrders": "Pesanan Terkunci",
  "unfinishOrder": "Pesanan Tidak Selesai",
  "frozonAmount": "Dana Beku",
  "currentBalance": "Baki Semasa",
  "page.mine": "Butiran Akaun",
  "mine.invitationCode": "Kod jemputan",
  "mine.serverCenter": "Pusat pelayan",
  "mine.tools.total": "Jumlah",
  "mine.tools.financial": "Dana",
  "mine.tools.details": "Butiran",
  "mine.tools.freeze": "Seimbang",
  "mine.serviceTools.personal": "Peribadi",
  "mine.serviceTools.teamReport": "Laporan pasukan",
  "mine.serviceTools.invite": "Akaun keluarga",
  "mine.serviceTools.message": "Pesanan",
  "mine.serviceTools.aboutUs": "Tentang kami",
  "mine.serviceTools.security": "Keselamatan",
  "mine.serviceTools.address": "Alamat",
  "mine.serviceTools.inviteTask": "Jemput tugas",
  "page.team": "Pasukan",
  "page.aboutus": "Tentang kami",
  "page.financial": "Dana",
  "page.financialRecord": "Rekod kewangan",
  "hint.InternetErr": "Ralat rangkaian, sila cuba lagi nanti!",
  "totalDeposit": "Jumlah deposit",
  "totalRevenue": "Jumlah hasil",
  "btn.transferInto": "Pindah masuk",
  "btn.transferOut": "Pindah keluar",
  "page.transferInto": "Pindah masuk",
  "page.transferOut": "Pindah keluar",
  "balanceTransfer": "Pindah baki",
  "availableBalance": "Baki yang tersedia",
  "depositRate": "Kadar deposit",
  "days": "Hari",
  "hint.success": "Berjaya",
  "hint.fail": "Gagal",
  "status.into": "Pindah masuk",
  "status.out": "Pindah keluar",
  "label.amount": "Jumlah",
  "label.time": "Masa",
  "Button": {
    "cancle": "Batal",
    "confirm": "Sahkan"
  },
  "dialog.title.inputPaypass": "Masukkan kata laluan pembayaran",
  "dialog.nopaypass": "Kata laluan pembayaran tidak ditetapkan, klik OK untuk pergi ke tetapan!",
  "mine.serviceTools.lan": "Bahasa",
  "checkLans": "Tukar bahasa",
  "mine.tools.commission": "Keuntungan",
  "page.commissionRecord": "Keuntungan",
  "noticeList.all": "Semua",
  "noticeList.unread": "Belum dibaca",
  "successWithdrawof": "Pengeluaran berjaya",
  "home.title.notice": "Pemberitahuan",
  "home.title.patener": "Rakan kongsi",
  "login.rememberPassword": "Ingat kata laluan",
  "page": {
    "cart": "kereta",
    "order": "pesanan",
    "financing": "pembiayaan",
    "goods": "barang-barang",
    "levelUpgrade": "naik taraf tahap",
    "PlatformIntroduction": "Pengenalan Platform",
    "RuleDescription": "Huraian Peraturan",
    "AgentCooperation": "Kerjasama Agen",
    "CommonProblem": "Masalah Biasa",
    "MemberUpgrade": "Peningkatan Ahli",
    "login": "Log masuk",
    "forgot": "Lupa",
    "registrasi": "Pendaftaran",
    "record": "Rekod",
    "Recharge": "Cas semula",
    "inviteFriends": "Jemput Rakan",
    "Withdraw": "Tarik balik",
    "Service": "Khidmat",
    "DialoguePage": "Halaman Dialog",
    "user": "Peribadi",
    "RansferIn": "Pindahan Masuk",
    "PersonalCenter": "Pusat Peribadi",
    "Portrait": "Potret",
    "Name": "Nama",
    "Account": "Akaun",
    "Password": "Kata Laluan",
    "PaymentCode": "Kod Pembayaran",
    "Site": "Laman Web",
    "AccountData": "Data Akaun",
    "RechargeRecord": "Rekod Tambah Nilai",
    "WithdrawalRecord": "Rekod Pengeluaran",
    "InviteFriends": "Jemput Rakan",
    "Announcement": "Pengumuman",
    "TheTeamReport": "Laporan Pasukan",
    "BillRecord": "Rekod Bil",
    "BankCardAdd": "Tambah Kad Bank",
    "NewRecharge": "Caj Semula Baru",
    "RechargeList": "Senarai Caj Semula",
    "newWithdraw": "Keluaran Baru",
    "withdrawList": "Senarai Pengeluaran",
    "SettingPayPwd": "Tetapkan",
    "MakeMoney": "Hasilkan Wang",
    "team": "Pasukan",
    "qrPay": "QRCODE-BAYAR",
    "bankPay": "BANK-BAYAR",
    "aboutus": "Tentang Kami",
    "financial": "Kewangan",
    "financialRecord": "Rekod Kewangan",
    "TransferInto": "Pindahkan Masuk",
    "TransferOut": "Pindahkan Keluar",
    "CommissionRecord": "Rekod Komisen",
    "Security": "Keselamatan",
    "Address": "Alamat",
    "Message": "Mesej",
    "InviteTask": "Tugasan Jemputan",
    "Notice": "Notis",
    "modifyPaymentPassword": "Ubah Kata Laluan Pembayaran"
  },
  "copyLink": "Salin pautan",
  "teamReport": {
    "title": "Laporan Pasukan",
    "subTitle": "Tinjauan Pasukan Bawahan",
    "taskTitle": "Jemput {number} orang untuk menyelesaikan dan menyelesaikan arahan",
    "bonus": "Bonus",
    "Unfinished": "Tidak Siap",
    "Getted": "Didapat"
  },
  "copy": "Salinan",
  "address": {
    "name": "Nama",
    "inputPlaceholder": "Sila isi",
    "phone": "Telefon",
    "areaPlaceholer": "Alamat Penghantaran Perpindahan",
    "submit": "Simpan"
  },
  "password.old": "Kata laluan lama",
  "password.old.placeholder": "Masukkan kata laluan lama anda",
  "password.confirmPassword": "Sahkan Kata Laluan",
  "password.confirmPassword.placeholder": "Masukkan kata laluan baru sekali lagi",
  "hint.passwordDiff": "Dua kata laluan tidak sama",
  "loginType": {
    "phone": "Telefon",
    "email": "E-mel"
  },
  "Login.Daftar.email": "E-mel",
  "Login.emailInput": "Masukkan e-mel anda",
  "My.PersonalInformation.Password.email": "E-mel",
  "My.PersonalInformation.Password.NewEmail": "Sila masukkan e-mel selular anda",
  "mine.serviceTools.certify": "Pengesahan",
  "Certification": {
    "type": {
      "id": "Pengenalan Diri (KP)",
      "driver": "Lesen Memandu",
      "passport": "Pasport"
    },
    "name": "Nama",
    "license": "Nombor Lesen",
    "upload": "Lampiran Dokumen",
    "submit": "Hantar",
    "incompleteInformation": "Sila isi maklumat dan cuba lagi!",
    "submitSuccess": "Maklumat pengesahan telah dikemukakan, sila tunggu semakan!",
    "photo": "Foto Pengenalan Diri",
    "review": "Di bawah Semakan",
    "verified": "Disahkan"
  },
  "upload": {
    "uploading": "Memuat naik...",
    "failed": "Muat naik gagal"
  },
  "login.forgetPassword": "Lupa kata laluan anda, sila hubungi Perkhidmatan Pelanggan!",
  "newAdd.BankNum.placeholder": "Contoh: 09xxxxxxxxxx",
  "page.certification": "Pengesahan Pengenalan Diri",
  "newAdd.page": "Halaman",
  "newAdd.record": "Rekod",
  "emailFormatError": "Sila masukkan alamat e-mel yang betul!",
  "urlParsePlaceholder": "Sila tampalkan pengenalan yang dikenali",
  "btn.Identify": "Kenal Pasti Dengan Segera",
  "produceStatus.confirming": "Mengesahkan",
  "produceStatus.paying": "Sedang dibayar",
  "produceStatus.paid": "Telah dibayar",
  "produceStatus.done": "Selesai",
  "produceStatus.cancle": "Dibatalkan",
  "order.old": "Pesanan Pendapatan Tinggi",
  "order.new": "Pesanan Biasa",
  "welfareDialogTitle": "Pemberitahuan Sistem",
  "viGrabDesces": {
    "1": "Setiap pesanan boleh dipilih sebagai pesanan bernasib baik dan komisen akan ditingkatkan sehingga {dati}%. Untuk memohon penugasan terlanjut, sila hubungi mentor pengembangan anda",
    "2": "Bantu penjual Shopee dalam membuat pesanan maya, meningkatkan kebolehlihataan dan memperoleh komisen",
    "3": "Setelah menerima pesanan, hubungi tutor promosi anda secepat mungkin, serahkan data pesanan dan lengkapkan tugasan",
    "4": "Bilangan tugasan terhad, sila perhatikan cadangan promosi jurulatih semasa menerima pesanan",
    "5": "Pembayaran balik akan dikeluarkan dalam masa 5-10 minit setelah tugasan selesai. Jika anda mempunyai sebarang soalan, sila rujuk pakar atau layanan pelanggan dalam talian.",
    "6": "Lengkapkan tugasan yang diberikan oleh Shopee, tingkatkan tahap VIP, dan dapatkan komisen yang tinggi untuk tugas Facebook",
    "7": "Lengkapkan tugas Shopee yang ditetapkan secara kumulatif untuk mendapatkan komisen tambahan. Untuk ganjaran khas, sila rujuk kepada pakar promosi atau layanan pelanggan dalam talian"
  },
  "expectedEarningsTitle": "Pendapatan Jangkaan",
  "rechargeDesc": {
    "nav": "Butiran Pengecas Semula",
    "p1": "Sekarang kami mempunyai banyak pekerja pada masa yang sama.",
    "p2": "Seorang penjual tidak dapat menyediakan akaun yang mencukupi",
    "p3": "Oleh itu, kami menyiarkan akaun penjual kami ke akaun lain yang kosong",
    "p4": "Apabila anda perlu melengkapkan tugas, anda boleh menghubungi sokongan jualan untuk mendaftar saluran pembayaran anda"
  },
  "chat": {
    "list": {
      "nav": "Mesej",
      "hours": "{h} jam",
      "minutes": "{m} minit",
      "ago": "{time} yang lalu",
      "image": "Imej"
    },
    "room": {
      "msgPlaceholder": "Masukkan suatu mesej...",
      "send": "Hantar",
      "sender": "Penghantar",
      "sendTime": "Masa Hantar",
      "content": "Kandungan"
    }
  },
  "Login.Daftar.selectCurrency": "Pilih Mata Wang",
  "mine.totalBalance": "Jumlah Baki",
  "newBank": {
    "usdt": {
      "name": "Nama",
      "currency": "Mata Wang",
      "chainType": "Jenis Rantaian",
      "depositAddress": "Alamat Deposit",
      "submit": "Hantar",
      "bank": "Bank",
      "wallet": "Dompet",
      "bankName": "Nama bank",
      "bankCard": "Kad bank"
    },
    "walletList": {
      "pageTitle": "Pengurusan Pengeluaran",
      "name": "Nama Dompet:",
      "coinType": "Mata Wang:",
      "chainType": "Jenis Rantaian:",
      "walletAddress": "Alamat:",
      "addDate": "Tarikh:",
      "btnText": "Tambah Alamat Dompet",
      "deleteConfirmText": "Adakah anda pasti anda ingin menghapuskan alamat dompet ini?",
      "btnConfirm": "Padam",
      "btnCancle": "Batal",
      "successHint": "Berjaya"
    },
    "withdrawNum": "Bilangan Pengeluaran",
    "AllOut": "Semua",
    "balance": "Baki Keseluruhan",
    "handlingFee": "Yuran Pengurusan",
    "actualAmount": "Jumlah Sebenar"
  },
  "recharge.actualAmount": "Jumlah Sebenar",
  "login.userAgreement": "Perjanjian Pengguna",
  "login.title1": "Daftar",
  "login.title2": "Selamat Datang kembali!",
  "registe.title1": "Daftar",
  "registe.title2": "Selamat Datang kembali!",
  "mine.credit": "Kredit",
  "page.checkin": "Log Masuk",
  "checkin.submit": "Log Masuk",
  "checkin.success": "Log Masuk Berjaya",
  "checkin.fail": "Gagal Mengambil Bahagian",
  "todayChecked": "Anda telah log masuk, sila jangan ulangi operasi!",
  "submit.checked": "Log Masuk",
  "hint.inviteBanned": "Maaf, akaun anda belum membuka akaun keluarga, sila hubungi khidmat pelanggan atau penjaga anda untuk memohon pembukaan!",
  "newTab.blindBox": "Kotak Buta",
  "blindBox.submit": "Mulakan Loteri",
  "blindBox.times": "Peluang membuka kotak dada: {times} kali",
  "blindBox.records.title": "Rekod Pemenang Saya",
  "banEditBankHint": "Maklumat kad bank adalah penting, jika anda perlu mengubahnya, sila hubungi khidmat pelanggan!",
  "level.order_num": "Komisen Kripto",
  "drawNum": "Bilangan Kotak Buta",
  "recharge.good_title": "Produk",
  "recharge.blindBoxIncome": "Hasil Kotak Buta",
  "recharge.amount": "Jumlah",
  "recharge.bonusRatio": "Nisbah Bonus",
  "submit": {
    "desc": {
      "1": "Selepas menyelesaikan produk acara, anda akan menerima peluang cabutan kotak buta, dan julat bonus produk adalah 10-50% daripada nilai produk.",
      "2": "Berdasarkan teknologi LBS, sistem akan secara automatik padankan produk dan peluang cabutan kotak buta dari awan.",
      "3": "Untuk mengelakkan pemantauan platform, jika pesanan tidak disahkan dan dikemukakan dalam masa 30 minit, sistem akan membekukan jumlah pesanan.",
      "sub_1": "Sebagai contoh, jika anda membayar pesanan sebanyak R $ 3000, nisbah bonus kotak buta adalah 10%-50%, dan julat bonus sebenar adalah R $ 300-1500, yang bermakna pulangan minimum anda adalah R $ 3300 dan pulangan maksimum adalah R $ 4500.",
      "note": "Sila baca kandungan yang relevan dengan teliti:",
      "note_sub": "Selepas menjalinkan pesanan pengguna, pedagang Shopee akan mengemukakan maklumat pesanan. Jika pengguna tidak mengemukakan pesanan dalam masa 30 minit, jumlah pesanan akan dibekukan oleh sistem. Untuk mengelakkan pemantauan sistem, sila terima selepas pengesahan, semua tindakan tidak boleh dibatalkan atau digantikan selepas kelulusan! Jika terdapat masalah lain yang perlu ditangani, sila klik untuk mencegah anda daripada memberikan pesanan pada masa yang diperlukan untuk mengelakkan pembekuan lebih masa, terima kasih atas sokongan anda."
    }
  },
  "drawNine": {
    "times": "Peluang membuka peti harta karun",
    "submit": "Bonus kotak buta",
    "percentHint": "100% pemenang"
  },
  "hint.nullOfDraw": "Anda tidak mempunyai peluang untuk ditarik",
  "hint.loginNotRead": "Sila baca perjanjian dan cuba lagi!",
  "pages.editPhone": "Ubah suai nombor telefon",
  "editPhone": {
    "label": "Nombor telefon",
    "placeholder": "Nombor telefon",
    "hint": "Sila masukkan nombor telefon anda"
  },
  "Button.submit": "Hantar",
  "pages.editEmail": "Ikat emel",
  "users.email": "Emel",
  "users.userphone": "Telefon",
  "users.username": "Nama pengguna",
  "editEmail": {
    "label": "Emel",
    "placeholder": "Emel",
    "hintRequire": "Sila masukkan alamat emel",
    "hintCorrect": "Sila masukkan emel yang sah"
  },
  "personal.page.email": "Emel",
  "personal.page.phone": "Telefon",
  "banEditPhone": "Nombor telefon adalah maklumat penting, jika anda perlu mengubahnya, sila hubungi perkhidmatan pelanggan!",
  "banEditEmail": "Emel adalah maklumat penting, jika anda perlu mengubahnya, sila hubungi perkhidmatan pelanggan!",
  "banEditUserInfo": "Maklumat penting tidak boleh diubah, jika anda perlu mengubahnya, sila hubungi perkhidmatan pelanggan!",
  "newTab.p2p": "P 2 P",
  "p2p": {
    "trade_method": {
      "buy": "Beli",
      "sell": "Jual"
    },
    "coinList": {
      "orders": "{__} pesanan",
      "label": {
        "amount": "Kuantiti",
        "price": "Harga",
        "limit": "Anda LIMIT"
      }
    },
    "tradeDetails": {
      "trade_title_sell": "Berapa banyak {__} yang anda ingin jual?",
      "trade_title_buy": "Berapa banyak {__} yang anda ingin beli?",
      "submit_now": "{__} sekarang"
    },
    "orderDetails": {
      "sellTitle": "Menunggu pembayaran daripada pihak lain",
      "buyTitle": "Menunggu pembayaran anda",
      "sellHint": "Pihak lain mesti membayar dalam masa {__}, jika tidak, pesanan akan dibatalkan secara automatik.",
      "buyHint": "Bayar dalam masa {__}. Selepas pembayaran dilakukan, klik hubungi penjual",
      "payInfo_title": "Pelanggaran pembayaran",
      "label": {
        "totalAmount": "Jumlah keseluruhan",
        "pricePerUnit": "Harga per unit",
        "quantity": "Kuantiti",
        "counterpaty": "Pihak lawan",
        "payMethod": "Kaedah pembayaran",
        "fullName": "Nama penuh",
        "email": "Emel",
        "phone": "Nombor telefon"
      },
      "btn_service": "Hubungi penjual",
      "btn_sell": "Hubungi Pembeli"
    }
  },
  "copySuccess": "Berjaya disalin",
  "bannerText1": "{name} mendapat komisen {money1} untuk jumlah kerja {money}",
  "bannerText2": "Tahniah {name} untuk memilih tugas premium dan mendapat 30% komisen",
  "grap": {
    "btask": "Tugas bonus",
    "wtask": "Tugas diperuntukkan"
  },
  "hashPlaceholder": "Sila masukkan hash",
  "recharge.status.error": "Gagal mencipta pesanan Walmart virtual, sila masukkan nilai hash dan jumlah item yang betul",
  "NetWork": "Rangkaian",
  "tailNum": "Bilangan ekor",
  "label.price": "Harga",
  "label.id": "ID",
  "label.level": "Tahap",
  "thirdRecharge.hint.noGoods": "Sila urai item terlebih dahulu sebelum cuba lagi!",
  "hint.onlyAgentInvite": "Akaun keluarga tidak dibuka.",
  "newTab.p2p": "P2P",
  "copySuccess": "Disalin dengan jayanya",
  "p2p.noMore": "Tiada lagi",
  "tabbar.shop": "Beli-belah",
  "newAdd.shopTitle": "Troli Beli-belah",
  "shop": {
    "urlplaceholder": "Sila tambahkan pautan produk",
    "uploadText": "Muat naik tangkapan skrin belian",
    "urlNull": "Sila tambahkan pautan produk",
    "uploadNull": "Sila tambahkan tangkapan skrin belian",
    "btn": "Hantar",
    "money": "+{__} Baki",
    "point": "+{__} Mata",
    "moneyPoint": "+{money} Baki\n+{point} Mata",
    "order": {
      "orderNo": "Nombor pesanan:",
      "link": "Pautan:",
      "screenshot": "Tangkapan skrin",
      "title": "Komit sejarah"
    }
  },
  "listStatus": {
    "all": "Semua",
    "progress": "Sedang dilaksanakan",
    "done": "Selesai",
    "error": "Gagal meminta data",
    "cancel": "Dibatalkan",
    "won": "Menang"
  },
  "pullRefresh": {
    "pulling": "Tarik ke bawah untuk menyegarkan...",
    "loosing": "Lepaskan untuk menyegarkan...",
    "loading": "Sedang memuatkan...",
    "success": "Berjaya dimuatkan"
  },
  "selectCurrenycy": "Pilih mata wang",
  "new_goods": {
    "custdown": "Kiraan detik",
    "hours": "Jam",
    "minutes": "Minit",
    "seconds": "Saat",
    "descBtn": "Huraian Tugas",
    "taskBtn": "Selesaikan Tugas"
  },
  "gift": {
    "gift_shop": "Kedai Barangan Hadiah",
    "my_gift": "Hadiah Saya",
    "away": "Hantar",
    "buy": "Beli",
    "overlay": {
      "inputPlaceholder": "Masukkan nombor bilik",
      "num": "Jumlah Hadiah",
      "room": "Hadiah Bilik Satu",
      "no_data": "Tiada hadiah"
    },
    "detailTitle": "Rekod Hadiah",
    "num": "Kuantiti",
    "room": "Nombor Bilik"
  }
}
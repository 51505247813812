<template>
  <div class="tabbar_wrap">
    <div class="placeholder">
      <router-view />
    </div>
    <keep-alive>
      <!-- verConfig.tabbar.selectedColor ||  -->
      <Tabbar v-model="active" @change="tabbarChange" active-color="var(--theme)"
        :inactive-color="theme == 'dark' ? 'rgba(255,255,255,.6)' : 'rgba(0,0,0,.6)'" route>
        <TabbarItem v-for="(item, index) in verConfig.tabbar.pages" :key="index" :name="index"
          :to="`/tabbar/${item.pagePath}`">
          <span v-if="index !=2" :class="item.type === 'image' ? 'textClass' : ''" :style="active == index ? {} : { display: 'none' }">{{
        $t(item.text) }}</span>
          <template #icon="props">
            <template v-if="item.type === 'image'">
              <div class="icons" :style="props.active ? {width:'calc(72rem / 16)',height:'calc(72rem / 16)'}:{}" v-if="index == 2">
                <div class="imgs" v-if="props.active">
                  <img class="img" :src="item.selectedIconPath" alt="" />
                </div>
                <img class="img" :src="item.iconPath" alt="" v-else />
              </div>
              <div class="iconss" v-else>
                <img class="img" :src="props.active ? item.selectedIconPath : item.iconPath" alt="" />
              </div>
            </template>
            <i class="iconfont" v-else :class="item.selectedIconPath
        ? props.active
          ? item.selectedIconPath
          : item.iconPath
        : item.iconPath
        "></i>
          </template>
        </TabbarItem>
      </Tabbar>
    </keep-alive>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { mapGetters } from "vuex";
export default {
  components: {
    Tabbar,
    TabbarItem,
  },
  data: () => ({
    // active: 0,
  }),
  created() { },
  methods: {
    handleClick() { },
    tabbarChange(index) {
      if (index == 1 && !this.userInfo.token) {
        this.$router.push('/login')
      }
    },
  },
  computed: {
    ...mapGetters(["theme", "userInfo"]),
    active: {
      get() {
        return this.$store.state.tabbar.active;
      },
      set(newVal) {
        return newVal;
      },
    },
  },
};
</script>

<style lang="less" scoped>
@bg: var(--bg);

.tabbar_wrap {
  background: @bg;

  /deep/ .van-tabbar {
    max-width: 750px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    background-color: @bg;
  }
}

.placeholder {
  margin-bottom: calc(50rem / 16);
}

.iconfont {
  font-size: calc(22rem / 16);
}

// .van-tabbar-item{
//   position: relative;
// }
.van-tabbar-item--active {
  background-color: transparent;
}

/deep/ .van-tabbar-item__icon {
  width: 100%;
}

.icons {
  position: absolute;
  top: calc(-40rem / 16);
  left: 0;
  right: 0;
  z-index: 10;
  width: calc(80rem / 16);
  height: calc(80rem / 16);
  border-radius: 50%;
  background-color: var(--bg);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px var(--bg);

}

.img {
  height: calc(68rem / 16);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(-15rem / 16);
}
.imgs{
  width: calc(48rem / 16);
  height: calc(48rem / 16);
  border-radius: 50%;
  background-color: var(--theme);
  display: flex;
  align-items: center;
  justify-content: center;
  .img{
    height: calc(36rem / 16);
    margin-top: 0;
  }
}

.iconss {
  display: flex;
  align-items: center;
  justify-content: center;

  .img {
    width: calc(24rem / 16);
    height: calc(24rem / 16);
    display: block;
    margin-top: calc(6rem / 16);
  }
}


.textClass {
  margin-top: calc(22rem / 16);
  color: var(--theme);
}
</style>
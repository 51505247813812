export default {
  // 公共的key
  common: {
    unrealized: '暂未实现',
    all: '全部',
    distributionTip: '0元起送 0配送费',
    easeBack: '安心退',
    addCarSuccess: '成功加入购物车',
    deleteConfirm: '确定要删除选中商品吗?'
  },
  //商品详情
  goodsDetail: {
    goodsDetail: '商品详情',
    shopping: '抢购中',
    end: '距结束',
    sold: '已售',
    goodsTips: '此商品按500g/份计价,如实收少于500g将退还差价',
    quickTime: '最快29分钟内送达',
    specifications: '规格',
    preservationConditions: '保存条件',
    coldStorage: '冷藏',
    shelfLife: '保质期',
    day: '15天',
    addToCar: '加入购物车'
  },
  //购物车
  car: {
    title: '购物车',
    delete: '删除',
    tip: '购物车空空滴~',
    walk: '去逛逛',
    all: '全选',
    guess: '猜你喜欢'
  },
  // 首页
  home: {
    HallofFame: '名人堂',
    chooseLocation: '请选择位置..',
    more: '更多',
    home: '首页',
    category: '分类',
    eat: '吃什么',
    cart: '购物车',
    mine: '我的',
    inputGoodsName: '输入商品名称',
    vipDesc: '加入会员·每年预计节省806元',
    vipSubDesc: '5折开卡>',
    specialZone: '特色专区',
    foodie: '吃货节',
    dinner: '晚餐',
    popularity: '人气',
    goodChoose: '心选',
    limitBuy: '限时抢购',
    bottomTip: '我是有底线的',
    chooseAddress: '请选择地址',
    currentLocation: '您当前的位置',
    nearbyAddress: '附近的地址'
  },
  // 分类
  category: {
    tip: '输入商品名称',
    bottomTip: '到底了,看看别的分类吧'
  },
  // 吃什么
  eat: {
    all: '全部',
    close: '收起',
    pleaseHolder: '搜索菜谱、食材',
    menu: '今日菜单',
    menuDevide: '菜单分类',
    recent: '最近找过',
    tip_bottom: '到底了,别拉了!',
    tip: '收藏暂未实现哦~'
  },
  // 我的
  mine: {
    title: '我的',
    phone: '手机号',
    login: '立即登录',
    myOrder: '我的订单',
    allOrder: '查看全部订单',
    myBill: '我的优惠券',
    myLocation: '我的收货地址',
    myCar: '我的绿卡',
    servier: '联系客服',
    servierTime: '客服时间 07:00-22:00',
    feedback: '意见反馈',
    switchLanguage: '语言',
    chinese: '中文',
    english: '英文',
    version: '当前版本',
    waitingPay: '待支付',
    waitingReceive: '待收货',
    waitingComment: '待评价',
    waitingFeedback: '售后/退款',
    unrealized: '退款页面尚未开通啦!',
    tip2: '记得点个小星❤️哦~',
    tip3: '💘感谢您的关注💘',
    tip4: 'GitHub上搜索 \nGeek-James/ddBuy \n🦉欢迎提出优化建议🙉',
    feedbackTip: '暂时还没有相关订单',
    cardPrise: '绿卡价格',
    moreMessage: '更多特权敬请期待...',
    openCard: '开通绿卡',
    recomend: '开通绿卡享三大权益,预计每单省6.66元',
    cardTip1: '专享券',
    cardTip2: '专享特价',
    cardTip3: '2倍积分',
    cardTip4: '更多特权',
    vipType: '5折',
    vipTime: '年卡·365天',
    vipRecommend: '相当于约0.24元/天',
    prise1: '¥88',
    prise2: '¥188',
    vipType1: '7折',
    vipTime1: '季卡·90天',
    vipRecommend1: '相当于约0.33元/天',
    prise11: '¥30',
    prise12: '¥45',
    payMethod: '支付方式',
    wechatPay: '微信支付',
    aliPay: '支付宝支付',
    huabeiPay: '花呗支付',
    payImmeatally: '立即支付',
    cardUeless: '绿卡未开通',
    tip: '只能到这步啦!后面的臣妾做不到啊~~',
    changeNickName: '修改昵称',
    confirm: '保存',
    nickName: '请输入新昵称',
    personal: '个人资料修改成功!',
    message: '请输入昵称',
    myBills: '我的优惠券',
    persent: '请输入520it兑换',
    bill: '优惠券 ',
    condition: '无使用门槛\n最多优惠1.5元',
    rmb: '元',
    myOrders: '我的订单',
    itemsTitle: ['全部', '待支付', '待收货', '待评价'],

    personalInfo: '个人资料',
    head: '头像',
    user_name: '昵称',
    userSex: '性别',
    Brithday: '生日',
    phoneNumber: '手机号',
    noInput: '未填写',
    logout: '退出登录',
    infoTip: '个人资料修改成功',
    loginInfo: '确定退出登录吗?',
    infoSuccess: '退出成功',
    year: '年',
    month: '月',
    day: '日',
    man: '帅哥',
    woman: '美女',

    greenCard: '绿卡',
    greenCardPower: '绿卡特权',
    fiftypecert: '5折开通绿卡',
    cardRecoamnd: '绿卡专享券 天天领取优惠',
    TodayExclusivecoupon: '今日专享券',
    everyUpadate: '每天0点更新',
    to: '满',
    toUse: '元使用',
    immeatallyGet: '立即领取',
    weekBill: '本周专享券',
    cardTip7: '立即领取',
    billTip: '绿卡专享券 天天领取优惠',
    currentGoods: '您当前购物',
    cardShop: '开通绿卡购物',
    cardBack: '返积分为',
    cardSpecialTip: '立即开启积分加速',
    cardSpecial: '绿卡专享特价',
    cardPriseb: '180元',
    cardPrisea: '88元',
    yearCard: '年卡',
    openCards: '开通绿卡',
    onece: '1倍',
    twice: '2倍'
  },
  // 订单
  order: {
    outTimeGetMoney: '超过十分钟可获得积分补偿',
    inputForm: '填写订单',
    location: '选择收货地址',
    arrivalTime: '送达时间',
    total: '共',
    thing: '件',
    wechatPay: '微信支付',
    aliPay: '支付宝支付',
    huabeiPay: '花呗支付',
    use: '使用',
    order12: '积分兑换',
    mark: '备注',
    tip: '选填,可以告诉我们您的特殊需求',
    goodsList: '商品清单',

    totalMoney: '商品金额',

    sendMoney: '配送费',
    point: '积分',
    order19: '实付',

    pay: '实付',
    sendForm: '提交订单',

    selectArrivalTime: '选择送达时间',
    comfirm: '确定',
    goods: '商品',
    all: '共',
    things: '件',
    sigalPrice: '单价:',
    numbers: '数量:',
    addTip: '还没有添加过地址呢,添加一个吧😄',

    addLocation: '添加地址',
    comfirmAndUse: '保存并使用',
    editLocation: '编辑地址',
    myAddress: '我的地址',
    settlement: '结算',
    chooseGoods: '请选择需要结算的商品',
    deliveryTime: '请选择送达时间',
    today: '今天',
    tomorrow: '明天'
  },
  Login: {
    indexTitle: '登录',
    Daftar: {
      title: '注册',
      phone: '请输入您的手机号码',
      code: '请输入验证码',
      password: '请输入密码',
      againPassword: '请再次输入密码确认'
    },
    Forgot: {
      sendCode: '发送',
      confirm: '提交',
      goBack: '返回'
    },
    nameInput: '请输入您的手机号码',
    pwdInput: '请输入密码',
    remenber: '记住密码',
    loginButton: '登录',
    registrasi: '注册'
  },
  // 登录
  login: {
    title: '登录',
    phoneNumber: '手机号',
    phoneNumberNotCorrect: '手机号格式不正确',
    phoneNumberNotEmpty: '手机号不能为空',
    passNumberNotEmpty: '密码不能为空',
    phoneInput: '请输入手机号',
    pass: '密码',
    passTip: '请输入密码',
    varify: '请输入验证码',
    otherMethods: '其他登录方式',
    wechat: '微信登录',
    qqchant: 'QQ登录',
    tipTile: '温馨提示：',
    tipContent: '未注册的手机号，登录时将自动注册，且代表同意',
    tip: '用户协议',
    tipProcy: '隐私策略',
    login: '登录',
    resgin: '注册',
    passTip2: '请输入密码(不少于6位)',
    sendVerify: '发送验证码',
    hasSend: '已发送',
    phoneVerify: '账号密码登录',
    smsMessage: '短信验证码登录',
    switchLoginMsg: '账号密码登录',
    messageSuccess: '验证码获取成功,请在输入框输入',
    pleaseInputCorrectPhoneNumber: '请输入正确的手机号',
    pleaseInputCorrectVerifyumber: '请输入正确的验证码',
    otherWechat: '微信登录-暂未完成',
    otherQQ: 'QQ登录-暂未完成',
    message: '验证码:'
  },
  Hint: {
    notMore: '没有更多了',
    loading: '加载中',
    failed: '失败',
    SendCodeSuccess: '验证码已发送成功!',
    phoneNot: '手机号/账号不能为空',
    pwdInconformity: '两次密码输入不一致',
    notInput: '输入内容不完整',
  },
  newTab: {
    home: '首页',
    money: '充值',
    order: '订单',
    my: '我的',
    team: '团队'
  },
  tab: {
    make_money: '接单'
  },
  Cart: {
    zzInfo: {
      buyIng: '购买中',
      buyFailed: '购买失败',
      notLevel: '您的会员等级不足',
      buyJurisdiction1: '如需购买黄金会员订单',
      buyJurisdiction2: '如需购买白金会员订单',
      buyJurisdiction3: '如需购买钻石会员订单',
      invite1: '邀请3个好友一起赚钱',
      invite4: '邀请10个好友一起赚钱',
      invite3: '邀请30个好友一起赚钱',
      invite2: '邀请好友',
      myMoney: '您的账户余额',
      under: '低于',
      notSendMoney: '不能进行交易',
      goTopUp: '请前往充值',
      know: '我知道了',
      account: '结算'
    },
    hint: {
      p1: '1. 不同的会员级别有不同的购买订单',
      p2: '2. 会员级别越高，可购买的订单佣金金额越高',
      p3: '3. 对于已购买的订单，您可以随时监控订单状态'
    },
    state: {
      all: '全部',
      going: '进行中',
      paid: '已支付',
      frozen: '已冻结',
      over: '已完成',
      canceled: '已取消'
    },
    info: {
      allMoney: '我的总资产 ฿',
      dayAllOrder: '今天的总订单',
      dayTrustMoney: '今天委托金',
      yesterdayMoney: '昨天的收入'
    },
    text: {
      one: '订单'
    }
  },
  My: {
    download: '下载APP',
    personal: '个人信息',
    recharge: '充值记录',
    withdrawal: '提现',
    language: '语言设置',
    logout: '登出',
    MemberInformation: {
      balance: '账户余额',
      Withdraw: '提现',
      Recharge: '充值',
      forzenAll: '冻结总额',
      Invitation: "邀请码"
    },
    PersonalInformation: {
      Page: {
        Avatar: '头像',
        Nickname: '昵称',
        PhoneNumber: '电话号码',
        BankAccounts: '银行卡',
        Password: '密码',
        TransactionPassword: '交易密码', // 交易密码
        title: '个人信息'
      },
      portrait: {
        SetAvatar: '头像设置',
        button: '修改'
      },
      Name: {
        ModifyNickname: '修改昵称',
        title: '修改昵称',
        name: '昵称',
        Please: '请输入您想修改的昵称'
      },
      Password: {
        title: '修改登录密码',
        PleaseEnter: '请输入您想修改的密码',
        phone: '手机号码',
        NewPhone: '请输入您的手机号码',
        verificationCode: '验证码',
        verificationCodeNote: '请输入验证码',
        ConfirmPassword: '密码',
        ConfirmPasswordNote: '请输入您想修改的密码',
        ButtonCode: '获取验证码', // 获取code
        Text: '请记住密码，如果忘记密码，请联系客服。',
        Button: '确认修改'
      },
      AccountData: {
        title: '交易详情'
      }
    }
  },
  newAdd: {
    all: '全部',
    Completed: '已完成',
    unfinished: '未完成',
    Income: '收入',
    Expenditure: '支出',
    GetCodeBtn: '获取验证码',
    copySuccess: '复制成功',
    copyError: '复制失败',
    noLoginUserName: '请登录',
    Withdraw: '提现',
    Recharge: '充值',
    commission: '佣金',
    single: '单',
    missionHall: '任务大厅',
    videoTutorial: '视频教程',
    helpCenter: '帮助中心',
    inviteFriends: '邀请好友',
    help1Title: '如何通过CG赚取佣金',
    help1Context: '电商老板需要添加店铺的销售额,所以我们只需要在CG上完成电商店铺的要求,完成订单即可。业主会给我们佣金。每个用户每天可以接收10个订单任务。您可以在一天内赚取 10% 到 30% 的佣金。',
    help2Title: '如何增加任务数量',
    help2Context: '由于兼职员工太多，公司规定所有在线兼职员工使用唯一的whatsapp进行联系和接收任务',
    help3Title: '如何完成任务',
    help3Context: '帮助大商家增加销售，并根据交易数量奖励你。对于每一个成功的订单，你赚取佣金，卖家赢得声誉。',
    referralLink: '推荐链接',
    copy: '复制',
    blance: '余额',
    todayOrder: '今日订单',
    provided: '本数据由提供',
    Transaction: '交易',
    Frozen: '未结算',
    Done: '完成',
    Cancel: '取消',
    ExpectedReturn: '预计返还:',
    ImmediatePayment: '立即支付',
    AvailableNum: '可用次数',
    OrderImmediately: '马上配单',
    MakeMoneyEveryDay: '日日赚钱',
    TodayRecord: '今日记录',
    AllocatCommissions: '分配佣金',
    FrozenAmount: '未结算金额',
    TaskMatch: '任务分配',
    AvailableBalance: '可用余额',
    WaitDispatch: '等待分配任务,请联系店长!',
    OrderDescription: '今日记录',
    // CouldMatch: '每个账户每天可配',
    CouldMatch: '任务由系统随机匹配',
    OrderPerDay: '单。',
    DescriptionRate: '配单佣金统一为订单金额的',
    DescriptionPeriod: '。',
    Description3: '系统基于LBS技术, 通过云端自动匹配商品。',
    Description4: '为防止平台监控,如果订单为在20分钟内确认并发送,系统将冻结订单数量。',
    Note: '注意事项:',
    DescriptionNote: '当平台为用户匹配订单时, 平台将订单信息提交给商家后台。如果用户在20分钟内未提交订单，\
    订单金额将被系统冻结。为避免监管系统，会对用户账号进行评分，直接影响用户下一次撮合的\
    时间和口碑！如有其他需要处理的，请及时点击停止下单，以免超时冻结',
    OrderNum: '订单号:',
    TotalOrderPrice: '总价:',
    SubmitNow: '立即提交',
    Record: '记录',
    MemberAccount: '会员账户',
    AccountPlaceholder: '请输入您的账户',
    RechargeAmount: '充值金额',
    RechargeAmountPlaceholder: '请输入充值金额',
    SelectRechargeMethod: '选择充值方式',
    NotBeenSentOrder: '尚未派发订单,请联系工作人员派发订单!',
    TodayOrderOver: '今日订单已完成,请明天再试!',
    NotLogin: '您尚未登录,请登录后重试!',
    // HaveNoteMethod: '暂时没有这样的充值方法,请选择其他充值方法~',
    HaveNoteMethod: '有限开放，请联系业务团队！',
    Login: '登录',
    AccountRecord: '账变记录',
    RealName: '真实姓名',
    IFSCCODE: 'IFSC',
    BankName: '银行卡名称',
    BankNum: '银行卡号',
    Mobile: '手机',
    MobileNum: '手机号码',
    Email: '邮箱',
    EmailRequired: '邮箱必填',
    AddBankCardNote: '银行卡是重要信息, 请仔细填写!',
    Submit: '提交',
    AddBankCardTitle: '添加银行卡',
    BankCard: '支付方式',
    Name: '姓名',
    CardNum: '卡号',
    Reading: '正在读取中...',
    AllBalance: '总余额',
    EnterWithdrawalAmount: '请输入提现金额',
    AllOut: '全部',
    AskNow: '提现',
    EnterPwdPlaceholder: '请输入密码',
    NotHaveCard: '你尚未绑定银行卡,您要去绑定银行卡吗?',
    GetBankInfoFail: '拉取银行信息失败',
    EnterValidPayPwd: '请输入有效的支付密码',
    Balance: '余额',
    Total: '总共',
    total: '总共',
    records: '记录',
    DisplayPerPage: '每页显示',
    NullNum: '条',
    pages: '页。',
    toAudit: '待审核',
    succeed: '成功',
    RechargeRecordTitle: '充值记录',
    rests: '其他',
    Arrive: '到账',
    forzenCommission: '冻结佣金',
    companyIntro: '公司简介',
    companyTitle: '塔塔购物中心是塔塔集团的一部分',
    warmTitle: '温馨提示',
    companyText: '塔塔集团是印度最大的商业集团，成立于1868年，总部设在印度孟买。业务运作涉及七个业务领域:通信和信息技术、工程、材料、服务、能源、消费品和化工产品。作为印度快速发展的商业集团，塔塔集团在六大洲80多个国家拥有200多家子公司，产品和服务出口到85个国家。塔塔集团2020-2021财年的总收入为1009亿美元，其中58%来自海外业务。集团在全球拥有约45万名员工。自153年以来，塔塔这个名字在印度一直受到广泛尊重，因为它坚持良好的价值观和商业道德。在福布斯全球最受尊敬的公司中排名第11位。',
    depositAddress: '充值地址',
    rechargeSteps: '充值步骤',
    completePayment: '充值完成',
    rechargeNow: '立即充值',
    rechargeCancel: '取消充值',
    bankName: '银行名称',
    payee: '收款人',
    cardNum: '收款账户',
    reviewing: '审核中',
    remittance: '已汇款',
    notThrough: '未通过',
    OrderID: '订单ID',
    toCutScreen: "如果图片不可用请进行截屏!",
    "cardTabBank": "银行",
    "cardTabUSDT": "USDT",
    "name": "名称",
    "chainType": "钱包类型"
  },
  add: {
    Commission: '今日未结算金额',
    Yesterday: '昨日完成收益',
    Cumulative: '历史累计首日',
    Today: '今日预计收益',
    TeamNum: ' 已邀请有效人数',
    TeamMoney: '已获取收益',
    Personalcenteraccountchangerecord: '变账记录',
    lockedbalance: '未结算金额',
    Cumulativeinvitation: '累计邀请', //
    Validinvitation: '有效邀请',
    Accumulatedrebate: '累计返利',
  },
  mine: {
    servier: '客服'
  },
  Button: {
    cancle: '取消',
    confirm: '确定',
  },
  "home.btn.wallet": "理财",
  "home.btn.recharge": "充值",
  "home.btn.withdraw": "提现",
  "home.btn.invite": "邀请",
  "home.btn.message": "消息",
  "page.grab": "抢单",
  "orderCommission": "佣金",
  "commission": "佣金",
  "todayIncome": "今日收益",
  "personalEarnings": "个人收入",
  "completedOrders": "已完成订单数",
  "lockedOrders": "锁定订单数",
  "unfinishOrder": "未完成订单数",
  "frozonAmount": "未结算资金",
  "currentBalance": "余额",
  "page.mine": "账户明细",
  "mine.invitationCode": "邀请码",
  "mine.serverCenter": "服务中心",
  "mine.tools.total": "总计",
  "mine.tools.financial": "理财",
  "mine.tools.details": "账变记录",
  "mine.tools.freeze": "未结算金额",
  "mine.serviceTools.personal": "个人",
  "mine.serviceTools.teamReport": "团队",
  "mine.serviceTools.invite": "家庭账户",
  "mine.serviceTools.message": "消息",
  "mine.serviceTools.aboutUs": "关于我们",
  "mine.serviceTools.address": "地址",
  "mine.serviceTools.inviteTask": "邀请任务",
  "page.team": "团队",
  "page.aboutus": "关于我们",
  "page.financial": "理财",
  "page.financialRecord": "理财订单",
  "hint.InternetErr": "网络错误,请稍后重试!",
  "totalDeposit": "总存款",
  "totalRevenue": "总收入",
  "btn.transferInto": "转入",
  "btn.transferOut": "转出",
  "page.transferInto": "转入",
  "page.transferOut": "转出",
  "balanceTransfer": "可用余额",
  "availableBalance": "可用余额",
  "depositRate": "利息",
  "days": "天",
  "hint.success": "成功",
  "hint.fail": "失败",
  "status.into": "转入",
  "status.out": "转出",
  "label.amount": "数量",
  "label.time": "时间",
  "dialog.title.inputPaypass": "输入支付密码",
  "dialog.nopaypass": "尚未设置支付密码,点击确定去设置支付密码!",
  "mine.serviceTools.lan": "语言",
  "checkLans": "切换语言",
  "mine.tools.commission": "佣金",
  "page.commissionRecord": "佣金",
  "noticeList.all": "所有",
  "noticeList.unread": "未读的",
  "successWithdrawof": "成功提现",
  "home.title.notice": "请注意",
  "home.title.patener": "合作商",
  "login.rememberPassword": "记住密码",
  "page": {
    "cart": "订单",
    "order": "订单",
    "financing": "理财",
    "goods": "货物",
    "levelUpgrade": "提升等级",
    "PlatformIntroduction": "平台介绍",
    "RuleDescription": "规则",
    "AgentCooperation": "代理合作",
    "CommonProblem": "常见问题",
    "MemberUpgrade": "会员升级",
    "login": "登录",
    "forgot": "忘记密码",
    "registrasi": "注册",
    "record": "记录",
    "Recharge": "充值",
    "inviteFriends": "邀请",
    "Withdraw": "提现",
    "Service": "客服",
    "DialoguePage": "对话页面",
    "user": "个人",
    "RansferIn": "转入",
    "PersonalCenter": "个人中心",
    "Portrait": "头像",
    "Name": "名字",
    "Account": "账户",
    "Password": "密码",
    "PaymentCode": "支付密码",
    "Site": "网站",
    "AccountData": "账变记录",
    "RechargeRecord": "充值订单",
    "WithdrawalRecord": "提现订单",
    "InviteFriends": "邀请",
    "Announcement": "公告",
    "TheTeamReport": "团队报告",
    "BillRecord": "下注订单",
    "BankCardAdd": "添加银行卡",
    "NewRecharge": "充值",
    "RechargeList": "充值订单",
    "newWithdraw": "提现",
    "withdrawList": "提现订单",
    "SettingPayPwd": "修改支付密码",
    "MakeMoney": "赚钱",
    "team": "团队",
    "qrPay": "二维码支付",
    "bankPay": "银行支付",
    "aboutus": "关于我们",
    "financial": "理财",
    "financialRecord": "理财记录",
    "TransferInto": "转入",
    "TransferOut": "转出",
    "CommissionRecord": "佣金",
    "Security": "安全",
    "Address": "地址",
    "Message": "消息",
    "InviteTask": "要求任务",
    "Notice": "公告",
    "modifyPaymentPassword": "修改支付密码"
  },
  "Home.WithdrawSu.Balance": "余额",
  copyLink: "复制链接",
  "teamReport": {
    title: "团队报告",
    subTitle: "下属团队概览",
    taskTitle: "邀请{num}人注册并完成订单",
    bonus: "奖金",
    Unfinished: "未完成",
    Getted: "已领取"
  },
  copy: "复制",
  address: {
    name: "名字",
    inputPlaceholder: "请输入",
    phone: "电话",
    areaPlaceholer: "收货地址信息",
    submit: "保存"
  },
  "My.PersonalInformation.Name.Modify": "修改",
  "password.old": "旧密码",
  "password.old.placeholder": "请输入您的旧密码",
  "password.confirmPassword": "确认密码",
  "password.confirmPassword.placeholder": "请再次输入新密码",
  "hint.passwordDiff": "两个密码输入不一致",
  "loginType": {
    "phone": "电话",
    "email": "电子邮件"
  },
  "Login.Daftar.email": "电子邮件",
  "Login.emailInput": "请输入你的电子邮箱",
  "My.PersonalInformation.Password.email": "电子邮件",
  "My.PersonalInformation.Password.NewEmail": "请输入您的手机或邮箱",
  "mine.serviceTools.certify": "确认",
  "Certification": {
    "type": {
      "id": "身份证号",
      "driver": "驾驶执照",
      "passport": "护照"
    },
    "name": "姓名",
    "license": "许可证号码",
    "upload": "上传图片",
    "submit": "提交",
    "incompleteInformation": "请完善信息后重试！",
    "submitSuccess": "认证信息已提交，请等待审核！",
    "photo": "身份证照片",
    "review": "审查",
    "verified": "经过验证"
  },
  "upload": {
    "uploading": "上传中...",
    "failed": "上传失败"
  },
  "login.forgetPassword": "忘记密码，请联系客服！",
  "newAdd.BankNum.placeholder": "示例：09XXXXXXXXX",
  "page.certification": "身份验证",
  "newAdd.page": "页",
  "newAdd.record": "记录",
  "emailFormatError": "请输入正确的电子邮件地址!",
  "urlParsePlaceholder": "请输入粘贴标识",
  "btn.Identify": "立即识别",
  "produceStatus.confirming": "待确认",
  "produceStatus.paying": "待支付",
  "produceStatus.paid": "已支付",
  "produceStatus.done": "完成",
  "produceStatus.cancle": "退款",
  "order.old": "高额订单",
  "order.new": "普通订单",
  "welfareDialogTitle": "Benefit Tips",
  "viGrabDesces": {
    "1": "每个订单都可以被选为幸运订单，佣金将提高到 {rate}%。 请联系您的推广导师申请进一步的推广任务",
    2: "帮助 Shopee 卖家完成虚拟订单创建，提高知名度并获得佣金",
    3: "收到订单后请尽快联系您的推广导师，提交订单数据并完成任务",
    4: "任务数量有限，接单时请注意导师的推广建议",
    5: "退款金额将在完成任务后5-10分钟内发放，如有问题请咨询专家或在线客服。",
    6: "完成Shopee分配的任务，提升VIP等级，获得更高的Fackbook任务佣金",
    7: "累计完成指定虾皮任务，可获得额外佣金。 具体奖励请咨询促销专员或在线客服"
  },
  "expectedEarningsTitle": "预计收益"
}

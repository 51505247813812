/*
 * @Author: your name
 * @Date: 2022-02-23 18:27:08
 * @LastEditTime: 2022-04-08 15:18:40
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ShopTask-goshop\src\i18n\index.js
 */
import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
import verConfig from "../utils/verConfig";
import enLocale from "./en";
import inLocale from "./idn";
import thLocale from "./th";
import trLocale from "./tr";
import viLocale from "./vi"
import de from "./de"
import zhLocale from "./zh";
import fr from "./fr"
import ar from "./ar"
import pt from "./pt"
import es from "./es"
import hi from "./hi"
import ms from "./ms"

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
  },
  // zh: {
  //   ...zhLocale,
  // },
  // idn: {
  //   ...inLocale,
  // },
  th: {
    ...thLocale,
  },
  // tr: {
  //   ...trLocale,
  // },
  vi: {
    ...viLocale
  },
  de,
  fr,
  ar,
  pt,
  es,
  hi,
  ms
};

export const lans = [
  { name: "English", value: "en" },
  { name: "हिंदी", value: "hi" },
  // { name: "中文", value: "zh" },
  { name: "ไทย", value: "th" },
  { name: "Français", value: "fr" },
  { name: "أولابو", value: "ar" },
  // { name: "Tiếng Việt", value: "vi" },
  { name: "Deutsch", value: "de" },
  { name: "Malaysia", value: "ms" }
]

export function getLanguage() {
  // 从cookis 里面取语言
  const chooseLanguage = Cookies.get("language");
  if (!chooseLanguage) {
    Cookies.set("language", verConfig.lan || "en");
    localStorage.setItem('language', verConfig.lan || "en")
  }
  // if (chooseLanguage) return chooseLanguage;
  // const language = (
  //   navigator.language || navigator.browserLanguage
  // ).toLowerCase();
  // const locales = Object.keys(messages);
  // for (const locale of locales) {
  //   if (language.indexOf(locale) > -1) {
  //     return locale;
  //   }
  // }
  // return verConfig.lan || "en";
  return chooseLanguage || verConfig.lan || "en"
}

export function setLanguage(val) {
  Cookies.set("language", val);
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
});

export default i18n;

import axios from 'axios'
import qs from 'qs'
import store from '../store'
import { Toast } from 'vant'
import { getToken } from '@/utils/tools'
import verConfig from './verConfig'
import { router } from '../router'
import { getLanguage } from "@/i18n"

// export const domain = 'https://trmudo.com/'
export const domain = verConfig.url

// import config from './env.js'
// import { Message, MessageBox } from 'element-ui'
// import FileSaver from 'file-saver'
// const Toast = (message) => Message({ message, type: 'error' })

/* Create a new instance of axios with a custom config */
const token = getToken();
const interceptResponse = (res) => {
  if (res.data.code === '000040') {
    // Invalid login
    MessageBox.alert('登录失效，请重新登录', '警告', {
      type: 'warning',
      showClose: false,
    }).then(() => {
      store.dispatch('Exit').then(() => {
        // 重新实例化vue-router对象 避免bug
        location.reload()
      })
    })

    return Promise.reject(res.data)
  } else {
    return res
  }
}

/* Network Error Message */
const ERROR_MESSAGE = {
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  // REQUEST_ERROR: '发送请求时出现错误',
  REQUEST_ERROR: 'An error occurred while sending the request',
  // NO_RESPONDING: '服务器无响应',
  NO_RESPONDING: 'Network Error',
  // UNKNOWN_ERROR: '未知错误',
  UNKNOWN_ERROR: 'unknown error',
}



const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? `/api` : `${domain}/api`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest',
    'token': token,
    "lang": getLanguage()
  },
  paramsSerializer: (params) => qs.stringify(params, {
    skipNulls: true
  }),
  timeout: 60000,
  // withCredentials: true,
  withCredentials: false,
  responseType: 'json', // default
  validateStatus: (status) => status >= 200 && status < 300, // default
})

/* Add a request interceptor */
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config
  },
  (error) => {
    // Do something with request error
    Toast(ERROR_MESSAGE.REQUEST_ERROR)
    return Promise.reject(error)
  }
)

/* Add a response interceptor */
service.interceptors.response.use(
  (response) => {
    // Do something with response data
    const { ret, msg } = response.data
    if (ret === -1) {
      // Toast(msg)
      // setTimeout(()=> {
      if (verConfig.notLoginToRegist) {
        return router.replace("/registrasi");
      }
      router.replace("login")
      // },1000)
      return
    }
    return interceptResponse(response)
  },
  (error) => {
    // Do something with response error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const status = error.response.status
      // eslint-disable-next-line no-prototype-builtins
      if (ERROR_MESSAGE.hasOwnProperty(status)) {
        Toast(`${status} ${ERROR_MESSAGE[status]}`)
      } else {
        Toast(ERROR_MESSAGE.UNKNOWN_ERROR)
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      Toast(ERROR_MESSAGE.NO_RESPONDING)
    } else {
      // Something happened in setting up the request that triggered an Error
    }
    return Promise.reject(error)
  }
)

export const $get = (url, params) => service({
  url,
  method: 'get',
  params: (() => {
    if (!params) {
      params = new Object;
    }
    // params.lang = verConfig.lan || "en";
    return params
  })(),
  headers: {
    token: localStorage.getItem('token')
  }
})

export const $post = (url, data, params, mytoken, headers) =>
  service({
    url,
    method: 'post',
    headers: {
      'token': mytoken ? mytoken : localStorage.getItem('token')
    },
    params,
    data: (() => {
      if (!data) {
        data = new Object;
      }
      // data.lang = verConfig.lan || "en";
      return data
    })(),
  })

const uploadHeaders = { lang: getLanguage(), "Content-Type": "multipart/form-data", token: token }
export const $upload = ({ url = "/users/upload", data, name = "image", headers }) => new Promise(async (resolve, reject) => {
  try {
    const res = await service({ url, data, method: "POST", headers: { ...uploadHeaders, ...headers }, })
    resolve(res.data)
  } catch (error) {
    reject(error)
  }
})
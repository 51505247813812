import { $post, $get, dealError } from "@/utils"
import { router } from "../router"
import { setToken } from "../utils/tools"
import store from "./index"
let loading = false;
let toServiceFlag = false;
const user = {
  state: {
    shareUrl: "",
    systemConfig: {},
    banner: [],
    about: "",
    levels: [],
    unitData: []
  },
  mutations: {
    SET_SYSTEMCONFIG(state, data) {
      state.systemConfig = { ...state.systemConfig, ...data }
    },
    SET_SHAREURL(state, data) {
      state.shareUrl = data;
    },
    SET_BANNER(state, data) {
      state.banner = data;
    },
    SET_ABOUT(state, data) {
      state.about = data;
    },
    SET_LEVELS(state, data) {
      const res = [];
      for (let i = 0, len = data.length; i < len; i++) {
        const item = data[i];
        const pre = data[i - 1];
        if (!i) {
          res.push({ ...item, realValue: 20 });
          continue;
        }
        res.push({ ...item, realValue: pre.value });
      }
      state.levels = res;
    },
    SET_UNITDATA(state, data) {
      state.unitData = data;
    }
  },
  getters: {
    shareUrl(state) {
      const url = state.shareUrl;
      if (!url) {
        store.dispatch("getShare")
      }
      return state.shareUrl;
    },
    systemConfig(state) {
      const config = state.systemConfig;
      if (!config.scroll_notice) {
        store.dispatch("getSystemConfig");
      }
      return state.systemConfig;
    },
    banner(state) {
      const arr = state.banner;
      if (!arr.length) {
        store.dispatch("getBanner");
      }
      return state.banner;
    },
    about(state) {
      const text = state.about;
      if (!text) {
        store.dispatch("getAbout");
      }
      return state.about;
    },
    levels(state) {
      const arr = state.levels;
      if (!arr.length) {
        store.dispatch("getLevels");
      }
      return state.levels;
    },
    unitData(state) {
      const arr = state.unitData;
      if (!arr.length) {
        store.dispatch("getUnitData");
      }
      return state.unitData;
    },
  },
  actions: {
    getSystemConfig({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await $get("/other/config", { act: "system" })
          commit("SET_SYSTEMCONFIG", data)
        } catch (error) {
          dealError(error)
        }
      })
    },
    getShare({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await $get("/other/config", { act: "share" })
          commit("SET_SHAREURL", data.text)
        } catch (error) {
          dealError(error)
        }
      })
    },
    getBanner({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await $get("/content/slide", { position: "home" })
          commit("SET_BANNER", data || [])
        } catch (error) {
          dealError(error)
        }
      })
    },
    toService({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        const url = state.systemConfig.service_url;
        if (!!url) {
          return location.href = url;
        }
        try {
          const { data } = await $get("/other/config", { act: "system" })
          commit("SET_SYSTEMCONFIG", data);
          if (!!data.service_url) {
            location.href = data.service_url
          }
        } catch (error) {
          dealError(error)
        }
      })
    },
    download({ state, commit }) {
      return new Promise(async (resolve, reject) => {
        const url = state.systemConfig.appurl_android;
        if (!!url) {
          return location.href = url;
        }
        try {
          const { data } = await $get("/other/config", { act: "system" })
          commit("SET_SYSTEMCONFIG", data)
          if (!!data.appurl_android) {
            location.href = data.appurl_android
          }
        } catch (error) {
          dealError(error)
        }
      })
    },
    getAbout({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await $get("/other/config", { act: "about" });
          !!data && !!data.about && commit("SET_ABOUT", data.about)
        } catch (error) {
          dealError(error)
        }
      })
    },
    getLevels({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          // const { data } = await $get("/users/ranks");
          const { data } = await $get("/task/level");
          commit("SET_LEVELS", data)
        } catch (error) {
          dealError(error)
        }
      })
    },
    getUnitData({ commit }) {
      return new Promise(async (resolve, reject) => {
        if (loading) return
        loading = true;
        try {
          const { data } = await $get("/third/currency/rate");
          loading = false;
          commit("SET_UNITDATA", data);
        } catch (error) {
          loading = false;
          dealError(error)
        }
      })
    }
  },
}
export default user

import { $post, $get, dealError } from "@/utils"
import { router } from "../router"
import { setToken } from "../utils/tools"
import store from "./index"
import verConfig from "../utils/verConfig";
import { cookie } from '@/utils/tools'
let loading = false;
const user = {
  state: {
    userInfo: {},
    theme: cookie.get('theme') || 'dark',
    giftList:[],
  },
  mutations: {
    SET_USERINFO(state, data) {
      state.userInfo = { ...state.userInfo, ...data };
    },
    SET_THEME(state, data) {
      state.theme = data
    },
    SET_GIFTLIST(state,data){
      state.giftList = data
    }
  },
  getters: {
    userInfo(state) {
      const token = state.userInfo.token;
      if (!token) {
        store.dispatch("getUserInfo")
      }
      return state.userInfo;
    },
    theme(state) {
      return state.theme
    },
    giftList(state){
      if(!state.giftList.length){
        store.dispatch("setGiftList")
      }
      return state.giftList
    }
  },
  actions: {
    login({ commit }, params) {
      return new Promise((resolve, reject) => {
        $post("/users/login", params).then(({ data }) => {
          setToken(data.token)
          $get("/task/user", {}, { headers: { token: data.token } }).then(({ data: taskRes }) => {
            commit("SET_USERINFO", { ...data, ...taskRes });
            router.replace({ name: "goods" })
            resolve({ ...data, ...taskRes });
          }).catch(err => {
            dealError(err)
            reject(err)
          })
        }).catch(err => {
          dealError(err)
        })
      })
    },
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        if (loading) return;
        loading = true;
        Promise.all([$get("/users/info"), $get("task/user")]).then(([{ data }, { data: taskRes }]) => {
          loading = false;
          commit("SET_USERINFO", { ...data, ...taskRes })
        }).catch(err => {
          loading = false;
          dealError(err)
        })
      })
    },
    updateTaskUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        $get("task/user").then(({ data }) => {
          commit("SET_USERINFO", data)
        }).catch(err => {
          dealError(err)
        })
      })
    },
    setTheme({ commit }, theme) {
      commit("SET_THEME", theme)
      cookie.set("theme", theme)
    },
    setGiftList({commit}){
      return new Promise((resolve, reject) => {
        $post('task/gift/product').then(({data})=>{
          commit("SET_GIFTLIST",data.lists)
        }).catch(err=>{
          dealError(err)
        })
      })
    }
  },
}
export default user

export default {
  // 公共的key
  common: {
    unrealized: 'Ainda não implementado',
    all: 'todos',
    distributionTip: 'A partir de $0 entrega 0 taxa de entrega',
    easeBack: 'Paz de espírito',
    addCarSuccess: 'Adicionado com sucesso ao carrinho',
    deleteConfirm: 'Tem certeza de que deseja remover os itens selecionados?'
  },
  //商品详情
  goodsDetail: {
    goodsDetail: 'Detalhes da mercadoria',
    shopping: 'Rush em venda',
    end: 'Fim da distância',
    sold: 'vendidos',
    goodsTips: 'Este item é avaliado em 500g/ porção. Se o valor recebido for inferior a 500g, a diferença será reembolsada',
    quickTime: 'Entrega em 29 minutos',
    specifications: 'especificação',
    preservationConditions: 'Condições de conservação',
    coldStorage: 'refrigeração',
    shelfLife: 'Prazo de validade',
    day: '15 dias',
    addToCar: 'Adicionar ao carrinho'
  },
  //购物车
  car: {
    title: 'Carrinho de compras',
    delete: 'apagar',
    tip: 'Carrinho vazio drops~',
    walk: 'Ir e passear',
    all: 'Cobrir todos',
    guess: 'Acho que você gosta'
  },
  // 首页
  home: {
    HallofFame: 'Hall of Fame',
    chooseLocation: 'Por favor, selecione uma localização..',
    more: 'mais',
    home: 'Ficha técnica',
    category: 'classificação',
    eat: 'O que comer',
    cart: 'Carrinho de compras',
    mine: 'Conta',
    inputGoodsName: 'Digite o nome comercial',
    vipDesc: 'Junte-se ao membro · economia estimada de $806 por ano',
    vipSubDesc: 'Cartão aberto com 50% de desconto>',
    specialZone: 'Áreas especiais em destaque',
    foodie: 'Festival dos foodies',
    dinner: 'O jantar.',
    popularity: 'O sentimento',
    goodChoose: 'O coração escolhe',
    limitBuy: 'Venda flash disponível',
    bottomTip: 'Eu tenho uma linha',
    chooseAddress: 'Por favor selecione um endereço',
    currentLocation: 'Sua localização atual',
    nearbyAddress: 'Endereços nas proximidades'
  },
  // 分类
  category: {
    tip: 'Digite o nome comercial',
    bottomTip: 'Isso é tudo. Veja as outras categorias.'
  },
  // 吃什么
  eat: {
    all: 'todos',
    close: 'guarda',
    pleaseHolder: 'Procurar receitas, ingredientes',
    menu: 'Menu do dia',
    menuDevide: 'Menu por categoria',
    recent: 'Pesquisado recentemente',
    tip_bottom: 'Raios, pára de puxar!',
    tip: 'Coleção ainda não realizada oh~'
  },
  // 我的
  mine: {
    title: 'Conta',
    phone: 'Telefone celular não',
    login: 'Entrar agora mesmo',
    myOrder: 'A minha encomenda',
    allOrder: 'Ver pedido completo',
    myBill: 'Os meus cupons',
    myLocation: 'Meu endereço de entrega',
    myCar: 'Meu green card',
    servier: 'Contacte o atendimento',
    servierTime: 'Horário de atendimento 07:00-22:00',
    feedback: 'Comentários comentários comentários',
    switchLanguage: 'línguas',
    chinese: 'chinês',
    english: 'inglês',
    version: 'A versão atual',
    waitingPay: 'A ser pago',
    waitingReceive: 'Para receber',
    waitingComment: 'Para avaliação',
    waitingFeedback: 'Pós-venda/reembolso',
    unrealized: 'A página de reembolso ainda não está disponível.!',
    tip2: 'Lembra um pouco uma pequena estrela ❤ ️ oh~',
    tip3: '💘 obrigado pela atenção 💘',
    feedbackTip: 'Ainda não há pedidos relevantes',
    cardPrise: 'Preço do green card',
    moreMessage: 'Mais privilégios em breve...',
    openCard: 'Abrir um green card',
    recomend: 'Abrir green card para desfrutar de três interesses, espera-se que cada pedido economize RMB 6.66',
    cardTip1: 'Cupom exclusivo para',
    cardTip2: 'Preços especiais exclusivos',
    cardTip3: '2x integração',
    cardTip4: 'Ainda mais privilégios',
    vipType: '5 vezes',
    vipTime: 'Passe anual ·365 dias',
    vipRecommend: 'Equivalente a cerca de $0.24 / dia',
    payMethod: 'Formas de pagamento',
    wechatPay: 'Pagamento com wechat',
    aliPay: 'Pagamento com alipay',
    huabeiPay: 'Gastar e pagar',
    payImmeatally: 'Pague agora mesmo',
    cardUeless: 'Green card não aberto',
    tip: 'É aqui que temos de chegar! A concubina lá atrás não consegue.~~',
    changeNickName: 'Alterar seu apelido',
    confirm: 'conservação',
    nickName: 'Por favor insira um novo apelido',
    personal: 'Perfil modificado com sucesso!',
    message: 'Por favor insira um apelido',
    myBills: 'Os meus cupons',
    bill: 'cupão',
    condition: 'Até $1.5 fora sem limite de uso \n',
    rmb: 'yuan',
    myOrders: 'A minha encomenda',
    itemsTitle: ['todos', 'A ser pago', 'Para receber', 'Para avaliação'],

    personalInfo: 'Dados pessoais disponíveis',
    head: 'cabeça',
    user_name: 'alcunha',
    userSex: 'sexo',
    Brithday: 'aniversário',
    phoneNumber: 'Telefone celular não',
    noInput: 'Não preenchido',
    logout: 'Sair do login',
    infoTip: 'Perfil modificado com sucesso',
    loginInfo: 'Tem certeza de sair do login?',
    infoSuccess: 'Sair com sucesso',
    year: 'anos',
    month: 'mês',
    day: 'De outubro',

    greenCard: 'residência',
    greenCardPower: 'Privilégios do green card',
    fiftypecert: '50% de desconto na abertura do green card',
    cardRecoamnd: 'Green card exclusivo obter benefícios todos os dias',
    TodayExclusivecoupon: 'Cupom exclusivo do dia',
    everyUpadate: 'Atualizado diariamente 0 pontos',
    to: 'cheia',
    toUse: 'Uso do meta',
    immeatallyGet: 'Receba agora mesmo',
    weekBill: 'Voucher exclusivo da semana',
    cardTip7: 'Receba agora mesmo',
    billTip: 'Green card exclusivo obter benefícios todos os dias',
    currentGoods: 'Suas compras atuais',
    cardShop: 'Abrir green card para compras',
    cardBack: 'Os pontos de reembolso são',
    cardSpecialTip: 'Aceleração integral de ativação imediata',
    cardSpecial: 'Green card especial exclusivo',
  },
  // 订单
  order: {
    outTimeGetMoney: 'Mais de 10 minutos para obter compensação de pontos',
    inputForm: 'Preencha seu pedido',
    location: 'Escolha um endereço de entrega',
    arrivalTime: 'Tempo de entrega',
    total: 'total',
    thing: 'Uma coisa',
    wechatPay: 'Pagamento com wechat',
    aliPay: 'Pagamento com alipay',
    huabeiPay: 'Gastar e pagar',
    use: 'utilização',
    order12: 'Troca de pontos',
    mark: 'observações',
    tip: 'Preenchimento opcional, pode nos dizer suas necessidades especiais',
    goodsList: 'Lista de bens',

    totalMoney: 'Quantidade da mercadoria',

    sendMoney: 'Custo de entrega',
    point: 'integração',
    order19: 'Efectivamente pago',

    pay: 'Efectivamente pago',
    sendForm: 'Envie seu pedido',

    selectArrivalTime: 'Escolha o tempo de entrega',
    comfirm: 'A certeza',
    goods: 'produtos',
    all: 'total',
    things: 'Uma coisa',
    sigalPrice: 'Preço unitário:',
    numbers: 'quantidade:',
    addTip: 'Ainda não adicionou um endereço. Adicione um 😄',

    addLocation: 'Adicionar um endereço',
    comfirmAndUse: 'Salvar e usar',
    editLocation: 'Editar o endereço',
    myAddress: 'O meu endereço',
    settlement: 'liquidação',
    chooseGoods: 'Por favor, selecione a mercadoria para liquidar',
    deliveryTime: 'Por favor, selecione um tempo de entrega',
    today: 'hoje',
    tomorrow: 'amanhã'
  },
  Login: {
    indexTitle: 'inicio',
    Daftar: {
      title: 'De registo',
      phone: 'Por favor,coloque seu número',
      code: 'Por favor insira o captcha',
      password: 'Por favor,configure sua senha',
      againPassword: 'Por favor, confirme digitando sua senha novamente'
    },
    Forgot: {
      sendCode: 'enviar',
      confirm: 'Entre',
      goBack: 'Coloque sua conta'
    },
    nameInput: 'Por favor,coloque seu número',
    pwdInput: 'Por favor,configure sua senha',
    remenber: 'Lembrar a senha',
    loginButton: 'autenticação',
    registrasi: 'De registo'
  },
  // 登录
  login: {
    title: 'autenticação',
    phoneNumber: 'Telefone celular não',
    phoneNumberNotCorrect: 'Formato incorreto do número do celular',
    phoneNumberNotEmpty: 'Número de telemóvel não pode estar vazio',
    passNumberNotEmpty: 'A senha não pode estar vazia',
    phoneInput: 'Por favor insira o número do telemóvel',
    pass: 'senha',
    passTip: 'Por favor,configure sua senha',
    varify: 'Por favor insira o captcha',
    otherMethods: 'Outros métodos de login',
    wechat: 'Entrar no wechat',
    qqchant: 'QQ entrar em',
    tipTile: 'Dicas quentes:',
    tipContent: 'Número de telefone celular não registrado, que será registrado automaticamente ao fazer login, e o representante concorda',
    tip: 'Acordo do usuário',
    tipProcy: 'Politica de privacidade',
    login: 'autenticação',
    resgin: 'De registo',
    passTip2: 'Digite uma senha (pelo menos 6 dígitos)',
    sendVerify: 'Enviar código de verificação',
    hasSend: 'Já foi enviado',
    phoneVerify: 'Conta senha login',
    smsMessage: 'SMS código de verificação login',
    switchLoginMsg: 'Conta senha login',
    messageSuccess: 'Código de verificação obtido com sucesso. Por favor, insira-o na caixa de entrada',
    pleaseInputCorrectPhoneNumber: 'Por favor, insira o número de telefone correto',
    pleaseInputCorrectVerifyumber: 'Por favor, insira o captcha correto',
    otherWechat: 'Wechat login- temporariamente incompleto',
    otherQQ: 'QQ login- temporariamente incompleto',
    message: 'Código de verificação:'
  },
  Hint: {
    notMore: 'Não há mais',
    loading: 'Carregando em',
    failed: 'fracasso',
    SendCodeSuccess: 'Código de verificação enviado com sucesso!',
    phoneNot: 'Número de telefone/conta não pode estar vazio',
    pwdInconformity: 'Duas entradas de senha inconsistentes',
    notInput: 'A entrada está incompleta',
  },
  newTab: {
    home: 'Ficha técnica',
    money: 'comprar',
    order: 'ordens',
    my: 'Conta',
  },
  tab: {
    make_money: 'ordens'
  },
  My: {
    download: 'Baixe o APP',
    personal: 'Sua informação pessoal',
    recharge: 'Recarregar o registro',
    withdrawal: 'Vários locais',
    language: 'Configurações de idioma',
    logout: 'Sair.',
    MemberInformation: {
      balance: 'Saldo da conta',
      Withdraw: 'Vários locais',
      Recharge: 'Para recarregar',
      forzenAll: 'Montante total congelado'
    },
    PersonalInformation: {
      Page: {
        Avatar: 'cabeça',
        Nickname: 'alcunha',
        PhoneNumber: 'Número de telefone',
        BankAccounts: 'Cartão bancário',
        Password: 'senha',
        title: 'Sua informação pessoal'
      },
      portrait: {
        SetAvatar: 'Configurações de avatar',
        button: 'alterações'
      },
      Name: {
        ModifyNickname: 'Alterar seu apelido',
        title: 'Alterar seu apelido',
        name: 'alcunha',
        Please: 'Por favor, insira o apelido que deseja alterar'
      },
      Password: {
        title: 'Alterar senha de login',
        PleaseEnter: 'Digite a senha que deseja alterar',
        phone: 'Número de celular',
        NewPhone: 'Por favor,coloque seu número',
        verificationCode: 'Código de verificação',
        verificationCodeNote: 'Por favor insira o captcha',
        ConfirmPassword: 'senha',
        ConfirmPasswordNote: 'Digite a senha que deseja alterar',
        Text: 'Lembre-se de sua senha. Se você esqueceu sua senha, entre em contato com o atendimento ao cliente.',
        Button: 'Confirmar a modificação'
      },
      AccountData: {
        title: 'Detalhes da transação'
      }
    }
  },
  newAdd: {
    all: 'todos',
    Completed: 'Já foi concluído',
    unfinished: 'Não está terminado',
    Income: 'receitas',
    Expenditure: 'despesa',
    GetCodeBtn: 'Obter código de verificação',
    copySuccess: 'Replicar com sucesso',
    copyError: 'Falha na cópia',
    noLoginUserName: 'Por favor faça login',
    Withdraw: 'Vários locais',
    Recharge: 'Para recarregar',
    commission: 'Comissão de Carrinho',
    single: 'único',
    missionHall: 'Lobby de missões',
    videoTutorial: 'Tutoriais em vídeo',
    helpCenter: 'Centro de ajuda',
    inviteFriends: 'Convide um amigo',
    help1Title: 'Como ganhar comissões com CG',
    help1Context: 'O proprietário do comércio eletrônico precisa adicionar as vendas da loja, então só precisamos preencher os requisitos da loja de e-shop no CG e completar o pedido. O proprietário vai nos dar uma comissão. Cada usuário pode receber até 10 tarefas de ordem por dia. Você pode ganhar uma comissão de 10% a 30% em um dia.',
    help2Title: 'Como aumentar o número de tarefas',
    help2Context: 'Devido ao grande número de funcionários a tempo parcial, a empresa estabelece que todos os funcionários a tempo parcial on-line usam o único whatsapp para entrar em contato e receber tarefas',
    help3Title: 'Como completar as tarefas',
    help3Context: 'Ajude os grandes comerciantes a aumentar suas vendas e recompense você com base no número de transações. Para cada pedido bem-sucedido, você ganha uma comissão e o vendedor ganha uma reputação.',
    referralLink: 'Sugira um link',
    copy: 'reproduzir',
    blance: 'equilíbrio',
    todayOrder: 'Ordem de hoje',
    provided: 'Estes dados são fornecidos pelo',
    Transaction: 'acordo',
    Frozen: 'congelados',
    Done: 'terminar',
    Cancel: 'cancelar',
    ExpectedReturn: 'Retorno esperado para:',
    ImmediatePayment: 'Pague agora mesmo',
    AvailableNum: 'Tempos de disponibilidade',
    OrderImmediately: 'Faça seu pedido agora',
    MakeMoneyEveryDay: 'Ganhe dinheiro dia a dia',
    TodayRecord: 'Recorde do dia',
    AllocatCommissions: 'Distribua suas comissões',
    FrozenAmount: 'Montante congelado',
    TaskMatch: 'Distribuição de tarefas',
    AvailableBalance: 'Saldo disponível disponível',
    WaitDispatch: 'Aguardando atribuição de tarefas, entre em contato com o gerente da loja!',
    OrderDescription: 'Recorde do dia',
    CouldMatch: 'Cada conta pode ser combinada por dia',
    OrderPerDay: 'único。',
    DescriptionRate: 'Comissão de colocação fixa no valor do pedido',
    DescriptionPeriod: '。',
    Description3: 'Sistema baseado na tecnologia LBS para combinar mercadorias automaticamente através da nuvem。',
    Description4: 'Para evitar o monitoramento da plataforma, se a ordem for confirmada e enviada dentro de 20 minutos, o sistema congelará a quantidade da ordem。',
    Note: 'Precauções e precauções:',
    DescriptionNote: 'Quando a plataforma corresponde a um pedido para o usuário, a plataforma envia as informações do pedido para o back office do comerciante. Se o usuário não enviar um pedido dentro de 20 minutos, \
O valor do pedido será bloqueado pelo sistema. Para evitar o sistema de regulação, as contas de usuário serão avaliadas, afetando diretamente o usuário da próxima vez que o \
Tempo e boca a boca! Se houver outros que precisam ser tratados, por favor, clique em parar a ordem a tempo para evitar o congelamento fora do tempo',
    OrderNum: 'Número de encomenda:',
    TotalOrderPrice: 'Preço total:',
    SubmitNow: 'Envie agora mesmo',
    Record: 'registos',
    MemberAccount: 'Conta de membro',
    AccountPlaceholder: 'Por favor, insira sua conta',
    RechargeAmount: 'Montante da recarga',
    RechargeAmountPlaceholder: 'Por favor insira o valor de recarga',
    SelectRechargeMethod: 'Escolha o método de recarga',
    NotBeenSentOrder: 'O pedido ainda não foi entregue. Entre em contato com a equipe para distribuir o pedido!',
    TodayOrderOver: 'Pedido concluído hoje, tente novamente amanhã!',
    NotLogin: 'Você não está logado, faça login e tente novamente!',
    HaveNoteMethod: 'Este método de recarga não está disponível no momento. Por favor, escolha outro método de recarga~',
    Login: 'autenticação',
    AccountRecord: 'Registro de mudança de conta',
    RealName: 'Nome completo',
    IFSCCODE: 'Pix CPF',
    BankName: 'Chave Pix',
    BankNum: 'Conta Pix',
    Mobile: 'telemóvel',
    MobileNum: 'Número de celular',
    Email: 'Caixa de correio',
    EmailRequired: 'Email é necessário',
    AddBankCardNote: 'O cartão bancário é uma informação importante, preencha-o cuidadosamente!',
    Submit: 'Entre',
    AddBankCardTitle: 'Adicione pix',
    BankCard: 'Cartão bancário',
    Name: 'O nome',
    CardNum: 'Número do cartão',
    Reading: 'Em leitura...',
    AllBalance: 'Saldo total disponível',
    EnterWithdrawalAmount: 'Por favor, insira o valor refletido',
    AllOut: 'todos',
    AskNow: 'Vários locais',
    EnterPwdPlaceholder: 'Por favor,configure sua senha',
    NotHaveCard: 'Você ainda não vinculou um cartão bancário. Você quer vinculá-lo?',
    GetBankInfoFail: 'Falha ao puxar informações bancárias',
    EnterValidPayPwd: 'Por favor, insira uma senha de pagamento válida',
    Balance: 'equilíbrio',
    Total: 'No total',
    total: 'No total',
    records: 'registos',
    DisplayPerPage: 'Mostrar por página',
    NullNum: 'linha',
    pages: 'páginas',
    toAudit: 'Para revisão',
    succeed: 'sucesso',
    RechargeRecordTitle: 'Recarregar o registro',
    rests: 'outros',
    Arrive: 'Na conta',
    forzenCommission: 'Congelar a comissão',
    companyIntro: 'Perfil da empresa',
    companyTitle: 'Tata mall faz parte do grupo tata',
    companyText: 'O grupo tata é o maior grupo empresarial da índia, fundado em 1868 e com sede em mumbai, índia. As operações comerciais abrangem sete áreas de negócio: tecnologias de comunicação e informação, engenharia, materiais, serviços, energia, bens de consumo e produtos químicos. Como um grupo empresarial em rápido crescimento na índia, o grupo tata tem mais de 200 subsidiárias em mais de 80 países em seis continentes e exporta seus produtos e serviços para 85 países. A receita total do grupo tata para o ano fiscal 2020-2021 foi de us $100, 9 bilhões, 58% dos quais provêm de operações no exterior. O grupo emprega cerca de 450.000 pessoas em todo o mundo. Desde 153, o nome tata tem sido amplamente respeitado na índia por defender bons valores e ética nos negócios. 11 ª empresa mais admirada do mundo pela forbes.。',
    depositAddress: 'Dirección de recarga',
    rechargeSteps: 'Pasos para recargar',
    completePayment: 'Recarga completa',
    rechargeNow: 'Recarga ahora mismo',
    rechargeCancel: 'Cancelar una recarga',
    bankName: 'Nombre del banco',
    payee: 'receptor',
    cardNum: 'Cuentas de cobro',
    reviewing: 'Em revisão',
    remittance: 'enviado',
    notThrough: 'Não passou',
    OrderID: 'ordens ID',
    Amount: 'amount',
    toCutScreen: "Please take a screenshot if the image is not available!",
    cardTabBank: 'Bank',
    cardTabUSDT: 'USDT',
    name: 'Name',
    chainType: 'Chain type',
    "newNavTitle": "Enviar pedido",
    "shopMoney": "Quantidade de mercadorias",
    "actualMoney": "Envio real",
    "commitBtnText": "Gerar ordens",
    "commitHint": "Entrada incompleta",
  },
  "mine": {
    "servier": "Servidor",
    "integral": "Integrante",
    "balance": "Resgatar",
    "record": "Registro",
    "wtdAmt": "Resgatar pontos",
    "avaBlc": "Quantidade de troca",
    "allWtd": "Todos",
    "close": "Cancelar",
    "commit": "confirme",
    "wtdHint": "Por favor, insira o valor correto de retirada"
  },
  "add": {
    "Commission": "Quantidade instável hoje",
    "Yesterday": "Ontem",
    "Cumulative": "Cumulativo",
    "Today": "Hoje",
    "TeamNum": " Tamanho total da equipe",
    "TeamMoney": " Lucro total da equipe",
    "Personalcenteraccountchangerecord": "Registro de mudança de conta",
    "lockedbalance": "quantidade pendente",
    "Cumulativeinvitation": "Convite cumulativo",
    "Validinvitation": "Convite válido",
    "Accumulatedrebate": "Desconto acumulado"
  },
  "home.btn.wallet": "Fundos",
  "home.btn.recharge": "Depositar",
  "home.btn.withdraw": "Saque",
  "home.btn.invite": "Convidar",
  "home.btn.message": "Mensagem",
  "page.grab": "Pegar",
  "orderCommission": "Lucro do pedido",
  "commission": "Lucro",
  "todayIncome": "Hoje renda",
  "personalEarnings": "Ganhos pessoais",
  "completedOrders": "Pedidos concluídos",
  "lockedOrders": "Ordens bloqueadas",
  "unfinishOrder": "Ordens inacabadas",
  "frozonAmount": "Fundos instáveis",
  "currentBalance": "Balanço atual",
  "page.mine": "Detalhes da conta",
  "mine.invitationCode": "Código de Convite",
  "mine.serverCenter": "Centro de servidor",
  "mine.tools.total": "Total",
  "mine.tools.financial": "Fundos",
  "mine.tools.details": "Detalhes",
  "mine.tools.freeze": "Equilíbrio",
  "mine.serviceTools.personal": "Pessoal",
  "mine.serviceTools.teamReport": "Relatório da equipe",
  "mine.serviceTools.invite": "Conta familiar",
  "mine.serviceTools.message": "Mensagem",
  "mine.serviceTools.aboutUs": "Sobre nós",
  "mine.serviceTools.security": "Segurança",
  "mine.serviceTools.address": "Endereço",
  "mine.serviceTools.inviteTask": "Convite tarefa",
  "page.team": "Equipe",
  "page.aboutus": "Sobre nós",
  "page.financial": "Fundos",
  "page.financialRecord": "Registro financeiro",
  "hint.InternetErr": "Erro de rede, tente novamente mais tarde!",
  "totalDeposit": "Depósito total",
  "totalRevenue": "Rendimento total",
  "btn.transferInto": "Transferência para",
  "btn.transferOut": "Transferir para fora",
  "page.transferInto": "Transferência para",
  "page.transferOut": "Transferir para fora",
  "balanceTransfer": "Transferência de saldo",
  "availableBalance": "Saldo disponível",
  "depositRate": "Taxa de depósito",
  "days": "dias",
  "hint.success": "Sucesso",
  "hint.fail": "Fracassado",
  "status.into": "Transferência para",
  "status.out": "Transferir para fora",
  "label.amount": "Quantia",
  "label.time": "Tempo",
  "Button": {
    "cancle": "Cancelar",
    "confirm": "Confirme"
  },
  "dialog.title.inputPaypass": "Insira senha de pagamento",
  "dialog.nopaypass": "A senha de pagamento não foi definida, clique em OK para ir para a configuração!",
  "mine.serviceTools.lan": "Linguagem",
  "checkLans": "Switch Language",
  "mine.tools.commission": "Lucro",
  "page.commissionRecord": "Lucro",
  "noticeList.all": "Todos",
  "noticeList.unread": "Não lida",
  "successWithdrawof": "retirada bem -sucedida de",
  "home.title.notice": "Perceber",
  "home.title.patener": "Em parceria com",
  "login.rememberPassword": "Lembre -se da senha",
  "page": {
    "cart": "Carrinho",
    "order": "Ordem",
    "financing": "Financiamento",
    "goods": "Bens",
    "levelUpgrade": "Atualização de nível",
    "PlatformIntroduction": "Introdução da plataforma",
    "RuleDescription": "Descrição da regra",
    "AgentCooperation": "Cooperação do agente",
    "CommonProblem": "Problema comum",
    "MemberUpgrade": "Atualização de membros",
    "login": "Conecte-se",
    "forgot": "Esquecido",
    "registrasi": "Registrasi",
    "record": "Registro",
    "Recharge": "Depositar",
    "inviteFriends": "Convide amigos",
    "Withdraw": "Saque",
    "Service": "Serviço",
    "DialoguePage": "Página de diálogo",
    "user": "Pessoal",
    "RansferIn": "Ransferin",
    "PersonalCenter": "Centro pessoal",
    "Portrait": "Retrato",
    "Name": "Nome",
    "Account": "Conta",
    "Password": "Senha",
    "PaymentCode": "PAGENSCODE",
    "Site": "Site",
    "AccountData": "AccountData",
    "RechargeRecord": "Recorde de recarga",
    "WithdrawalRecord": "Registro de retirada",
    "InviteFriends": "Convide amigos",
    "Announcement": "Anúncio",
    "TheTeamReport": "O relatório da equipe",
    "BillRecord": "Registro de contas",
    "BankCardAdd": "Adicionar cartão de banco",
    "NewRecharge": "Completar",
    "RechargeList": "Lista de recarga",
    "newWithdraw": "Saque",
    "withdrawList": "Lista de retiradas",
    "SettingPayPwd": "Definindo PayPass",
    "MakeMoney": "Fazer dinheiro",
    "team": "equipe",
    "qrPay": "qrcode-pay",
    "bankPay": "pay-pay",
    "aboutus": "Sobre nós",
    "financial": "Fundos",
    "financialRecord": "Registro financeiro",
    "TransferInto": "Transferência para",
    "TransferOut": "Transferir para fora",
    "CommissionRecord": "Lucro",
    "Security": "Segurança",
    "Address": "Endereço",
    "Message": "Mensagem",
    "InviteTask": "InviteTask",
    "Notice": "Perceber",
    "modifyPaymentPassword": "Modificar senha de pagamento"
  },
  "copyLink": "Link de cópia",
  "teamReport": {
    "title": "Relatório da equipe",
    "subTitle": "Visão geral da equipe subordinada",
    "taskTitle": "Convide {num} pessoas para se inscrever e concluir o pedido",
    "bonus": "Bônus",
    "Unfinished": "Inacabado",
    "Getted": "Sendo levado"
  },
  "copy": "cópia de",
  "address": {
    "name": "Nome",
    "inputPlaceholder": "Por favor preencha",
    "phone": "Telefone",
    "areaPlaceholer": "Endereço de entrega Informação",
    "submit": "Salvar"
  },
  "password.old": "Senha Antiga",
  "password.old.placeholder": "Digite sua senha antiga",
  "password.confirmPassword": "Confirme sua senha",
  "password.confirmPassword.placeholder": "Digite uma nova senha novamente",
  "hint.passwordDiff": "As duas senhas não combinam",
  "loginType": {
    "phone": "Telefone",
    "email": "E-mail"
  },
  "Login.Daftar.email": "E-mail",
  "Login.emailInput": "Digite seu e-mail",
  "My.PersonalInformation.Password.email": "e-mail",
  "My.PersonalInformation.Password.NewEmail": "Por favor, digite seu e -mail de celular",
  "mine.serviceTools.certify": "Verificação",
  "Certification": {
    "type": {
      "id": "Número de identidade",
      "driver": "Carteira de motorista",
      "passport": "Passaporte"
    },
    "name": "Nome",
    "license": "Número de licença",
    "upload": "Anexo de documento",
    "submit": "Depositar",
    "incompleteInformation": "Por favor, preencha as informações e tente novamente!",
    "submitSuccess": "As informações de certificação foram enviadas, aguarde a revisão!",
    "photo": "ID Photo",
    "review": "sob revisão",
    "verified": "verificado"
  },
  "upload": {
    "uploading": "Enviando...",
    "failed": "Falha no upload"
  },
  "login.forgetPassword": "Esqueceu sua senha, entre em contato com o atendimento ao cliente!",
  "newAdd.BankNum.placeholder": "Exemplo: 09xxxxxxxxx",
  "page.certification": "VERIFICAÇÃO DE IDENTIDADE",
  "newAdd.page": "página",
  "newAdd.record": "registro",
  "emailFormatError": "Por favor, insira o endereço de e -mail correto!",
  "urlParsePlaceholder": "Digite a identificação da pasta",
  "btn.Identify": "Identificar imediatamente",
  "produceStatus.confirming": "A ser confirmado",
  "produceStatus.paying": "Ser pago",
  "produceStatus.paid": "Pago",
  "produceStatus.done": "Feito",
  "produceStatus.cancle": "Devolveu",
  "order.old": "Ordem de alta renda",
  "order.new": "Ordem regular",
  "welfareDialogTitle": "Dicas de benefício",
  "viGrabDesces": {
    "1": "Cada pedido pode ser selecionado como uma ordem de sorte e a comissão será aumentada para {taxa}%.Entre em contato com o seu mentor de divulgação para solicitar outras tarefas de divulgação",
    "2": "Ajude os vendedores de compradores a completar a criação de pedidos virtuais, aumentar a visibilidade e ganhar comissões",
    "3": "Entre em contato com o seu tutor de promoção o mais rápido possível depois de receber o pedido, envie os dados do pedido e conclua a tarefa",
    "4": "O número de tarefas é limitado, preste atenção às sugestões de promoção do instrutor ao aceitar ordens",
    "5": "O valor do reembolso será emitido dentro de 5 a 10 minutos após a conclusão da tarefa.Se você tiver alguma dúvida, consulte um atendimento ao cliente especialista ou on -line.",
    "6": "Conclua as tarefas atribuídas pela Shopee, melhore o nível VIP e obtenha comissões mais altas para tarefas do Fackbook",
    "7": "Accumulativamente completa tarefas de compras designadas para obter comissões adicionais.Para recompensas específicas, consulte o especialista em promoção ou o atendimento ao cliente on -line"
  },
  "expectedEarningsTitle": "Ganhos esperados",
  "rechargeDesc": {
    "nav": "Recarregar a descrição",
    "p1": "Agora temos muitos funcionários concluindo tarefas ao mesmo tempo.",
    "p2": "Um vendedor não pode fornecer contas suficientes",
    "p3": "Então, publicamos nossa conta do vendedor em outro espaço em branco",
    "p4": "Quando você precisar concluir a tarefa, entre em contato com o suporte de vendas para registrar seu canal de pagamento"
  },
  "chat": {
    "list": {
      "nav": "Mensagem",
      "hours": "{h} horas",
      "minutes": "{m} minutos",
      "ago": "{time} atrás",
      "image": "imagem"
    },
    "room": {
      "msgPlaceholder": "Digite uma mensagem ...",
      "send": "Depositar",
      "sender": "Remetente",
      "sendTime": "Enviar tempo",
      "content": "Contente"
    }
  },
  "Login.Daftar.selectCurrency": "Escolha a moeda",
  "mine.totalBalance": "Balanço total",
  "newBank": {
    "usdt": {
      "name": "Nome",
      "currency": "Moeda",
      "chainType": "Tipo de corrente",
      "depositAddress": "Endereço de depósito",
      "submit": "enviar"
    },
    "walletList": {
      "pageTitle": "Gerenciamento de retirada",
      "name": "Alias da carteira:",
      "coinType": "Moeda:",
      "chainType": "Tipo de corrente:",
      "walletAddress": "Endereço:",
      "addDate": "data:",
      "btnText": "Adicione o endereço da carteira",
      "deleteConfirmText": "Tem certeza de que deseja excluir o endereço da carteira?",
      "btnConfirm": "Excluir",
      "btnCancle": "Cancle",
      "successHint": "bem-sucedido"
    },
    "withdrawNum": "Número de retiradas",
    "AllOut": "Todos",
    "balance": "Balanço geral",
    "handlingFee": "Taxa de manuseio",
    "actualAmount": "Montante atual"
  },
  "hashPlaceholder": "Por favor, digite hash",
  "recharge.status.error": "A criação de pedidos virtuais da Amazon falhou, envie o valor correto do hash e o valor do item",
  "recharge.actualAmount": "Conta real",
  "login.userAgreement": "Contrato do usuário",
  "login.title1": "Entre na sua conta",
  "login.title2": "Bem vindo de volta!",
  "registe.title1": "Entre na sua conta",
  "registe.title2": "Bem vindo de volta!",
  "mine.credit": "Crédito",
  "page.checkin": "Check-in",
  "checkin.submit": "Check-in",
  "checkin.success": "Assine com sucesso",
  "checkin.fail": "A entrada falhou",
  "todayChecked": "Você assinou, por favor, não repita a operação!",
  "submit.checked": "assinado",
  "hint.inviteBanned": "A conta familiar só está disponível após atingir o nível 3!",
  "newTab.blindBox": "Caixa cega",
  "blindBox.submit": "Começar loteria",
  "blindBox.times": "Oportunidade de abrir um baú: {__times__} vezes",
  "blindBox.records.title": "Meu recorde de vitórias",
  "banEditBankHint": "O cartão bancário é informações importantes, entre em contato com o atendimento ao cliente se você precisar modificá -las!",
  "level.order_num": "Saque concluído",
  "drawNum": "Número de caixas cegas",
  "recharge.good_title": "produtos",
  "recharge.blindBoxIncome": "Receita de caixa cega",
  "recharge.amount": "Quantia",
  "recharge.bonusRatio": "Taxa de bônus",
  "submit": {
    "desc": {
      "1": "Depois de concluir o produto do carrinho, vocéterá uma oportunidade de bônus da caixa cega e o intervalo de bonus é de 10 a 50% do valor do produto.",
      "2": "Com base na tecnologia LBS, o sistema corresponde automaticamente aos produtos e às oportunidades de loteria de caixas cegas através da nuvem.",
      "3": "Para evitar a supervisão da plataforma, se opedido não for confirmado e enviado em 30 minutos, o sistema congelará o valor do pedido.",
      "sub_1": "Por exemplo, se vocé pagar um valor depedidos de R $ 3000, a relação bônus da caixa cega é de10%a 50%e o intervalo de bônus real é de R $ 300-1500, o que significa que sua retirada minima é de R$ 3300 e a retirada máxima êR $ 4500.",
      "note": "Leia o conteúdo relevante com cuidado:",
      "note_sub": "Depois de corresponder ao pedido do usuário, o Shopee enviará as informações do pedido ao plano de fundo do comerciante.Se o usuário não enviar um pedido em 30 minutos, o valor do pedido será congelado pelo sistema.Para evitar a supervisão do sistema, aceite após a confirmação, todas as tarefas não podem ser canceladas ou substituidas após a aceitação!Se houver outros problemas que precisam ser tratados, clique para parar de fazer um pedido a tempo de evitar o congelamento de horas extras, obrigado."
    }
  },
  "drawNine": {
    times: "Chance de abrir o baú do tesouro",
    prizeHint: "Bônus de caixa cega",
    percentHint: "100% vencedor"
  },
  "hint.nullOfDraw": "Você não tem chance de desenhar",
  "hint.loginNotRead": "Leia o contrato e tente novamente!",
  "Home.WithdrawSu.Balance": "Dinheiro em conta",
  "Hint.updateText": "Atualize para a versão mais recente",
  "pages.editPhone": "modificação do número de telefone",
  "editPhone": {
    label: "Telefone",
    placeholder: "Telefone",
    hint: "Por favor, digite seu número de telefone"
  },
  "Button.submit": "Enviar",
  "pages.editEmail": "Vincular caixa de correio",
  "users.email": "E-mail",
  "users.userphone": "Telefone",
  "users.username": "Nome de usuário",
  "editEmail": {
    label: "E-mail",
    placeholder: "E-mail",
    hintRequire: "Por favor insira o endereço de e-mail",
    hintCorrect: "por favor digite seu e-mail válido"
  },
  "personal.page.email": "E-mail",
  "personal.page.phone": "Telefone",
  banEditPhone: "O número do celular é uma informação importante, se precisar modificá-lo, entre em contato com o atendimento ao cliente!",
  banEditEmail: "O e-mail é uma informação importante, se precisar modificá-lo, entre em contato com o atendimento ao cliente!",
  banEditUserInfo: "Informações importantes não podem ser modificadas, se você precisar modificar, entre em contato com o atendimento ao cliente!"
}
export default {
  "signStr": "1001",
  "tab": {
    "my": "Của tôi",
    "home": "Nhà",
    "record": "Lịch sử",
    "grab": "Nhiệm vụ",
    "financing": "Tài chính",
    "make_money": "Nhiệm vụ"
  },
  "newTab": {
    "home": "Nhà",
    "money": "Tiền bạc",
    "shop": "Cửa hàng",
    "order": "Lịch sử",
    "my": "Của tôi",
    "team": "Đội"
  },
  "common": {
    "unrealized": "Đang làm",
    "all": "Tất cả các",
    "distributionTip": "0Delivery0Distributionfee",
    "easeBack": "Dễ dàng",
    "addCarSuccess": "Tham gia shoppings thành công",
    "deleteConfirm": "AreyousuretodeletetheSelectedItem?"
  },
  "My": {
    "download": "Tải ứng dụng",
    "personal": "Thông tin cá nhân",
    "preOrde": "Đơn đặt hàng trước",
    "account": "Chi tiết tài khoản",
    "recharge": "Rechargerecord",
    "withdrawal": "Rút tiền",
    "invite": "Mời bạn bè",
    "announcement": "Sự thông báo",
    "service": "Dịch vụ",
    "team": "TeamReport",
    "quit": "Từ bỏ",
    "logoutEvent": "Xác nhậnToExit?",
    "language": "Cài đặt ngôn ngữ",
    "languageButton": {
      "english": "Tiếng Anh",
      "indonesian": "Indonesia"
    },
    "logout": "Đăng xuất",
    "MemberInformation": {
      "SilverMember": "Silvermember",
      "Invitation": "Mã mời",
      "balance": "Số dư tài khoản",
      "Recharge": "Nạp tiền",
      "Withdraw": "Rút tiền",
      "forzenAll": "Frozenmoney"
    },
    "PersonalInformation": {
      "Page": {
        "title": "Thông tin của tôi",
        "Avatar": "Hình đại diện",
        "Nickname": "Tên nick",
        "PhoneNumber": "Số điện thoại",
        // "BankAccounts": "Tài khoản ngân hàng", // 银行账户
        BankAccounts: 'Địa chỉ Ví của tôi',
        "Password": "Mật khẩu",
        "TransactionPassword": "Mật khẩu giao dịch",
        "ReceivingAddress": "Nhận"
      },
      "portrait": {
        "SetAvatar": "Avatar",
        "button": "Xác nhận"
      },
      "Name": {
        "ModifyNickname": "Sửa đổi Nick Name",
        "title": "Sửa đổi Nick Name",
        "name": "Tên nick",
        "Modify": "Sửa đổi",
        "Please": "Hãy nhập một nickname"
      },
      "BankAccounts": {
        "BankAccounts": "BindingBankCard",
        "YourInformation": "Thông tin của bạn",
        "RealName": "Tên thật",
        "RealNameNote": "PleasesetyourRealName",
        "PhoneNo": "Số điện thoại",
        "PhoneNoNote": "Xin vui lòng",
        "YourBankCard": "YourBankCardInformation",
        "BankAccount": "Tài khoản ngân hàng",
        "BankAccountNote": "PleasesetBankAccount",
        "BankName": "Tên ngân hàng",
        "BankNameData": {
          "option1": "CHOOSEACCOUNTBANK",
          "option2": "Bankcentralasia",
          "option3": "Banknegaraindonesia",
          "option4": "Bankrakyatindonesia",
          "option5": "Bankmandiri"
        },
        "BankBranch": "Chi nhánh ngân hàng",
        "BankBranchNote": "PleasesetBankBranch",
        "MoreInformation": "Thêm thông tin",
        "WhatsApp": "WhatsApp",
        "WhatsAppNote": "PleasesetWhatsAppnumber",
        "Text": "Nhắc nhở: Xin chào, inorderToguaranteEyourrights, PleaseBindTheCorrectCardNumberAndBankInformation.iftheaccountCannotByeveVerdUetoinceRectFilling, ThisPlatformDoShoStakeAresponsItion!",
        "Settings": "Cài đặt"
      },
      "Password": {
        "title": "Sửa đổi mật khẩu",
        "PleaseEnter": "Vui lòng nhập mật khẩu mới của bạn",
        "phone": "điện thoại",
        "NewPhone": "PLELENTERYOURCELLPHONENUMBER",
        "verificationCode": "Codeverifikasi",
        "verificationCodeNote": "PLELERENTEREVERIENTCODE",
        "oldPassword": "Mật khẩu cũ",
        "oldPasswordNote": "Vui lòng nhập mật khẩu mới của bạn",
        "ConfirmPassword": "Mật khẩu mới",
        "ConfirmPasswordNote": "Nhập mật khẩu mới của bạn",
        "Text": "Pleaserememberthepassword, ifyouforgetThePassword, pleasecontactCustomerservice.",
        "ButtonCode": "GetverificationCode",
        "Button": "Sửa đổi",
        "verifyPhone": "PLELENTERYOURCELLPHONENUMBER",
        "PhoneNew": "Pleaseconfirmthatyouhavefinished"
      },
      "Site": {
        "title": "Nhận hóa điều trị",
        "ReceivingIdentity": "Nhận thông tin",
        "ReceivingAddress": "Nhận thông tin",
        "DetailedAddress": "Địa chỉ chi tiết",
        "DetailedAddressNote": "PLELENTERDETAILEDDDRESS",
        "Set": "Setascommonaddress",
        "Button": "Xác nhận",
        "hint": "Pleaseconfirmthatyouhavefinished"
      },
      "AccountData": {
        "title": "Chi tiết giao dịch",
        "To": "Đến",
        "search": "Tìm kiếm",
        "Cancel": "Hủy bỏ",
        "Semua": "Semuajenis",
        "Penarikan": "Catatanpenarikan",
        "IsiUlang": "Mutasitop-up"
      },
      "RechargeRecord": {
        "title": "Rechargerecord",
        "Recharge": "Rechargeoffline",
        "Online": "Onlinerecharge",
        "Amount": "Số tiền cần nạp:",
        "Order": "OrderNumber:",
        "Status": "Rechargestatus:"
      },
      "WithdrawalRecord": {
        "title": "Rút tiền",
        "withdrawal": "BankcardwithDrawal"
      },
      "InviteFriends": {
        "Invite": "Liên kết mời: Nhấp vào Sao chép",
        "InvitationCode": "Mã mời:",
        "Click": "Sao chép đường link"
      },
      "Announcement": {
        "title": "Sự thông báo",
        "System": "Hệ thốngannouning"
      },
      "TheTeamReport": {
        "title": "TeamReport",
        "To": "Đến",
        "search": "Tìm kiếm",
        "Cancel": "Hủy bỏ",
        "balance": "TEAMAMOUNT",
        "turnover": "TeamRecharge",
        "recharge": "TeamorderCommission",
        "withdrawal": "TeamWithDrawal",
        "commission": "TeamorderCommission",
        "charge": "TotalNumberOfpeople",
        "people": "NumberOfusersWithRecharge",
        "members": "NumberOfNewusers",
        "NewPeople": "NewuserRecharge",
        "Active": "NumberOfNewusers",
        "Level1": "Cấp độ 1",
        "Level2": "Cấp độ 2",
        "Level3": "Cấp 3"
      }
    },
    "PlatformIntroduction": {
      "PlatformIntroduction": "PlatformIntrial",
      "Two": "ThisplatformisanallianceComosesofmillionsoftraders.itsserviceprincipleis: ThePlatformTradersIncreasethetradingVolume, vàtheplatformMemembereArnCommissionincomebyReCeIningerS.",
      "Three": "Thethird-partyIntelligentCloudcontrolsthehostordermatchingplatform.Theplatformcooperateswithotheronlineshoppingplatformmerchantstomatchbusinessorderswithplatformusersthroughintelligentcloudsystem,soastoincreasethetransactionvolumeandsellerreputationonthemainplatform.Thedeliverymethodofthepurchaseorderisimproved.Theorderisautomaticallysentbytheremoteintelligentcloudserver.Theplatformusersonlyneedtoprovidethecompletereceiveraddress,telephonenumberandsomeshoppinginformationdirectlyontheplatformtoenterthesmartcloudhost.",
      "Four": "Inordertohelpsellerseffectivelyimprovethesuccessrateofordersandavoidtheriskofsellerscheckingfalseorders,theplatformwilloptimizetheordermatchingofthedayaccordingtoyourcurrentoperatingIPandequipmenttype.Therefore,ordermatchingneedstoberealizedbyintelligentcloudcomputingalgorithm.Pleasewaitpatiently.Theplatformuserswillautomaticallybecometheplatformagentbypromotingnewpeopletojointheplatform,andtheycangetextracommission.",
      "Five": "YoujustneedtouseYoursParetimetosurftheInet, Andyoucaneasilyearnrichcommissioneveryday.AfteryouClickAndsuccessSendtheOrder, ThePrincipalandCommissionWillBereFunded."
    },
    "RuleDescription": {
      "RuleDescription": "Quyết định",
      "One": "1.anggotaharusmengisialamatpenerimadanmeningkatkaninformasipribadisebelummengambilpesanan.komisiange",
      "Two": "2.Karenapesananbarangmemerlukanwaktutertentuuntukberlaku,pesananhanyadapatditerimadenganpenjualsetelahpesananberlaku,olehkarenaitupenyelesaiankomisiplatformdanbanktripartitdananggotamenggunakansistemT+1.Yaitu:hariiniuntukmenarikdanuangakanditerimapadakeesokanharisebelum23:59.Jikapesanangagalberlakusetelahwaktuyangditentukan,platformakanmembayardiuangmuka,menyelesaikanakundanmengeluarkanpembayarandalamwaktuyangditentukan.",
      "Three": "3.Kondisiaulayangsesuadanrasiokomisianggotaperak: semuaanggotasilverdapatmengambil10pesananperhari, dankomisiuntukpesanantersebutadalah0,30%",
      "Four": "Anggotaemas: Untukmembukukaanggotaemas, Andaperlumengundanghingga3oranguntukmasukkeaulaini.andadapatmengambil35pesananperhari, dankomisiuntukpesananadalah0.35%",
      "Five": "Aygotaplatinum: Untukmembukaanggotaplatinum, andaperlumengundanghingga10oranguntukmemasukiaulaini.andabisamendapatkan40pesanperhari, dankomisiuntukpesan",
      "Six": "Aygotaplatinum: Untukmembukaanggotaplatinum, andaperlumengundanghingga10oranguntukmemasukiaulaini.andabisamendapatkan40pesanperhari, dankomisiuntukpesan",
      "Seven": "AygoTaberlian: Untukmembukaanggotadiamond, Andaperlumengundang30oranguntukmemasukiaulaini.andadapatmemperoleh45pesananperhari, dankomisiuntukpesantersebutadalah0,45%",
      "Eight": "4.HarapperiksanomorkartudepositplatformsebelumMENT",
      "Nine": "Catatan: Silakanperiksainformasikartubankdariplatformapakahituplatforminiuntuksetiaptransferuntukmenghindarikerugianekonomianda.",
      "Ten": "5.Untukmelindungiprofitantarapenjualdanplatform,olehkarenaitusetiapip,nomorkartubankdannomorponselhanyadapatberlakuuntuksatuakun.Semuaplatforme-niagautamamemilikimesinkontrolrisiko,Jikabanyakpenggunadiareayangsamadanipyangsamayangberbelanjaditokoyangsama,akanmenyebabkanprodukbahanpeledaktokodihilangkandanmempengaruhikredibilitasdanpenjualanpenjualtidakvalid.",
      "Eleven": "Các công ty, pelanggarakanpermekukanakunakunaperaper, achanggarakanmembekukanakunakunaudayankan, achanggarakanmembekukanakunakunapek đã ratankankankankankankankankankankankankankankankankankankankankankankankankankankankan, ALO",
      "OneData": {
        "One": "Pengenalantugas:",
        "Two": "Jenistugastarifkomisi",
        "Three": "Lazada0.30%",
        "Four": "Bukalapak0,35%",
        "Five": "Shopee0,40%",
        "Six": "Tokopedia0,45%"
      },
      "TwoData": {
        "One": "DeskRipsitingKatKeanGgotaan:",
        "Two": "Jeniskeanggotaananggotaperak",
        "Three": "Bataspenarikan50.000.000",
        "Four": "Jumlahpesanan30/Hari",
        "Five": "Tingkatkomisi0.30%",
        "Six": "Không thay đổi-"
      },
      "FourData": {
        "One": "Jeniskeanggotaananggotaplatinum",
        "Two": "Bataspenarikan50.000.000",
        "Three": "Jumlahpesanan40/hari",
        "Four": "Tingkatkomisi0,40%",
        "Five": "Undang10"
      },
      "FiveData": {
        "One": "Jeniskeanggotaananggotaberlian",
        "Two": "Bataspenarikan50.000.000",
        "Three": "Jumlahpesanan45/hari",
        "Four": "Tingkatkomisi0,45%",
        "Five": "Undang30"
      },
      "Twelve": "*Levelyangberbedadapatmemperolehangkaganjildanrasiokomisi.semakintinggilevelkeangaan, semakinbanyakangkaganjilyangbisaandaambil.semakinbesarurutyny"
    },
    "AgentCooperation": {
      "AgentCooperation": "Đại lý",
      "One": "Peluncuranstandartingkatketigauntukproxy",
      "Two": "Aygotadapatmenjadiproxyplatformdanganmerekomendasikananggotabaruyangbergabunguntukmendapatkanhadiahtambahan.",
      "Three": "Promosikanlangsunghadiahpenggunatingkatpertamauntukmendapatkankomisibonus9%setiaphari.",
      "Four": "Sebagaicontoh:",
      "Five": "ProksitingkatpertamaAndatelahmengundang10oranguntukbergabungmenjadianggota,proxytingkatkeduamengundang20oranguntukbergabungmenjadianggotadanproxytingkatketigatelahmengundang40oranguntukbergabungmenjadianggota.Pendapatanperoranguntuksehariadalah200.000INR,jadibayangkansajapenghasilanharianyangakanAndadapatkan.",
      "Six": "Levelpertama: 200.000x10x9%= 180.000inr",
      "Seven": "Tingkatkedua: 200.000x20x6%= 240.000inr",
      "Eight": "Tingkatketiga: 200.000x40x3%= 240.000inr",
      "Nine": "Levelpertama+tingkatkedua+tingkatketiga = 660.000inr",
      "Ten": "MisalkanandAndamengundanggotaauntukmendaftar, Amengundangb, Danbmengundangc,",
      "Eleven": "Kemudiananggotaaadalahpenggunatingkatpertamatimanda, badalahpengunatingkatkeduaanda, dancadalahpenggunatingkatketigaanda.",
      "Twelve": "Jumlahhadiah = komisipesananax9%+komisipesananbx6%+komisipesanancx3%.",
      "Thirteen": "*Pengingat: Hadiahkomisiuntuksemuaaagenyangmerupakananggotaofflinedikeluarkanolehplatformini, DantidakakanmemEngaruhikomisisemuaaagendananggotaoffline!"
    },
    "CommonProblem": {
      "CommonProblem": "Vấn đề thường gặp",
      "One": "Q: Mengapabisnismembutuhkanpesananvirtual?",
      "Two": "Menurutaturanplatforme-commerceutama,semakinbanyakorangmembeliproduk,semakinpopulerproduktersebut,dansemakinbanyakpeluangakandidorongdanditampilkanolehplatforme-commerce.Bisnise-commercesedangsengit.Untukmeningkatkaneksposurproduk,pedagangperlumeningkatkanvolumetransaksikomoditas,sehinggamerekamemilihuntukmenghasilkanpesananvirtualdiplatform.",
      "Three": "Q: Bagaimaanacaramenyelesaikanprosespengambilanpesanan?",
      "Four": "Pilihsalurane-niaga,klikuntukmengambilpesanansecaraotomatis,sistemdengancerdasmencocokkanprodukuntukmendapatkanpesanan.PlatformmenggunakaninformasipribadiAndayangsebenarnya,alamatpengiriman,danIPjaringanuntukmengirimkanpesanankeplatforme-commerceuntukmensimulasikanbelanjaonline.Namun,pedagangtidakbenar-benarmengirimkanbarangnya.Setelahpesananberhasildikirim,pokokdankomisipesananakansegeradikembalikankeakunAnda.",
      "Five": "Q: Mengapasayaperlumengisiulangsebelummenerimapesanan?",
      "Six": "Untukmemastikankeaslianpesanan, setiapplatformpemeananyangdisimulasikanmemerlukanpembayarannyatesakesituswebe-niaga.t",
      "Seven": "Q: Bagaimaanacarameningkatkanjumlahpesananbildanmeningkatkankomisi?",
      "Eight": "DenganMengundangtemanuntukmendaftar, semakintinggilevelnya, semakinbanyakjumlahtotalpesanperhari, dansemakintinggirasiokomisi;",
      "Nine": "Q: Bagaimaanacarengundangtemanuntukmendaftar?",
      "Ten": "Klik \"Undangteman\", Salintautanberbagiataukodeqr, Dankirimkanketeman, Temansekelas, Kolega, Dankeluargaandamelaluaperangkatlunaksosial.andaharusmengisikodeundanganunt",
      "Eleven": "Q: Bagaimaanacaramenarikuangtunai?",
      "Twelve": "Andadapatmengajukanpenarikantunaisetelahmemenuhikuantasminimumorder.jikasyaratkuantasminimumordertidakter"
    },
    "MemberUpgrade": {
      "MemberUpgrade": "Thành viên",
      "Member": "Thành viên ：",
      "Orders": "Chi tiết nhiệm vụ",
      "Commission": "Hoa hồng",
      "DedicatedOne": "Chuyên dụngCustomerservice",
      "DedicatedTwo": "Chuyên dụngCustomerservice",
      "Swipe": "Swipeleftandrighttoseemore",
      "One": {
        "Silver": "Silvermember",
        "Need": "NeedToinvite0people",
        "Withdrawal": "Rút tiền: 50.000.000",
        "quantity": "OrderQuantity: 10/ngày",
        "rate": "Ủy ban: 0,30%",
        "fee": "Rút tiền: 2,00%",
        "Activated": "Kích hoạt"
      },
      "Two": {
        "Silver": "Thành viên vàng",
        "Need": "NeedToinvite3people",
        "People": "3Peoplestillshort",
        "Withdrawal": "Rút tiền: 50.000.000",
        "quantity": "OrderQuantity: 35/ngày",
        "rate": "Ủy ban: 0,35%",
        "fee": "Rút tiền: 2,00%",
        "Activated": "Dailyrechargeamount: 1.000.000",
        "Invite": "Invitenow"
      },
      "Three": {
        "Silver": "Platinummember",
        "Need": "NeedToinvite10People",
        "People": "10PeapleStillShort",
        "Withdrawal": "Rút tiền: 50.000.000",
        "quantity": "OrderQuantity: 40/ngày",
        "rate": "Ủy ban: 0,40%",
        "fee": "Rút tiền: 2,00%",
        "Activated": "Dailyrechargeamount: 1.500.000",
        "Invite": "Invitenow"
      },
      "Four": {
        "Silver": "Diamondmember",
        "Need": "NeedToinvite30people",
        "People": "30PeapleStillShort",
        "Withdrawal": "Rút tiền: 50.000.000",
        "quantity": "OrderQuantity: 45/ngày",
        "rate": "Ủy ban: 0,45%",
        "fee": "Rút tiền: 2,00%",
        "Activated": "Dailyrechargeamount: 2.000.000",
        "Invite": "Invitenow"
      }
    },
    "PhoneNew": "Pleaseconfirmthatyouhavefinished",
    "Toast": {
      "One": "Lưu lại thành công",
      "Two": "NotSucceed"
    },
    "Button": {
      "One": "Hủy bỏ",
      "Two": "Xác nhận"
    }
  },
  "Record": {},
  "Grab": {},
  "Financing": {
    "time": "Thời gian",
    "day": "Ngày",
    "Fina": "Tài chính",
    "assets": "Tổng tài sản",
    "amount": "Tài chính",
    "revenue": "Tổng số tiền rút",
    "earnings": "Ngày hôm qua",
    "Balance": "Chuyển khoản Số dư",
    "RP": "",
    "Please": "PLELINPUTTHETRANSFERINAMOUNTSILAKANMASMAKKANTRANSFERDALAMJUMLAH",
    "Estimated": "Ước tínhincome",
    "Income": "Incomestandard",
    "TransferOut": "Rút tiền",
    "ransferIn": "Chuyển giao",
    "SukuBung": "Sukubunga",
    "Transfer": "Transferinfailed",
    "Pleaseint": "PleaseNentertheamount",
    "Successful": "Thành công",
    "RansferInSU": {
      "FinancialAcc": "FinancialAccessRecord",
      "Acc": "AccessLog"
    },
    "ServiceSu": {
      "Please": "Làm ơn ...",
      "send": "gửi đi",
      "Online": "Dịch vụ trực tuyến",
      "Service": "Dịch vụ",
      "Customer": "Pelayananpelanggan",
      "Ifyou": "Ifyouencerseproblemsandneedhelp, pleasecontactonLineCunStomerservic",
      "Layanana8": "Khách hàng bảo vệ",
      "Layanana9": "Khách hàngerviceCenterno.9 (WhatsApp)",
      "Dedicated": "Chuyên dụngCunStomerservice",
      "Telegram8": "Layananapelangganno.8 (Telegram)",
      "Telegram008": "Telegram008",
      "Telegram9": "Layananapelangganno.9 (Telegram)",
      "Telegram009": "Telegram009"
    }
  },
  "Home": {
    "HallofFame": "Danh sách trúng thưởng",
    "Member": "Thành viên",
    "Mission": "Missionhall",
    "Learnde": "LearnDe",
    "Hello": "Xin chào",
    "Let": "LetVictoryMallopenthewayTowealth",
    "total": "Mytotalassets",
    "Withdraw": "Rút tiền",
    "Recharge": "Nạp tiền",
    "RP": "",
    "earningsRp": "Ngày hôm qua",
    "incomeRp": "Tích lũy",
    "rarningsRp": "Hôm nay là",
    "Service": "Dịch vụ",
    "Invite": "Mời",
    "Lazada": "Lazada",
    "Bukalapak": "Bukalapak",
    "Shopee": "Cửa hàng",
    "Tokopedia": "Tokopedia",
    "silver": "Sonelforsilvermembers",
    "gold": "ChannelForGoldMembers",
    "platinum": "sonelforplatinummembers",
    "diamond": "ChannelFordIAmondMembers",
    "Agent": "AgentorderCommission",
    "Order": "Thứ tự",
    "RechargeSu": {
      "Recharge": "Nạp tiền",
      "Catatan": "Catatankhusus:",
      "Next": "Tiếp theo",
      "RechargeAm": "Số tiền cần nạp",
      "Enter": "Entertherechargeamount",
      "Quick": "Sự lựa chọnnhanh",
      "nameP": "PLELENTERYOURREALNAME",
      "name": "Tên",
      "Pleaseamount": "PleaseNentertheamount",
      "Pleaseint": "PleaseNentertheamount",
      "Pleasename": "Xin hãy nhập tên của bạn"
    },
    "WithdrawSu": {
      "Withdraw": "Rút tiền",
      "Withdrawal": "Số tiền rút",
      "WithdrawalRe": "Rút tiền",
      "Input": "InputWithDrawAlamount",
      "Balance": "Số dư",
      "WithdrawAll": "Rút tiền",
      "Mobile": "MobilePhonenumbe",
      "Bank": "BankcardNumber",
      "Owned": "Sở hữuBank",
      "Account": "Tên tài khoản",
      "Transaction": "Mật khẩu giao dịch",
      "Withdrawim": "Rút tiền",
      "Pleaseamount": "PleaseNentertheamount",
      "Pleasephone": "Xin vui lòng điền số điện thoại của bạn",
      "Pleasecard": "PLELENTERYOURBANKCARDNUMBER",
      "Pleasebank": "PLELENTERYOURBANKNAME",
      "Pleasename": "Xin hãy nhập tên của bạn",
      "Pleasepassword": "PLELENTERTRATRTRATECTIONPASSWORD",
      "Pleaseint": "PleaseNentertheamount",
      "PasswordError": "Sai mật khẩu",
      "Successful": "Đã áp dụng forpitwithDrawal",
      "Insufficient": "Không đủWithDrawAlalBalance",
      "Unbound": "TheBankcardhasnotbeenbound",
      "Binding": "BoundyourBankcard",
      "idCard": "Số chứng minh nhân dân"
    }
  },
  "Login": {
    "indexTitle": "Đăng nhập",
    "remenber": "Ghi nhớThepassword",
    "versions": "V.86",
    "masuk": "Đăng nhập",
    "name": "Tải xuống ứng dụng",
    "forgot": "Quên",
    "registrasi": "Đăng ký",
    "loginButton": "Đăng nhập",
    "nameInput": "Nhập số điện thoại của bạn",
    "pwdInput": "Nhập mật khẩu của bạn",
    "notLoginHint": "Đăng nhập",
    "goLoginText": "xác nhận",
    "Forgot": {
      "title": "Truy xuất ba lần",
      "registerTitle": "Đăng ký",
      "phone": "Nhập số điện thoại của bạn",
      "code": "Nhập mã xác nhận",
      "area": "PleaseNenterArecoDE",
      "sendCode": "gửi mã",
      "password": "Mật khẩu",
      "againPassword": "Xác nhận mật khẩu",
      "confirm": "Xác nhận",
      "goBack": "Bạn đã có tài khoản ? Đăng nhập ngay",
      "register": "Daftar"
    },
    "Daftar": {
      "username": "Tên tài khoản",
      "title": "Đăng ký",
      "area": "PleaseNenterArecoDE",
      "phone": "Số điện thoại",
      "code": "Nhập mã xác nhận",
      "userName": "tên nick",
      "password": "Mật khẩu",
      "againPassword": "Xác nhận mật khẩu",
      "payPassword": "Paypasspassword",
      "referrer": "Mã lời mời (ID)"
    }
  },
  "Cart": {
    "title": "Chi tiết nhiệm vụ",
    "explain": "Sự mô tả",
    "grade": {
      "SliverMember": "Bạc",
      "GoldMember": "Thành viên",
      "PlatinumMember": "Vàng",
      "DiamondMember": "Thành viên",
      "undefined": "Thành viên"
    },
    "info": {
      "allMoney": "Tổng số của tôi",
      "dayAllOrder": "Một bộ",
      "dayTrustMoney": "Thu nhập hôm nay",
      "yesterdayMoney": "TotalOrder",
      "undefined": "thu nhập = earnings"
    },
    "text": {
      "rebateAndPrincipal": "Trở về",
      "residue": "thủ đô",
      "one": "Lịch sử",
      "rebate": "Lợi nhuận",
      "buyBtn": "Còn lại",
      "toGet": "Lịch sử",
      "orderSuccess": "Trả lại",
      "orderGetMoney": "Mua ngay",
      "orderWaiting": "Akanmendapat",
      "orderOver": "Chi tiết nhiệm vụ",
      "undefined": "Xong"
    },
    "state": {
      "all": "Tất cả các",
      "going": "Nữ sinh",
      "paid": "Trả",
      "frozen": "Thông tin",
      "over": "Xong"
    },
    "zzInfo": {
      "buyIng": "Thu mua",
      "buySucceedFindDT": "ClickthestatusviewAfterThePurchase",
      "ok": "là thành công",
      "buyFailed": "Được chứ",
      "notLevel": "Giao dịch thất bại",
      "buyJurisdiction1": "YourVipLevelisInsuff đủ",
      "buyJurisdiction2": "Ifwantpurchaseagoldmembershiporde",
      "buyJurisdiction3": "IFWANTPURCHASEALLATINumMMBERSHIPHIPorder",
      "meet": "IFWANTPurchaseadAmondMembershipReater",
      "invite1": "OneOfThefollowingConditionsmustblefulfills",
      "invite4": "Mời3 FriendStomAKemoneyTogether",
      "invite3": "Mời10 FriendStomAKemoneyTogether",
      "buySendMoney": "Mời30 FriendstomAKemoneytogether",
      "invite2": "2.Spendrs2000ToBUyMembershipdiamond",
      "buy": "Mời bạn bè",
      "myMoney": "Mua ngay",
      "under": "YourAccountBalance",
      "notSendMoney": "Phía dưới",
      "goTopUp": "CantDotransaction",
      "know": "PLELGOTOP-UP",
      "account": "Settlement",
      "undefined": "Tôi biết"
    },
    "hint": {
      "p1": "1",
      "p2": "2.thehigheryourMembershipLevel, TheHigherTheOrderCommissionamountthatcanbepurchasing",
      "p3": "3.forPurchasingReSter, YouCanMonitorTheRtatusanyTime"
    }
  },
  "Hint": {
    "SendCodeSuccess": "TheverificationCodeissentsuccess một cách một cách khó khăn!",
    "closed": "Đóng",
    "notTime": "Các ghi chú được chọn",
    "notTimeFailed": "Thời gian chọn",
    "success": "Thành công",
    "failed": "Thất bại",
    "phoneNot": "NoHP/Noakuntidakbisakosong",
    "pwdInconformity": "Mật khẩu không có",
    "notMoney": "MoneyInsufficent",
    "notEvent": "Featurecantuseyet",
    "notInput": "Không hoàn chỉnh",
    "loading": "Đang tải",
    "notMore": "Nomore",
    "notPhone": "Tài khoản không phải là chủ nghĩa",
    "yesPhone": "Tài khoản",
    "levelNot": "Cấp độ",
    "serverMaintenance": "Xin vui lòng, Thiếu tá",
    "invite": "Mời bạn bè",
    "goodsNumNo": "kiếm tiền",
    "haveBuy": "cùng với nhau",
    "depositBtnText1": "Không đủ điều kiện",
    "depositBtnText2": "Đã mua",
    "depositBtnText3": "Diterima",
    "commissionMoney": "Có sẵn",
    "bill": "Nhận",
    "billRecordTitle": "Trả lại thông thường:",
    "balance": "hóa đơn",
    "reference": "Hóa đơn",
    "updateText": "Số dư",
    "undefined": "PleaseUpdateTothelatestversion"
  },
  "newAdd": {
    "all": "Tất cả",
    "Completed": "Hoàn thành",
    "unfinished": "Chưa xong",
    "GetCodeBtn": "GetverificationCode",
    "copySuccess": "Copysuccess!",
    "copyError": "CopyTheFailure!",
    "noLoginUserName": "Đăng nhập",
    "Withdraw": "Rút tiền",
    "Recharge": "Nạp tiền",
    "commission": "Lợi nhuận",
    "single": "Độc thân",
    "missionHall": "Missionhall",
    "videoTutorial": "Video hướng dẫn",
    "helpCenter": "Trung tâm trợ giúp",
    "inviteFriends": "Mời bạn bè",
    "play": "Bắt đầu chơi",
    "help1Title": "HowToearnCommissionSwithMudo",
    "help1Context": "Thương mại điện tử",
    "help2Title": "Howtoincreasethenumberofmission",
    "help2Context": "Becausetherearetoomanypart-thời gian làm việc, thecompanystipulatesthatallonlinepart-timeMployeesusetheonlyWhatsApptogetInToughandReceIvemission.",
    "help3Title": "HowToCompletEthemission",
    "help3Context": "itistohelpmajormerchantstoincreasesales, andtorewardyouactiontothenumberoftransactions.",
    "referralLink": "Giới thiệu.",
    "copy": "sao chép",
    "blance": "Số dư",
    "todayOrder": "Hôm nay là thứ tự",
    "provided": "Thedataisprovidedby",
    "Transaction": "Giao dịch",
    "Frozen": "Thông tin",
    "Done": "Xong",
    "Cancel": "Hủy bỏ",
    "ExpectedReturn": "Reteturn dự kiến:",
    "ImmediatePayment": "Ngay lập tức",
    "AvailableNum": "Therearestill",
    "OrderImmediately": "Tìm nhiệm vụ",
    "MakeMoneyEveryDay": "Makemoneyeveryday",
    "TodayRecord": "Hôm nay là rrecord",
    "AllocatCommissions": "Phân bổOfCommissions",
    "FrozenAmount": "Frozenamount",
    "TaskMatch": "TaskorderRandomness",
    "AvailableBalance": "Số dư",
    "WaitDispatch": "Chờ đợifordispatch, Pleasecontactthestoremanager!",
    "OrderDescription": "Chi tiết nhiệm vụ",
    "CouldMatch": "Nhiệm vụDomerAndomlyMatchedBythesystem",
    "OrderPerDay": "Độc thân.",
    "DescriptionRate": "OrderPlocationCommissionisUniformly",
    "DescriptionPeriod": "OftheOrderAmount.",
    "Description3": "ThesystemisbasingonlbstechnologyAndautomallyMatchesproductsthroughthecloud.",
    "Description4": "InorderTopreventthesupervisionoftheplatform, iftheorderisnotconfirmedandsubmitedwithin20minutes, thesystemwillfreezetheorderamount.",
    "Note": "Ghi chú:",
    "DescriptionNote": "Whentheplatformmatchestheordertotheuser,theplatformsubmitstheinformationoftheordertothemerchantbackend.Iftheuserdoesnotsubmittheorderwithin20minutes,theamountoftheorderwillbefrozenbythesystem.Inordertocircumventthesupervisionsystem,useraccountswillbescored,whichwilldirectlyaffectthetimeandreputationoftheuser'snextordermatching!Ifthereareotherthingsthatneedtobedealtwith,pleaseclicktostoptheorderintimetoavoidfreezingduetotimeout",
    "OrderNum": "OrderNumber:",
    "TotalOrderPrice": "TotalOrderPrice",
    "SubmitNow": "Xác nhận ngay bây giờ",
    "Record": "Lịch sử",
    "MemberAccount": "Thành viên",
    "AccountPlaceholder": "PLELENTERYOURMEMMENCOUNT",
    "RechargeAmount": "Số tiền cần nạp",
    "RechargeAmountPlaceholder": "Nhập số tiền",
    "SelectRechargeMethod": "Chọn phương thức để thanh toán",
    "NotBeenSentOrder": "TheorderHasNotbeensent, PleasecontactthestafftosendtheOrder!",
    "TodayOrderOver": "ContactonLineCustomerservice",
    "NotLogin": "Youarenotloggedin, Pleaseloginandtryagain!",
    "HaveNoteMethod": "ATHERiSnosuchRechArgemethod, Pleasechooseanothothod ~",
    "Login": "Đăng nhập",
    "AccountRecord": "Thu nhập và chi tiêu",
    "RealName": "Tên tài khoản",
    "IFSCCODE": "IFSCCODE",
    "BankName": "Tên ngân hàng",
    "ChainType": "Chaintype",
    "BankNum": "Số tài khoản ngân hàng",
    "Mobile": "Di động",
    "MobileNum": "Số điện thoại",
    "Email": "E-mail",
    "EmailRequired": "Email thì cần thiết",
    "AddBankCardNote": "Thông tin rút tiền phải được điền chính xác",
    "AddWalletNote": "Lưu ý: WalletAddressisimportantInformation, PleaseFillitOutcare!",
    "Submit": "Xác nhận",
    "AddBankCardTitle": "Thêm ngân hàng",
    "AddWalletTitle": "AddWalletAddress",
    "BankCard": "Thẻ ngân hàng",
    "WalletInfo": "Walletinfo",
    "Name": "Tên",
    "CardNum": "Cardnumber (axit)",
    "WalletAddress": "Địa chỉ nhà",
    "Reading": "isbeingreading ...",
    "AllBalance": "Số dư",
    "EnterWithdrawalAmount": "Vui lòng nhập số tiền bạn muốn rút",
    "AllOut": "Tất cả đi ra",
    "AskNow": "Xác nhận",
    "EnterPwdPlaceholder": "Vui lòng nhập mật khẩu",
    "NotHaveCard": "Bạn vẫn chưa đăng ký tài khoản ngân hàng.bạn có muốn đăng ký không?",
    "NotHaveWallet": "Bạn chưa thiết lập địa chỉ ví, bạn có muốn vào phần cài đặt không?",
    "GetBankInfoFail": "Không truy xuất được thông tin ngân hàng",
    "EnterValidPayPwd": "Vui lòng nhập mật khẩu thanh toán hợp lệ!",
    "Balance": "Số dư",
    "Total": "Tổng cộng",
    "total": "toàn bộ",
    "records": "Hồ sơ",
    "DisplayPerPage": "mỗi người",
    "NullNum": "",
    "pages": "trang.",
    "toAudit": "kiểm toán",
    "succeed": "thành công",
    "RechargeRecordTitle": "Lịch sử",
    "rests": "nghỉ ngơi",
    "Arrive": "Đến",
    "Income": "Thu nhập",
    "Expenditure": "Chi phí",
    "forzenCommission": "không có người nhận",
    "companyIntro": "Công ty",
    "companyTitle": "Tata Mall trực thuộc Tập đoàn Tata",
    "warmTitle": "Chú ý",
    "companyText": "Tập đoàn Tata là tập đoàn kinh doanh lớn nhất Ấn Độ, được thành lập năm 1868 và có trụ sở chính tại Mumbai, Ấn Độ. Hoạt động kinh doanh liên quan đến bảy lĩnh vực kinh doanh: truyền thông và công nghệ thông tin, kỹ thuật, vật liệu, dịch vụ, năng lượng, sản phẩm tiêu dùng và sản phẩm hóa chất. Là một tập đoàn kinh doanh đang phát triển nhanh chóng ở Ấn Độ, Tata Group có hơn 200 công ty con, hoạt động tại hơn 80 quốc gia trên 6 lục địa và xuất khẩu sản phẩm và dịch vụ sang 85 quốc gia. Tổng doanh thu của Tập đoàn Tata trong năm tài chính 2020-2021 là 100,09 tỷ USD, trong đó 58% đến từ các hoạt động ở nước ngoài. Số lượng nhân viên của Tập đoàn trên khắp thế giới xấp xỉ 450.000 người. Từ 153 năm nay, cái tên Tata ở Ấn Độ đã được mọi người kính trọng vì sự tuân thủ các giá trị tốt đẹp và đạo đức kinh doanh. Được Forbes xếp hạng là công ty có uy tín thứ 11 trên thế giới.",
    "depositAddress": "Địa chỉ gửi tiền",
    "rechargeSteps": "Các bước nạp tiền",
    "completePayment": "Hoàn thành thanh toán",
    "rechargeNow": "Nạp tiền ngay bây giờ",
    "rechargeCancel": "Hủy thanh toán",
    "bankName": "Tên ngân hàng",
    "payee": "Người nhận tiền",
    "cardNum": "Số thẻ ngân hàng",
    "reviewing": "Đang xem xét",
    "remittance": "Thành công",
    "notThrough": "Thất bại",
    "OrderID": "ID đặt hàng",
    "Amount": "số lượng",
    "toCutScreen": "Vui lòng chụp ảnh màn hình nếu hình ảnh không có sẵn!",
    "cardTabBank": "Ngân hàng",
    "cardTabUSDT": "USDT",
    "name": "Tên",
    "chainType": "Loại dây chuyền",
    // 这下面是新增 2022-11-30 的
    newNavTitle: 'Gửi đơn hàng',
    shopMoney: 'Số lượng hàng hóa', // 商品金额
    actualMoney: 'Gửi thực tế', // 实际提交金额（客户手动填写）
    commitBtnText: 'Tạo đơn đặt hàng',
    commitHint: 'Đầu vào không đầy đủ' // 输入不完整    
  },
  "mine": {
    "servier": "Dịch vụ",
    'integral': 'tích phân', // 积分展示
    'balance': 'Đổi trả', // 兑换
    'record': 'Lịch sử đổi', // 兑换记录
    avaBlc: 'Số lượng đổi', // 兑换数量
    allWtd: 'Đổi tất cả', // 全部兑换
    close: 'Hủy',
    commit: 'Xác nhận',
    wtdHint: 'Vui lòng nhập đúng số tiền rút' // 请输入正确的提现金额
  },
  "add": {
    "Commission": "Nhiệm vụ",
    "Yesterday": "Hôm qua",
    "Cumulative": "Tích lũy",
    "Today": "Thu nhập hôm nay",
    "TeamNum": "Tổng quy mô nhóm",
    "TeamMoney": "Tổng lợi nhuận của nhóm",
    "Personalcenteraccountchangerecord": "Tài khoản",
    "lockedbalance": "khóa chặt",
    "Cumulativeinvitation": "Tích lũy",
    "Validinvitation": "Lời mời hợp lệ",
    "Accumulatedrebate": "Tích lũy"
  },
  "home.btn.wallet": "Tài chính",
  // "home.btn.recharge": "Nạp tiền",
  "home.btn.recharge": "Gửi",
  "home.btn.withdraw": "Rút tiền",
  "home.btn.invite": "Mời",
  "page.grab": "Nhiệm vụ",
  "orderCommission": "Nhiệm vụ lợi nhuận",
  "commission": "Lợi nhuận",
  "todayIncome": "Thu nhập hôm nay",
  "personalEarnings": "Thu nhập cá nhân",
  "completedOrders": "Hoàn thành Nhiệm vụ",
  "lockedOrders": "Số tiền bị khóa",
  "unfinishOrder": "Không hoàn thành",
  "frozonAmount": "Số tiền chưa hoàn thành",
  "currentBalance": "Số tiền hiện tại",
  "page.mine": "Chi tiết tài khoản",
  "mine.invitationCode": "Mã mời",
  "mine.serverCenter": "Máy chủ",
  "mine.tools.total": "Tổng cộng",
  "mine.tools.financial": "Tài chính",
  "mine.tools.details": "Thông tin chi tiết",
  "mine.tools.freeze": "Thông tin",
  "mine.serviceTools.personal": "Riêng tư",
  "mine.serviceTools.teamReport": "Báo cáo nhóm",
  "mine.serviceTools.invite": "Tài khoản gia đình",
  "mine.serviceTools.message": "Thông điệp",
  "mine.serviceTools.aboutUs": "Về chúng tôi",
  "mine.serviceTools.security": "Bảo vệ",
  "mine.serviceTools.address": "Địa chỉ nhà",
  "mine.serviceTools.inviteTask": "Mời Nhiệm vụ",
  "page.team": "Đội",
  "page.aboutus": "Về chúng tôi",
  "page.financial": "Tài chính",
  "page.financialRecord": "Hồ sơ tài chính",
  "hint.InternetErr": "Lỗi mạng, vui lòng thử lại sau!",
  "totalDeposit": "Tổng số tiền nạp",
  "totalRevenue": "Tổng số tiền rút",
  "btn.transferInto": "Nạp tiền",
  "btn.transferOut": "Rút tiền",
  "page.transferInto": "Nạp tiền",
  "page.transferOut": "Rút tiền",
  "balanceTransfer": "Chuyển khoản Số dư",
  "availableBalance": "Số dư",
  "depositRate": "Lãi suất mỗi tháng",
  "days": "ngày",
  "hint.success": "Thành công",
  "hint.fail": "Thất bại",
  "status.into": "Nạp tiền",
  "status.out": "Rút tiền",
  "label.amount": "Số lượng",
  "label.time": "Thời gian",
  "Button": {
    "cancle": "Hủy bỏ",
    "confirm": "Xác nhận"
  },
  "dialog.title.inputPaypass": "Nhập mật khẩu thanh toán",
  "dialog.nopaypass": "Mật khẩu thanh toán chưa được đặt, bấm OK để chuyển sang cài đặt!",
  "mine.serviceTools.lan": "Ngôn ngữ",
  "checkLans": "Chuyển đổi ngôn ngữ",
  "mine.tools.commission": "Nhiệm vụ",
  "page.commissionRecord": "Nhiệm vụ",
  "noticeList.all": "Tất cả các",
  "noticeList.unread": "Chưa đọc",
  "successWithdrawof": "Rút tiền thành công",
  "home.title.notice": "Để ý",
  "home.title.patener": "Bạn đồng hành",
  "login.rememberPassword": "Nhớ mật khẩu",
  page: {
    "cart": "Xe đẩy",
    "order": "Lịch sử",
    "financing": "Tài trợ",
    "goods": "Các mặt hàng",
    "levelUpgrade": "Nâng cấp",
    "PlatformIntroduction": "Giới thiệu nền tảng",
    "RuleDescription": "Mô tả quy tắc",
    "AgentCooperation": "Hợp tác đại lý",
    "CommonProblem": "Vấn đề thường gặp",
    "MemberUpgrade": "Nâng cấp thành viên",
    "login": "Đăng nhập",
    "forgot": "Quên",
    "registrasi": "Đăng ký",
    "record": "Lịch sử",
    "Recharge": "Nạp tiền",
    "inviteFriends": "Mời bạn bè",
    "Withdraw": "Rút tiền",
    "Service": "Dịch vụ",
    "DialoguePage": "Trang đối thoại",
    "user": "Riêng tư",
    "RansferIn": "Chuyển giao",
    "PersonalCenter": "Trung tâm cá nhân",
    "Portrait": "Chân dung",
    "Name": "Tên",
    "Account": "Tài khoản",
    "Password": "Mật khẩu",
    "PaymentCode": "Mã thanh toán",
    "Site": "Địa điểm",
    "AccountData": "Dữ liệu tài khoản",
    "RechargeRecord": "Bản ghi nạp tiền",
    "WithdrawalRecord": "Hồ sơ rút tiền",
    "InviteFriends": "Mời bạn bè",
    "Announcement": "Sự thông báo",
    "TheTeamReport": "Báo cáo của nhóm",
    "BillRecord": "Bản ghi hóa đơn",
    "BankCardAdd": "Thêm thẻ ngân hàng",
    "NewRecharge": "Nạp tiền",
    "RechargeList": "Danh sách nạp tiền",
    "newWithdraw": "Rút tiền",
    "withdrawList": "danh sách rút tiền",
    "SettingPayPwd": "Đặt Paypass",
    "MakeMoney": "Làm ra tiền",
    "team": "đội",
    "qrPay": "qrcode-pay",
    "bankPay": "thanh toán qua ngân hàng",
    "aboutus": "Về chúng tôi",
    "financial": "Tài chính",
    "financialRecord": "Hồ sơ tài chính",
    "TransferInto": "Chuyển vào",
    "TransferOut": "Rút tiền",
    "CommissionRecord": "Nhiệm vụ",
    "Security": "Bảo vệ",
    "Address": "Địa chỉ nhà",
    "Message": "Thông điệp",
    "InviteTask": "Mời Nhiệm vụ",
    "Notice": "Để ý",
    "modifyPaymentPassword": "Sửa đổi mật khẩu thanh toán"
  },
  "copyLink": "Sao chép đường link",
  "teamReport": {
    "title": "Báo cáo nhóm",
    "subTitle": "Tổng quan về nhóm cấp dưới",
    "taskTitle": "Mời {num} người đăng ký và hoàn thành đơn đặt hàng",
    "bonus": "Thưởng",
    "Unfinished": "Chưa xong",
    "Getted": "Được"
  },
  "copy": "Sao chép",
  "address": {
    "name": "Tên",
    "inputPlaceholder": "Vui lòng điền vào",
    "phone": "Điện thoại",
    "areaPlaceholer": "Giao hàng",
    "submit": "Tiết kiệm"
  },
  "password.old": "Mật khẩu cũ",
  "password.old.placeholder": "Vui lòng nhập mật khẩu mới của bạn",
  "password.confirmPassword": "Xác nhận mật khẩu",
  "password.confirmPassword.placeholder": "Vui lòng xác nhận lại mật khẩu",
  "hint.passwordDiff": "Hai mật khẩu không khớp",
  "loginType": {
    "phone": "Điện thoại",
    "email": "E-mail"
  },
  "Login.Daftar.email": "E-mail",
  "Login.emailInput": "Nhập email của bạn",
  "My.PersonalInformation.Password.email": "e-mail",
  "My.PersonalInformation.Password.NewEmail": "Vui lòng nhập email di động của bạn",
  "mine.serviceTools.certify": "Xác minh",
  "Certification": {
    "type": {
      "id": "Số ID",
      "driver": "Bằng lái xe",
      "passport": "Hộ chiếu"
    },
    "name": "Tên",
    "license": "Số giấy phép",
    "upload": "Hình thẻ",
    "submit": "Xác nhận",
    "incompleteInformation": "Vui lòng xem lại thông tin!!",
    "submitSuccess": "Thông tin chứng nhận đã được gửi, vui lòng chờ xem xét!",
    "photo": "Ảnh id",
    "review": "Đang được xem xét",
    "verified": "đã xác minh"
  },
  "upload": {
    "uploading": "Tải lên ...",
    "failed": "tải lên không thành công"
  },
  "login.forgetPassword": "Quên mật khẩu của bạn, xin vui lòng liên hệ với dịch vụ khách hàng!",
  "newAdd.BankNum.placeholder": "Ví dụ: 09xxxxxxxxxx",
  "page.certification": "Nhận dạng",
  "newAdd.page": "trang",
  "newAdd.record": "ghi lại",
  "emailFormatError": "Xin vui lòng",
  "urlParsePlaceholder": "Vui lòng nhập đường link vào",
  "btn.Identify": "Xác nhận nhiệm vụ",
  "produceStatus.confirming": "Tobeconf xác nhận",
  "produceStatus.paying": "Trả tiền",
  "produceStatus.paid": "Trả",
  "produceStatus.done": "Xong",
  "produceStatus.cancle": "Hoàn lại tiền",
  "order.old": "Lệnh thu nhập cao",
  "order.new": "Thường xuyên",
  "welfareDialogTitle": "Lợi ích",
  "home.btn.message": "Thông tin",
  "viGrabDesces": {
    "1": "Mỗi đơn hàng có thể được chọn là đơn hàng may mắn và hoa hồng sẽ được tăng lên {rate}%. Vui lòng liên hệ với người hướng dẫn khuyến mãi của bạn để đăng ký các nhiệm vụ khuyến mãi tiếp theo",
    2: "Giúp người bán trên Shopee hoàn thành việc tạo đơn hàng ảo, tăng khả năng hiển thị và nhận hoa hồng",
    3: "Vui lòng liên hệ với gia sư khuyến mãi của bạn ngay sau khi bạn nhận được đơn hàng, gửi dữ liệu đơn hàng và hoàn thành nhiệm vụ",
    4: "Số lượng nhiệm vụ có hạn, các bạn chú ý để được gia sư tư vấn khuyến mãi khi nhận đơn hàng",
    5: "Số tiền bạn sẽ nhận lại được cấp trong vòng từ 5-10 phút sau khi hoàn thành nhiệm vụ ,nếu có thắc mắc vui lòng tham khảo ý kiến chuyên gia hoặc chăm sóc khách hàng trực tuyến.",
    6: "Hoàn thành các nhiệm vụ Shopee chỉ định để tăng cấp độ VIP và nhận hoa hồng nhiệm vụ Fackbook cao hơn",
    7: "Tích lũy hoàn thành các nhiệm vụ Shopee chỉ định có thể nhận thêm hoa hồng. Để biết phần thưởng cụ thể, hãy tham khảo ý kiến   của chuyên gia khuyến mãi hoặc dịch vụ khách hàng trực tuyến"
  },
  "expectedEarningsTitle": "Nguồn thu nhập được dự kiến",
  rechargeDesc: {
    nav: "Mô tả nạp tiền",
    p1: "bây giờ chúng tôi có rất nhiều nhân viên hoàn thành nhiệm vụ cùng một lúc.",
    p2: "Một người bán không thể cung cấp đủ tài khoản",
    p3: "vì vậy chúng tôi đăng tài khoản người bán của mình vào chỗ trống khác",
    p4: "khi bạn cần hoàn thành nhiệm vụ, bạn có thể liên hệ với bộ phận hỗ trợ bán hàng để đăng ký kênh thanh toán cho bạn"
  },
  chat: {
    list: {
      nav: "Thông điệp",
      hours: "{h} giờ",
      minutes: "{m} phút",
      ago: "{time} trước",
      image: "hình ảnh"
    },
    room: {
      msgPlaceholder: "Nhập tin nhắn...",
      send: "Gửi",
      sender: "Người gửi",
      sendTime: "Gửi thời gian",
      content: 'Nội dung'
    }
  },
  newBank: {
    usdt: {
      name: "Tên",
      currency: "Tiền tệ",
      chainType: "Loại dây chuyền",
      depositAddress: "Địa chỉ gửi tiền",
      submit: "nộp",
    },
    "walletList": {
      pageTitle: 'Quản lý ví',
      "name": "Bí danh ví:",
      "coinType": "Tiền tệ: ",
      "chainType": "Loại dây chuyền:",
      "walletAddress": "Địa chỉ:",
      "addDate": "ngày:",
      "btnText": "Thêm địa chỉ ví",
      "deleteConfirmText": "Bạn có chắc chắn muốn xóa địa chỉ ví không?",
      "btnConfirm": "Xóa",
      "btnCancle": "Hủy",
      "successHint": "thành công"
    },
    withdrawNum: "Số lần rút tiền",
    AllOut: "Tất cả",
    balance: "Cân bằng tổng thể",
    handlingFee: "Phí xử lý",
    actualAmount: "Số tiền thực tế"
  }
}
export default {
  signStr: '1001', // 渠道
  RP: '₹',
  areaCode: '+91',
  // tab按钮
  tab: {
    my: 'Mi',
    home: 'Casa',
    record: 'Registro',
    grab: 'Agarrar',
    financing: 'Financiación',
    make_money: 'toma un pedido'
  },
  newTab: {
    home: 'Casa',
    money: 'Dinero',
    shop: 'Tienda',
    order: 'Pedido',
    my: 'Mi'
  },
  // 公共的key
  common: {
    unrealized: 'Haciendo',
    all: 'Todos',
    distributionTip: '0 gastos de envío 0 gastos de distribución',
    easeBack: 'Relajación',
    addCarSuccess: 'Únase a las compras con éxito',
    deleteConfirm: '¿Está seguro de que desea eliminar el elemento seleccionado?',
  },
  My: {
    download: 'Descargar aplicación',
    personal: 'informacion personal', // 个人信息
    preOrde: 'registro de pre-pedido', // 预订单记录
    account: 'detalles de la cuenta', // 帐户详细信息
    recharge: 'Registro de recarga', // 充值记录
    // withdrawal: 'registros de retiro', // 提款记录
    withdrawal: 'Withdrawal', // （改成提现了）
    invite: 'Retiro', // 邀请朋友
    announcement: 'Anuncio', // 公告
    service: 'Servicio',
    team: 'Informe del equipo', // 团队
    quit: 'Dejar', // 退出
    logoutEvent: '¿Confirmar para salir?', // 确定退出
    language: 'Configuración de idioma', // 语言设置
    languageButton: {
      english: 'English', // 英语
      indonesian: 'Indonesian', // 印尼语
    },
    logout: 'cerrar sesión', // 登出
    // 个人中心的值-小饶
    MemberInformation: {
      SilverMember: 'miembro de plata', // 银牌会员（这个没用了）
      Invitation: 'Código de invitación', // 邀请代码
      balance: 'Saldo de la cuenta', // 账户余额
      Recharge: 'Recargar', // 充值
      Withdraw: 'Retirar',
      forzenAll: 'dinero congelado'
    },
    // 个人中心-小饶 -Personal information
    PersonalInformation: {
      // 个人中心选项的
      Page: {
        title: 'Mi informacion', // 我的信息
        Avatar: 'Avatar', // 头像
        Nickname: 'Apodo', // 昵称
        PhoneNumber: 'Número de teléfono', // 手机号
        BankAccounts: 'cuentas bancarias', // 银行账户
        Password: 'Clave', // 密码
        TransactionPassword: 'Contraseña de transacción', // 交易密码
        ReceivingAddress: 'Dirección de recepción', // 收货地址
      },
      // 个人中心-修改头像
      portrait: {
        SetAvatar: 'Establecer avatar', // 设置头像
        button: 'Establecer ahora', // 立即设置
      },
      // 个人中心-修改用户名
      Name: {
        ModifyNickname: 'Modificar apodo', // title
        title: 'Modifica tu apodo', // input上方的提示
        name: 'Apodo', // label
        Modify: 'Modificar', // 提交按钮
        Please: 'Por favor escribe un apodo', // input的placeholder
      },
      // 个人中心-银行卡信息
      BankAccounts: {
        BankAccounts: 'Tarjeta bancaria vinculante', // 添加银行卡页面title
        YourInformation: 'Tu información', // 姓名上方的title
        RealName: 'Nombre real', // 真实姓名
        RealNameNote: 'Por favor, establezca su nombre real', // 真实姓名的placeholder
        PhoneNo: 'Número de teléfono', // 手机号
        PhoneNoNote: 'Por favor, establezca el número de teléfono', // 手机号placeholder
        YourBankCard: 'La información de su tarjeta bancaria', // 银行卡信息的title
        BankAccount: 'Cuenta bancaria', // 银行卡号label
        BankAccountNote: 'Establezca una cuenta bancaria', // 银行卡号placeholder
        BankName: 'Nombre del banco', // 银行name
        BankNameData: {
          option1: 'Elegir banco de cuenta',
          option2: 'Banco de Asia Central',
          option3: 'Banco Negara Indonesia',
          option4: 'Banco Rakyat Indonesia',
          option5: 'Banco Mandiri',
          // option6: 'Bank Negara Indonesia', // TO DO 他这个是重复的
        }, // 银行选项
        BankBranch: 'Sucursal bancaria', // 银行支行label
        BankBranchNote: 'Por favor establezca sucursal bancaria', // 银行支行palaceholder
        MoreInformation: 'Más información', // whatsApp上方title
        WhatsApp: 'WhatsApp', // whatsApp label
        WhatsAppNote: 'Por favor, establezca el número de WhatsApp', // whatsApp palaceholder
        Text:
          'Recordatorio: Hola, para garantizar sus derechos, vincule el número de tarjeta y la información bancaria correctos. Si la cuenta no se puede recibir debido a un llenado incorrecto, ¡esta plataforma no se hace responsable!', // 添加银行卡的提示
        Settings: 'Ajustes', // 确定（提示）
      },
      // 个人中心-修改密码
      Password: {
        title: 'Modificar contraseña de inicio de sesión', // title
        PleaseEnter: 'Por favor ingrese su nueva contraseña', // 输入框上方的
        // LoginPassword:"Login password",
        // LoginPasswordNote:"Please enter the login password",
        phone: 'teléfono', // 手机
        NewPhone: 'Por favor ingrese su número de celular', // // 验证手机号的轻提示
        verificationCode: 'código de verificación', // 验证码
        verificationCodeNote: 'Por favor ingrese un código de verificación', // 请输入验证码
        ConfirmPassword: 'clave', // 密码
        ConfirmPasswordNote: 'Ingresa tu contraseña', // 输入密码
        Text:
          'Recuerde la contraseña, si olvida la contraseña, comuníquese con el servicio al cliente.', // 提示
        ButtonCode: 'obtener código de verificación', // 获取code
        Button: 'Aceptar para modificar', // 按钮
        verifyPhone: 'Por favor ingrese su número de celular', // 验证手机号的轻提示
        PhoneNew: 'Confirma que has terminado.', // 是否输入完毕的轻提示
      },
      // 个人中心-修改支付密码
      // 个人中心-修改用户地址
      Site: {
        title: 'Modificación de la dirección de recepción', // 接收地址修改
        ReceivingIdentity: 'Recibir información de identidad', // 接收身份信息
        ReceivingAddress: 'Recepción de información de la dirección', // 接收地址信息
        DetailedAddress: 'Dirección detallada', // 详细地址
        DetailedAddressNote: 'Por favor ingrese la dirección detallada', // 请输入详细地址
        Set: 'Establecer como dirección común', // 设为公用地址
        Button: 'Confirmar', // 完成
        hint: 'Confirma que has terminado.', // 请确认您已完成
      },
      // 个人中心-账户信息
      AccountData: {
        title: 'Detalles de la transacción', // 交易明细
        To: 'Para', // 到
        search: 'búsqueda', // 搜索
        Cancel: 'Cancelar', // 取消
        Semua: 'Todos los tipos', // 全部
        Penarikan: 'Notas de retiro', // 吸引力
        IsiUlang: 'Mutación de recarga', // 重新加载
      },
      // 个人中心-充值记录
      RechargeRecord: {
        title: 'Registro de recarga', // 充值记录
        Recharge: 'Recarga sin conexión', // 离线充值
        Online: 'recarga en línea', // 在线充值
        // Amount: 'Recharge amount Rp:', // 充值金额Rp
        Amount: 'Cantidad de recarga ₹:', // 充值金额Rs
        Order: 'Número de orden:', // 订单号
        Status: 'Estado de recarga:', // 充值状态
      },
      // 个人中心-提款记录
      WithdrawalRecord: {
        title: 'registro de retiro', // 提款记录
        withdrawal: 'retiro de tarjeta bancaria', // 银行卡取款
      },
      // 个人中心-邀请好友
      InviteFriends: {
        Invite: 'Enlace de invitación: haga clic en copiar', // 邀请链接：单击“复制”
        InvitationCode: 'Código de invitación:', // 邀请代码
        Click: 'Copiar link', // 复制链接
      },
      // 个人中心-系统公告
      Announcement: {
        title: 'Anuncio', // 公告
        System: 'Anuncio del sistema', // 系统公告
      },
      // 个人中心-团队报告
      TheTeamReport: {
        title: 'Informe del equipo', // 团队报告
        To: 'Para', // 到
        search: 'búsqueda', // 搜索
        Cancel: 'Cancelar', // 取消
        balance: 'cantidad del equipo', // 团队金额 0
        turnover: 'recarga de equipo', // 团队充值 1
        recharge: 'Comisión de orden de equipo', // 团队订单佣金 2
        withdrawal: 'retiro del equipo', // 团队提现 3
        commission: 'Comisión de orden de equipo', // 团队佣金 4
        charge: 'Número total de personas', // 总人数 5
        people: 'Número de usuarios con recarga', // 有充值用户数 6
        members: 'Número de nuevos usuarios', // 新用户数 7
        NewPeople: 'Recarga de nuevo usuario', // 新用户充值人数
        Active: 'gente activa', // 活跃人数
        Level1: 'Nivel 1', // 等级1
        Level2: 'Nivel 2', // 等级2
        Level3: 'Nivel 3', // 等级3
      },
    },
    // 首页跳转下的四个静态页面 - 小饶
    PlatformIntroduction: {
      PlatformIntroduction: 'Introducción a la plataforma',
      // One:
      //   'Indonesia Alliance Mall Co., Ltd. didirikan pada tahun 2012 ，adalah sistem layanan cloud e-niaga yang berfokus pada manajemen proses penjualan untuk membantu perusahaan dengan cepat meningkatkan peningkatan penjualan, rekomendasi petunjuk akurat dipotong, dan mengintegrasikan saluran pemasaran dengan manajemen penjualan dari keseluruhan proses.',
      // Two:
      //   'Platform ini merupakan aliansi yang terdiri dari jutaan pedagang dari Tokopedia, Shopee, Bukalapak, dan Lazada, Prinsip layanannya adalah: pedagang platform meningkatkan volume transaksi, dan anggota platform memperoleh pendapatan komisi dengan mengambil pesanan.',
      Two:
        'Esta plataforma es una alianza compuesta por millones de comerciantes. Su principio de servicio es: los comerciantes de la plataforma aumentan el volumen de negociación y los miembros de la plataforma obtienen ingresos por comisiones al recibir pedidos.',
      // Three:
      //   'Membantu perusahaan mendapatkan pelanggan yang lebih efektif melalui mesin rekomendasi petunjuk pelanggan' +
      //   '"Cloud guest"' +
      //   'adalah platform pesanan pencocokan host kontrol cloud cerdas pihak ketiga.Platform ini bekerja dengan Tokopedia, Shopee, Bukalapak, Lazada dan pedagang platform belanja online lainnya, melalui sistem cloud cerdas untuk mencocokkan pesanan bisnis dengan pengguna platform, sehingga dapat meningkatkan volume perdagangan dan reputasi penjual di platform utama. mode pengiriman pesanan pembelian ditingkatkan, dan pesanan dikirimkan secara otomatis oleh server cloud cerdas jarak jauh.Pengguna platform hanya perlu memberikan alamat penerima yang yang lengkap , nomor telepon dan beberapa informasi belanja secara langsung di platform ， dan masuk ke host cloud cerdas. komputer Robot Cerdas dari jarak jauh akan kontrol cloud secara otomatis menganalisa proses operasi pesanan secara manual, membuat pencocokan pesanan online lebih efisien dan sederhana！',
      Three:
        'La nube inteligente de terceros controla la plataforma de coincidencia de pedidos del host. La plataforma coopera con otros comerciantes de la plataforma de compras en línea para hacer coincidir los pedidos comerciales con los usuarios de la plataforma a través del sistema de nube inteligente, a fin de aumentar el volumen de transacciones y la reputación del vendedor en la plataforma principal. Se mejora el método de entrega de la orden de compra. El pedido es enviado automáticamente por el servidor de nube inteligente remoto. Los usuarios de la plataforma solo necesitan proporcionar la dirección completa del destinatario, el número de teléfono y cierta información de compra directamente en la plataforma para ingresar al host de nube inteligente.',
      // Four:
      //   'Setiap hari, platform akan menempatkan produk pelanggan yang perlu diekspos ke publik di platform ， dan menyediakan mereka ke platform untuk perampasan pesanan.Untuk menghindari deteksi platform belanja online dan meningkatkan keaslian pesanan , pengguna baru harus meningkatkan informasi pribadi mereka yang sebenarnya dan mengisi alamat penerima yang lengkap sebelum mengambil pesanan, sehingga penjual dapat meningkatkan kedapatan dan penjualan sementara tidak dianggap sebagai pesanan palsu oleh platform belanja online dan dengan demikian mempromosikan perkembangan bisnis yang sehat di platform utama. Untuk membantu penjual secara efektif meningkatkan tingkat keberhasilan pesanan dan menghindari risiko penjual diperiksa untuk pesanan palsu ， platform akan mengoptimalkan pencocokan pesanan untuk hari itu sesuai dengan IP operasi Anda saat ini dan jenis peralatan. Oleh karena itu, pencocokan pesanan perlu diwujudkan melalui algoritma komputasi awan yang cerdas. Mohon tunggu dengan sabar.Pengguna platform secara otomatis akan menjadi agen platform melalui promosi orang baru untuk bergabung dengan platform dan menjadi agen bisa mendapatkan komisi tambahan. ',
      Four:
        'Para ayudar a los vendedores a mejorar efectivamente la tasa de éxito de los pedidos y evitar el riesgo de que los vendedores verifiquen pedidos falsos, la plataforma optimizará la coincidencia de pedidos del día de acuerdo con su IP operativa actual y tipo de equipo. Por lo tanto, la coincidencia de pedidos debe realizarse mediante un algoritmo inteligente de computación en la nube. Por favor espere pacientemente. Los usuarios de la plataforma se convertirán automáticamente en agentes de la plataforma mediante la promoción de nuevas personas para que se unan a la plataforma, y pueden obtener una comisión adicional.',
      // Five:
      //   'Anda hanya perlu menggunakan waktu luang Anda untuk online, dan Anda dapat dengan mudah memperoleh pendapatan komisi yang besar setiap hari. Pokok dan komisi akan dikembalikan segera setelah pesanan berhasil diklik dan berhasil dikirim.',
      Five:
        'Solo necesita usar su tiempo libre para navegar por Internet, y puede ganar fácilmente una gran comisión todos los días. Después de hacer clic y enviar con éxito el pedido, se reembolsará el capital y la comisión.',
    },
    RuleDescription: {
      RuleDescription: 'Descripción de la regla', // title
      One:
        '1. Los miembros deben completar la dirección del destinatario y proporcionar información personal antes de realizar pedidos. Comisionados de diferentes plataformas según diferentes tareas. El monto del pedido generado por el sistema se basa en el 30%-70% del saldo de su cuenta.', //
      Two:
        '2. Dado que los pedidos de bienes tardan cierto tiempo en surtir efecto, los pedidos solo se pueden recibir con el vendedor después de que el pedido sea válido, por lo tanto, la liquidación de comisiones de plataformas y bancos tripartitos y miembros utiliza el sistema T + 1. A saber: hoy para retirar y el dinero se recibirá al día siguiente el día anterior a las 23:59. Si el pedido no se hace efectivo después del tiempo especificado, la plataforma pagará por adelantado, liquidará la cuenta y emitirá el pago dentro del tiempo especificado.', //
      Three:
        '3. Condiciones adecuadas de la sala y relación de comisión Miembros Silver: Todos los miembros Silver pueden recibir 10 pedidos por día, y la comisión por dichos pedidos es del 0,30 % del monto de la mercancía.', //
      Four:
        'Miembros dorados: para desbloquear miembros dorados, debe invitar hasta 3 personas a ingresar a esta sala. Puede tomar 35 pedidos por día, y la comisión por pedidos es del 0,35% del monto de la mercancía.', //
      Five:
        'Miembros Platino: Para desbloquear miembros Platino, debe invitar hasta 10 personas a ingresar a esta sala. Puede obtener 40 pedidos por día, y la comisión por esos pedidos es del 0.40% del monto de la mercancía.', //
      Six:
        'Anggota platino: Untuk membuka anggota platino, Anda perlu mengundang hingga 10 orang untuk memasuki aula ini.Anda bisa mendapatkan 40 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,40% dari jumlah merchandise.', //
      Seven:
        'Anggota berlian: Untuk membuka anggota Diamond, Anda perlu mengundang 30 orang untuk memasuki aula ini. Anda dapat memperoleh 45 pesanan per hari, dan komisi untuk pesanan tersebut adalah 0,45% dari jumlah barang dagangan.', //
      Eight:
        '4. Harap periksa nomor kartu plataforma de depósito sebelum mengisi dan plataforma akan mengubah nomor akun secara tidak teratur. Plataforma tidak akan bertanggung jawab atas kerugian propertyti yang disebabkan oleh kesalahan operasional pribadi.', //
      Nine:
        'Catatán: Silakan periksa informasi kartu bank dari plataforma apakah itu plataforma ini untuk setiap transfer untuk menghindari kerugian ekonomi Anda.', //
      Ten:
        '5. Untuk melindungi profit antara penjual dan plataforma, oleh karena itu setiap ip, nomor kartu bank dan nomor ponsel hanya dapat berlaku untuk satu akun. Plataforma Semua e-niaga utama memiliki mesin kontrol risiko, Jika banyak pengguna di area yang sama dan ip yang sama yang berbelanja di toko yang sama, akan menyebabkan produk bahan peledak toko dihilangkan dan mempengaruhi kredibilitas dan penjualan penjual tidak valid.', //
      Eleven:
        'Jika ditemukan seseorang mengajukan permohonan untuk beberapa akun, pelanggar akan membekukan akun secara permanen dan membekukan saldo selama 30 hari. Saat Anda perlu menarik saldo yang dibekukan (akhir periode berakhir), plataforma en línea silakan hubungi layanan pelanggan kami untuk mengaktifkan akun untuk ditarik.', //
      OneData: {
        One: 'Tugas de Pengenalan:',
        Two:
          'Jenis tugas\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0Comisario de tarifas',
        Three:
          'Lazada\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.30%',
        Four:
          'Bukalapak\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.35%',
        Five:
          'comprar\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.40%',
        Six:
          'Tokopedia\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.45%',
      },
      TwoData: {
        One: 'Escritorio de nivel de membresía:',
        Two:
          'Tipo de membresía\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0perak anggota',
        Three:
          'Retirada Límite\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa050.000.000',
        Four:
          'Ordene la cantidad\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa030/día',
        Five:
          'porcentaje de comision\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.30%',
        Six:
          'Invitar\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0-',
      },
      FourData: {
        One:
          'Tipo de membresía\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0miembro platino',
        Two:
          'Retirada Límite\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa050.000.000',
        Three: 'Ordene la cantidad\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa040/día',
        Four:
          'porcentaje de comision\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.40%',
        Five:
          'Invitar\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa010',
      },
      FiveData: {
        One: 'Tipo de membresía\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0miembro diamante',
        Two:
          'Retirada Límite\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa050.000.000',
        Three:
          'Ordene la cantidad\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa045/día',
        Four:
          'porcentaje de comision\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa00.45%',
        Five:
          'Invitar\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa030',
      },
      Twelve:
        '*Diferentes niveles pueden obtener números impares y proporciones de comisión. Cuanto más alto sea el nivel de membresía, más números impares podrá recoger. Cuanto mayor sea el pedido, mayor será la comisión.', //
    },
    AgentCooperation: {
      AgentCooperation: 'Cooperación de agentes', // title
      One: 'Lanzamiento estándar de tercer nivel para proxies', //问1
      Two:
        'Los miembros pueden convertirse en representantes de la plataforma recomendando nuevos miembros que se unan para obtener recompensas adicionales.', //
      Three:
        'Promocione las recompensas de usuario de primer nivel directamente para obtener una comisión adicional del 9 % todos los días. Para el usuario de segundo nivel, la recompensa es una comisión adicional del 6 % diaria. Para el usuario de tercer nivel, la recompensa es una comisión adicional del 3 % diaria.', //
      Four: 'Por ejemplo:', //
      // Five:
      //   'Proksi tingkat pertama Anda telah mengundang 10 orang untuk bergabung menjadi anggota, proxy tingkat kedua mengundang 20 orang untuk bergabung menjadi anggota dan proxy tingkat ketiga telah mengundang 40 orang untuk bergabung menjadi anggota. Pendapatan per orang untuk sehari adalah 200.000 IDR, jadi bayangkan saja penghasilan harian yang akan Anda dapatkan.', //
      // Six: 'Level pertama: 200.000 x 10 x 9%  = 180.000 IDR', //
      // Seven: 'Tingkat kedua: 200.000 x 20 x 6%  = 240.000 IDR', //
      // Eight: 'Tingkat ketiga: 200.000 x 40 x 3%  = 240.000 IDR', //
      // Nine:
      //   'Level pertama  +  Tingkat kedua  +  Tingkat ketiga  =  660.000 IDR', //
      Five:
        'Su representante de primer nivel invitó a 10 personas a convertirse en miembros, su representante de segundo nivel invitó a 20 personas a ser miembros y su representante de tercer nivel invitó a 40 personas a ser miembros. El ingreso por persona por día es de 200,000 INR, así que imagínate el ingreso diario que obtendrás.', //
      Six: 'Primer nivel: 200 000 x 10 x 9 % = 180 000 INR', //
      Seven: 'Segundo nivel: 200 000 x 20 x 6 % = 240 000 INR', //
      Eight: 'Tercer nivel: 200 000 x 40 x 3 % = 240 000 INR', //
      Nine:
        'Primer nivel + Segundo nivel + Tercer nivel = 660,000 INR', //
      Ten:
        'Supongamos que invita al miembro A a registrarse, A invita a B y B invita a C,', //
      Eleven:
        'Luego, el miembro A es el usuario de primer nivel de su equipo, B es su usuario de segundo nivel y C es su usuario de tercer nivel.', //
      Twelve:
        'Importe del premio = Comisión del pedido A x 9 % + Comisión del pedido B x 6 % + Comisión del pedido C x 3 %.', //
      Thirteen:
        '*Recordatorio: ¡Esta plataforma emite recompensas de comisiones para todos los agentes que son miembros fuera de línea y no afectarán las comisiones de todos los agentes y miembros fuera de línea!', //
    },
    CommonProblem: {
      CommonProblem: 'Problema comun', //title
      One: 'P: ¿Por qué las empresas necesitan pedidos virtuales?', // 问1
      Two:
        'De acuerdo con las reglas de las principales plataformas de comercio electrónico, cuantas más personas compren el producto, más popular será el producto y más oportunidades serán impulsadas y mostradas por la plataforma de comercio electrónico. El negocio del comercio electrónico es feroz. Para aumentar la exposición del producto, los comerciantes deben aumentar el volumen de transacciones de productos básicos, por lo que eligen generar pedidos virtuales en la plataforma.', //答1
      Three: 'P: ¿Cómo completar el proceso de toma de pedidos?', //问2
      Four:
        'Seleccione el canal de comercio electrónico, haga clic para recoger el pedido automáticamente, el sistema empareja de manera inteligente el producto para obtener el pedido. La plataforma utiliza su información personal real, dirección de envío e IP de red para entregar pedidos a la plataforma de comercio electrónico para simular compras en línea. Sin embargo, el comerciante en realidad no entrega los bienes. Después de que el pedido haya sido enviado con éxito, el capital y la comisión del pedido se reembolsarán inmediatamente a su cuenta.', //答2
      Five: 'P: ¿Por qué necesito recargar antes de recibir el pedido?', //问3
      Six:
        'Para garantizar la autenticidad de los pedidos, cada plataforma de pedidos simulada requiere un pago real al sitio web de comercio electrónico. Para proteger los intereses de la plataforma y los comerciantes, el saldo de su cuenta se deducirá cuando se envíe el pedido y se reembolsará inmediatamente después de que se complete el pedido.', // 答3
      Seven:
        'P: ¿Cómo aumentar el número de pedidos para recoger y aumentar la comisión?', //问4
      Eight:
        'Al invitar a amigos a registrarse, cuanto mayor sea el nivel, mayor será el número total de pedidos por día y mayor será el índice de comisión; el sistema automáticamente iguala el monto del pedido entre el 30% y el 70% del saldo de la cuenta, por lo tanto, cuanto mayor sea el saldo de la cuenta, cuanto mayor sea la cantidad de cada pedido y mayor sea la comisión.', // 答4
      Nine: 'P: ¿Cómo invito a amigos a registrarse?', //问5
      Ten:
        'Haga clic en "Invitar amigos", copie el enlace para compartir o el código QR y envíelo a sus amigos, compañeros de clase, colegas y familiares a través del software social. Debe completar el código de invitación para registrarse en esta plataforma, y ​​la otra parte se convertirá en miembro de su equipo después del registro.', // 答5
      Eleven: 'P: ¿Cómo retiro efectivo?', //问6
      Twelve:
        'Puede solicitar retiros de efectivo después de alcanzar la cantidad mínima de pedido. Si no se cumplen los requisitos de cantidad mínima de pedido, simplemente continúe hasta que se cumplan los requisitos. Una vez que se aprueba el retiro, la plataforma depositará los fondos en su cuenta bancaria consolidada y la hora exacta de recepción depende de su banco.', // 答6
    },
    // 首页会员升级页面 - 小饶
    MemberUpgrade: {
      MemberUpgrade: 'Actualización de miembro', // title
      Member: 'Nivel de miembro:', //会员等级
      Orders: 'Pedidos disponibles todos los días: ', // 可用订单
      Commission: 'aumento de la comisión', // 佣金奖金
      DedicatedOne: 'Servicio al cliente dedicado', // 特殊客服服务
      DedicatedTwo: 'Servicio al cliente dedicado', // 特殊客服服务
      Swipe: 'Desliza hacia la izquierda y hacia la derecha para ver más', // 说明
      One: {
        Silver: 'miembro de plata', // 银卡会员
        Need: 'Necesito invitar a 0 personas', // 邀请
        Withdrawal: 'Límite de retiro: 50.000.000', // 提款限额
        quantity: 'Cantidad de pedido: 10/día', // 订单数量
        rate: 'Tasa de comisión:0.30%', // 佣金率
        fee: 'Tarifa de retiro: 2.00%', // 提现费
        Activated: 'Activado', //已解锁
      },
      Two: {
        Silver: 'Miembro dorado', // 黄金会员
        Need: 'Necesito invitar a 3 personas', // 邀请
        People: '3 personas todavía cortos', // 缺人
        Withdrawal: 'Límite de retiro: 50.000.000', // 提款限额
        quantity: 'Cantidad de pedido: 35/día', // 订单数量
        rate: 'Tasa de comisión:0.35%', // 佣金率
        fee: 'Withdrawal fee:2.00%', // 提现费
        Activated: 'Daily recharge amount:1.000.000', //每日提款补充
        Invite: 'invitar ahora', // 立即邀请
      },
      Three: {
        Silver: 'miembro platino', // 白金会员
        Need: 'Necesito invitar a 10 personas', // 邀请
        People: '10 personas todavía cortos', // 缺人
        Withdrawal: 'Límite de retiro: 50.000.000', // 提款限额
        quantity: 'Cantidad de pedido: 40/día', // 订单数量
        rate: 'Tasa de comisión:0.40%', // 佣金率
        fee: 'Tarifa de retiro: 2.00%', // 提现费
        Activated: 'Cantidad de recarga diaria: 1.500.000', //每日提款补充
        Invite: 'invitar ahora', // 立即邀请
      },
      Four: {
        Silver: 'Diamond member', // 钻石会员
        Need: 'Necesito invitar a 30 personas', // 邀请
        People: '30 personas todavía cortos', // 缺人
        Withdrawal: 'Límite de retiro: 50.000.000', // 提款限额
        quantity: 'Cantidad de pedido: 45/día', // 订单数量
        rate: 'Tasa de comisión:0.45%', // 佣金率
        fee: 'Tarifa de retiro: 2.00%', // 提现费
        Activated: 'Cantidad de recarga diaria: 2.000.000', //每日提款补充
        Invite: 'invitar ahora', // 立即邀请
      },
    },
    PhoneNew: 'Confirma que has terminado.', // 是否输入完毕的轻提示
    // 轻提示 - 小饶
    Toast: {
      One: 'Guardar con éxito', //保存成功
      Two: 'Fracaso', //保存失败
    },
    // 确定和取消的多文本 -- 小饶
    Button: {
      One: 'Cancelar', //取消
      Two: 'Confirmar', //确定
    },
    logout: 'cerrar sesión', // 个人中心下的退出按钮-小饶
  },
  Record: {},
  Grab: {},
  Financing: {
    time: 'Tiempo', // 时间
    day: 'Día', // 天
    Fina: 'Financiero', // 标题
    // assets: 'Total assets Rp', // 总资产RP
    assets: 'Los activos totales ₹', // 总资产Rs
    // amount: 'Financial amount Rp', // 财务金额RP
    amount: 'Monto financiero ₹', // 财务金额Rs
    revenue: 'Los ingresos totales', // 总收入
    earnings: "Ganancias de ayer", // 昨日总收入
    Balance: 'Transferencia de saldo', // 余额转入
    // RP: 'Rp', // 钱的单位(印尼)
    RP: '₹', // 钱的单位(印度)
    Please: 'Por favor ingrese tran', // 金额输入框placeholder
    Estimated: 'Ingresos estimados', // 预计收入文字
    Income: 'Estándar de ingresos', // 收入标准文字
    TransferOut: 'Trasferencia', // 转出btn文字
    ransferIn: 'Transferencia', // 转入btn文字
    SukuBung: 'Tasa de interés', // 商品名称
    Transfer: 'Transferencia fallida', //转入提示
    Pleaseint: 'Por favor ingrese la cantidad',//提示
    Successful: 'Transferencia exitosa',
    Please:
      'Ingrese el monto de la transferencia', // 请输入转入金额
    RansferInSU: {
      FinancialAcc: 'Registro de acceso financiero',
      Acc: 'Registro de acceso',
    },
    // 首页客服页面
    ServiceSu: {
      Please: 'Por favor escribe...', //请输入
      send: 'mandar', //发送
      Online: 'Servicio en línea', //在线客服
      Service: 'Servicio', // title
      Customer: 'Servicio al Cliente', //客服服务
      Ifyou:
        'Si encuentra problemas y necesita ayuda, comuníquese con el servicio al cliente en línea', // 服务说明
      // Layanana8: 'Layanana Pelanggan No.8 (WhatsApp)', //服务号
      Layanana8: 'Centro de atención al cliente No.8 (WhatsApp)', //服务号
      // Layanana9: 'Layanana Pelanggan No.9 (WhatsApp)', // 服务号
      Layanana9: 'Centro de atención al cliente No.9 (WhatsApp)', // 服务号
      Dedicated: 'Servicio al cliente dedicado', //专属客服
      Telegram8: 'Servicio al cliente Mi.8 (Telegrama)', //服务号
      Telegram008: 'telegrama008', //服务号
      Telegram9: 'Atención al Cliente No.9 (Telegrama)', //服务号
      Telegram009: 'telegrama009', //服务号
    },
  },
  Home: {
    Member: 'Noticias de miembros', // 新闻
    Mission: 'Salón de la misión', // 宣讲堂
    Learnde: 'Aprende', // 信息
    Hello: 'Hola', // 你好
    Let: 'Deje que Victory Mall abra el camino a la riqueza', //说明
    total: 'Mis activos totales', //我的总资产
    Withdraw: 'Retirar', //收回
    Recharge: 'Recargar', //充值
    // RP: 'Rp', //Rp
    RP: '₹', //ZAR
    // earningsRp: "Yesterday's earningsRp", //昨天的收益
    // incomeRp: 'Cumulative incomeRp', //累计收益
    // rarningsRp: "Today's earningsRp", //今日收益
    earningsRp: "Ganancias de ayerRs", //昨天的收益
    incomeRp: 'Ingresos acumuladosRs', //累计收益
    rarningsRp: "Ganancias de hoyRs", //今日收益
    Service: 'Servicio', // 服务
    Invite: 'Invitar', // 邀请
    Lazada: 'Lazada', // 会员白银
    Bukalapak: 'Bukalapak', // 会员黄金
    Shopee: 'comprar', // 会员白金
    Tokopedia: 'Tokopedia', //会员钻石
    silver: 'Canal para miembros plata', //白银说明
    gold: 'Canal para miembros dorados', //黄金说明
    platinum: 'canal para miembros platino', // 白金说明
    diamond: 'canal para miembros diamante', //钻石说明
    Agent: 'Comisión de orden de agente', // 滚动信息
    Order: 'Comisión de pedidos', //滚动信息
    // 首页充值页面
    RechargeSu: {
      Recharge: 'Recargar', //充值
      Catatan: 'Nota especial:', //充值说明
      Next: 'Próxima', //下一步
      RechargeAm: 'Monto de recarga', //充值金额
      Enter: 'Introduce el importe de la recarga', //输入充值金额
      Quick: 'Selección rápida', // 快速选着
      nameP: 'Por favor ingrese su nombre real', //请输入姓名
      name: 'nombre', //姓名
      Pleaseamount: 'Por favor ingrese la cantidad', //请输入金额
      Pleaseint: 'Por favor ingrese la cantidad ', //只能输入0到9之间的整数：
      Pleasename: 'por favor, escriba su nombre', //请输入姓名
    },
    // 首页子页面-Withdraw
    WithdrawSu: {
      Withdraw: 'Retirar', // title
      Withdrawal: 'Cantidad de retiro', // 提现金额
      WithdrawalRe: 'tasa de retiro', // 提现率
      Input: 'Ingrese el monto del retiro', // 输入提款金额
      Balance: 'Equilibrio', // 金额
      WithdrawAll: 'retirar todo', //  全部选中
      Mobile: 'Número de teléfono móvil', // 手机号码
      Bank: 'número de tarjeta bancaria', // 银行卡号
      Owned: 'banco propio', // 所属银行
      Account: 'Nombre de la cuenta', // 用户名
      Transaction: 'Contraseña de transacción', // 密码
      Withdrawim: 'retirar inmediatamente', // 提现按钮
      Pleaseamount: 'Por favor ingrese la cantidad', //请输入金额
      Pleasephone: 'Por favor, introduzca su número de teléfono', //请输入您的手机号
      Pleasecard: 'Por favor ingrese su número de tarjeta bancaria', //请输入银行卡号
      Pleasebank: 'Por favor ingrese el nombre de su banco', //请输入您的银行
      Pleasename: 'por favor, escriba su nombre', //请输入姓名
      Pleasepassword: 'Por favor ingrese la contraseña de la transacción', //请输入交易密码
      Pleaseint: 'Por favor ingrese la cantidad', //只能输入0到9之间的整数：
      PasswordError: 'Contraseña incorrecta', // 密码错误
      Successful: 'Ya solicitó el retiro', //已经申请提款
      Insufficient: 'Saldo de retiro insuficiente', //提现余额不足
      Unbound: 'La tarjeta bancaria no ha sido vinculada', //银行卡还没有绑定
      Binding: 'Vincula tu tarjeta bancaria', //绑定银行卡
      idCard: 'Número de tarjeta de identificación', // 身份证
    },
  },
  Login: {
    indexTitle: 'Iniciar sesión en',
    remenber: 'recuerda la contraseña',
    versions: 'V.86', // 版本号
    masuk: 'Registrarse', // 标签
    name: 'descarga de la aplicación', // 标签旁边的名称
    forgot: 'Olvidó', // 找回密码文字
    registrasi: 'Registro', // 注册账号文字
    loginButton: 'Registrarse', // 登录的btn
    nameInput: 'Ingrese su número telefónico', // 登录时账号input提示
    pwdInput: 'Ingresa tu contraseña', // 登录时密码input提示
    notLoginHint: 'Registrarse', // 返回登录弹窗提示
    goLoginText: 'confirmar', // 返回登录弹窗去登陆btn
    Forgot: {
      title: 'Recuperar Contraseña',
      registerTitle: 'Registro',
      phone: 'Ingrese su número telefónico', // 账号提示
      code: 'Introduce el código de verificación', // 验证码提示
      area: 'Por favor ingrese el código de área', // 暂时未使用
      sendCode: 'enviar código', // 发送短信btn文字
      password: 'Clave', // 第一次密码提示
      againPassword: 'Confirmar Contraseña', // 再次输入密码提示
      confirm: 'Entregar', // 提交
      goBack: 'Tenía una cuenta, inicie sesión ahora', // 返回
      register: 'Lista', // 暂时未使用
    },
    Daftar: {
      title: 'Registro',
      area: 'Por favor ingrese el código de área',
      phone: 'Ingrese el número de teléfono móvil',
      code: 'Introduce el código de verificación',
      userName: 'apodo',
      password: 'Clave',
      againPassword: 'Confirmar Contraseña',
      referrer: 'Número de referencia', // 推荐人号码
    },
  },
  Cart: {
    title: 'tienda de pedidos', // 订单商城
    explain: 'Descripción', // 说明
    grade: {
      SliverMember: 'Astilla\nMember', // 白银
      GoldMiembro: 'Oro\nMiembro', // 黄金
      PlatinumMember: 'Platino\nMiembro', // 白金
      DiamondMember: 'Diamante\nMiembro', // 钻石
    },
    info: {
      // allMoney: 'My total\n Aset Rp', // 我的总资产Rp
      allMoney: 'mi total\n Aset ₹', // 我的总资产Rs
      dayAllOrder: `de hoy\n orden total`, //今天的总订单
      dayTrustMoney: `de hoy\n Comisión`, // 今天委托金
      yesterdayMoney: `de ayer\n ingreso`, // 昨天的收入
    },
    text: {
      // rebateAndPrincipal: 'Return\n capital\n and\n commission', // 返佣及本金
      rebateAndPrincipal: 'Devolución\n de\n capital\n y comisión', // 返佣及本金
      residue: 'Restante', // 剩余
      one: 'Pedido', // 单
      rebate: 'Comisión de devolución', // 返佣
      // buyBtn: 'Grab an order now', // 立即抢单
      buyBtn: 'Compra ahora', // 立即抢单（换文字）
      toGet: 'Recibirá', // 即将获得
      orderSuccess: 'Pedido exitoso', // 抢单成功
      orderGetMoney: 'Pago exitoso', // 付款成功
      orderWaiting: 'Esperando', // 等待中
      orderOver: 'Hecho', // 完成
    },
    state: {
      all: 'Todos', // 全部
      going: 'En proceso', // 进行中
      paid: 'Pagado', // 已支付
      frozen: 'Congelado', // 已冻结
      over: 'Hecho', //已完成
    },
    zzInfo: {
      buyIng: 'Adquisitivo', // 购买中
      buySucceedFindDT:
        'Haga clic en la vista de estado después de que la compra\n sea exitosa', // 购买成功点击动态查看即可
      ok: 'Okey', // 好的
      buyFailed: 'La compra falló', // 购买失败
      notLevel: 'Tu nivel VIP es insuficiente', // 您的会员等级不足
      buyJurisdiction1: 'Si desea comprar una orden de membresía de oro', // 如需购买黄金会员订单
      buyJurisdiction2: 'Si desea comprar un pedido de membresía de platino', // 如需购买白金会员订单
      buyJurisdiction3: 'Si desea comprar un pedido de membresía de diamante', // 如需购买钻石会员订单
      meet: 'Debe cumplirse una de las siguientes condiciones', // 需满足以下条件之一
      // invite: '1. Invite 10 friends to make money together', // 1.邀请10个好友一起赚钱
      invite1: 'Invita a 3 amigos a ganar dinero juntos', // 1.邀请3个好友一起赚钱
      invite4: 'Invita a 10 amigos a ganar dinero juntos', // 1.邀请10个好友一起赚钱
      invite3: 'Invita a 30 amigos a ganar dinero juntos', // 1.邀请30个好友一起赚钱
      // buySendMoney: '2. Spend Rp2000 to buy membership diamond', // 2.花费Rp2000购买砖石会员
      buySendMoney: '2. Gasta Rs2000 para comprar un diamante de membresía', // 2.花费Rs2000购买砖石会员
      invite2: 'Invitar a amigos', // 邀请好友
      buy: 'Compra ahora', // 立即购买
      myMoney: 'el saldo de tu cuenta', // 您的账户余额
      under: 'Abajo', // 低于
      notSendMoney: 'no se puede hacer la transaccion', // 不能进行交易
      goTopUp: 'Por favor, vaya a recargar', // 请前往充值
      know: 'Lo sé', // 我知道了
      account: 'Asentamiento', // 结算
    },
    hint: {
      p1: '1. Los diferentes niveles de membresía tienen diferentes órdenes de compra',
      p2:
        '2. Cuanto mayor sea su nivel de membresía, mayor será el monto de la comisión del pedido que se puede comprar',
      p3: '3. Para pedidos comprados, puede monitorear el estado del pedido en cualquier momento',
    },
  },
  Hint: {
    SendCodeSuccess: '¡El código de verificación se envía con éxito!',
    closed: 'Cerrado', // 停服
    notTime: 'Sin tiempo seleccionado', // 没有选择时间
    notTimeFailed: 'La selección de tiempo falló', // 选择时间错误
    success: 'Exitoso', // 成功
    failed: 'Fallido', // 失败
    phoneNot: 'El número de teléfono móvil/número de cuenta no puede estar vacío', // 手机号/账号不能为空
    pwdInconformity: 'Contraseña no es la misma', // 密码不一致
    notMoney: 'Dinero insuficiente', // 货币不足
    notEvent: 'La característica no se puede usar todavía', // 功能暂未开启
    notInput: 'Entrada de datos incompleta', // 输入内容不完整
    loading: 'Cargando', // 加载中
    notMore: 'No más', // 没有更多了
    notPhone: `La cuenta no existe`, // 账号不存在
    yesPhone: 'la cuenta ya existe', // 账号已存在
    levelNot: 'NivelNo', // 等级不够
    serverMaintenance: 'Ten paciencia, el juego está en mantenimiento.', // 服务器维护中
    invite: 'Invita a amigas \n a ganar dinero \n juntas', // 分享页面的文字
    goodsNumNo: 'Cantidad insuficiente', // 物品数量不足
    haveBuy: 'Comprado', // 订单页面已买文字
    depositBtnText1: 'Aceptado', // 已领取
    depositBtnText2: 'Disponible', // 可领取
    depositBtnText3: 'Recibir', // 领取
    // commissionMoney: 'Return commission Rp:', // 返佣额度
    commissionMoney: 'Comisión de devolución ₹:', // 返佣额度
    bill: 'factura', // 玩家查询充值流水记录的记录
    billRecordTitle: 'Registros de facturación', // 流水记录
    balance: 'equilibrio', // 余额
    reference: 'Pegar código de invitación', // 粘贴邀请码
    updateText: 'Actualice a la última versión.',
  }, // 提示
  newAdd: {
    all: 'todos',
    Completed: 'terminado',
    unfinished: 'inconcluso',
    GetCodeBtn: 'obtener código de verificación',
    copySuccess: 'Copia éxito!',// 复制成功
    copyError: '¡Copia el fracaso!',// 复制失败
    noLoginUserName: 'Ingresa, por favor',// 请登录
    Withdraw: 'Retirar',// 提现
    Recharge: 'Recargar',// 充值
    commission: 'commission',// 佣金
    single: 'soltera',// 单
    missionHall: 'Salón de la misión',// 任务大厅
    videoTutorial: 'Videotutorial',// 视频教程
    helpCenter: 'Centro de ayuda',// 帮助中心
    inviteFriends: 'Invitar a amigos',// 邀请好友
    play: 'Reproducir ahora',
    help1Title: 'Cómo ganar comisiones con Mudo',// 如何通过CG赚取佣金
    help1Context: 'Los propietarios de negocios de comercio electrónico necesitan aumentar las ventas de la tienda, por lo que solo necesitamos completar los requisitos de la tienda de comercio electrónico y completar el pedido en el software Mudo. El propietario nos dará una comisión. Cada usuario puede recibir 10 tareas de pedido por día. Puede ganar una comisión del 10% al 30% en un día.',
    // 电商老板需要添加店铺的销售额,所以我们只需要在CG上完成电商店铺的要求,完成订单即可。业主会给我们佣金。每个用户每天可以接收10个订单任务。您可以在一天内赚取 10% 到 30% 的佣金。
    help2Title: 'Cómo aumentar el número de misiones.',
    help2Context: 'Debido a que hay demasiados empleados a tiempo parcial, la empresa estipula que todos los empleados a tiempo parcial en línea usen el único whatsapp para ponerse en contacto y recibir la misión.',
    help3Title: 'Cómo completar la misión',
    help3Context: 'es ayudar a los principales comerciantes a aumentar las ventas y recompensarlo de acuerdo con la cantidad de transacciones. Por cada pedido exitoso, gana comisiones y los vendedores ganan reputación.',
    referralLink: 'Enlace de referencia:',// 推荐链接
    copy: 'dupdo',// 复制
    paste: 'Paste', // 粘贴
    blance: 'equilibrio',// 余额
    todayOrder: "Orden de hoy",// 今日订单
    provided: 'Los datos son proporcionados por',// 本数据由提供
    Transaction: 'Transacción',// 交易
    Frozen: 'Congelado', // 冻结
    Done: 'Hecho',// 完成
    Cancel: 'Cancelar',// 取消
    ExpectedReturn: 'Rendimiento esperado:',// 预计返还:
    ImmediatePayment: 'Pago inmediato', // 立即支付
    AvailableNum: 'Todavía hay',// 可用次数
    OrderImmediately: 'orden inmediatamente', // 马上配单
    MakeMoneyEveryDay: 'Gana dinero todos los días', // 日日赚钱
    TodayRecord: "récord de hoy", // 今日记录
    AllocatCommissions: 'Asignación de comisiones', // 分配佣金
    FrozenAmount: 'Cantidad congelada', // 冻结金额
    TaskMatch: 'Aleatoriedad del orden de tareas',// 任务分配
    AvailableBalance: 'Saldo disponible',// 可用余额
    WaitDispatch: 'Esperando el envío, ¡póngase en contacto con el gerente de la tienda!',// 等待分配任务,请联系店长!
    OrderDescription: 'Descripción del pedido', // 今日记录
    // CouldMatch: 'Each account can match',// 每个账户每天可配
    CouldMatch: 'Las tareas son emparejadas aleatoriamente por el sistema',
    OrderPerDay: 'soltero.',// 单。
    DescriptionRate: 'La comisión de colocación de pedidos es uniforme',// 配单佣金统一为订单金额的
    DescriptionPeriod: 'del monto del pedido.',// 。
    Description3: 'El sistema se basa en la tecnología LBS y empareja automáticamente los productos a través de la nube.',
    // 系统基于LBS技术, 通过云端自动匹配商品。
    Description4: 'Para evitar la supervisión de la plataforma, si la orden \
    no se confirma y se envía dentro de los 20 minutos, el sistema \
    congelar el monto del pedido.',
    // 为防止平台监控,如果订单为在20分钟内确认并发送,系统将冻结订单数量。
    Note: 'Note:', // 注意事项:
    DescriptionNote: "Cuando la plataforma hace coincidir el pedido con el usuario, la plataforma\
    envía la información del pedido al backend del comerciante. Si el\
    el usuario no envía el pedido dentro de los 20 minutos, el monto de la \
    el orden será congelado por el sistema. Para eludir el\
    sistema de supervisión, las cuentas de usuario serán puntuadas, lo que\
    afectan directamente el tiempo y la reputación del próximo pedido del usuario\
    ¡pareo! Si hay otras cosas que necesitan ser tratadas,\
    haga clic para detener el pedido a tiempo para evitar la congelación debido a \
    se acabó el tiempo",
    // 当平台为用户匹配订单时, 平台将订单信息提交给商家后台。如果用户在20分钟内未提交订单，
    // 订单金额将被系统冻结。为避免监管系统，会对用户账号进行评分，直接影响用户下一次撮合的
    // 时间和口碑！如有其他需要处理的，请及时点击停止下单，以免超时冻结
    OrderNum: 'Número de orden:',// 订单号:
    TotalOrderPrice: 'Precio total del pedido', //总价:
    SubmitNow: 'Aplique ahora',// 立即提交
    Record: 'Registro', // 记录
    MemberAccount: 'cuenta de miembro', // 会员账户
    AccountPlaceholder: 'Por favor ingrese su cuenta de miembro',// 请输入您的账户
    RechargeAmount: 'Monto de recarga',// 充值金额
    RechargeAmountPlaceholder: 'Por favor ingrese el monto de la recarga', //请输入充值金额
    SelectRechargeMethod: 'Seleccione el método de recarga', // 选择充值方式
    NotBeenSentOrder: '¡El pedido no ha sido enviado, por favor contacte al personal para enviar el pedido!', // 尚未派发订单,请联系工作人员派发订单!
    // TodayOrderOver: "Today's order has been completed, please try again tomorrow!",
    TodayOrderOver: 'Póngase en contacto con el servicio de atención al cliente en línea',
    // 今日订单已完成,请明天再试!
    NotLogin: 'No has iniciado sesión, ¡Inicia sesión e inténtalo de nuevo!',
    // 您尚未登录,请登录后重试!
    HaveNoteMethod: 'No existe tal método de recarga, elija otro método ~',
    // 暂时没有这样的充值方法,请选择其他充值方法~
    Login: 'Acceso', // 登录
    AccountRecord: 'Ingresos y gastos',// 账变记录
    RealName: 'Nombre real', // 真实姓名
    IFSCCODE: 'CÓDIGO IFSC', // IFSC
    BankName: 'Nombre del banco', // 银行卡名称
    BankNum: 'Número de banco (ID AC)', // 银行卡号
    Mobile: 'Móvil', // 手机
    MobileNum: 'Número de teléfono móvil', // 手机号码
    Email: 'Correo electrónico', // 邮箱
    EmailRequired: 'correo electronico es requerido', // 邮箱必填
    AddBankCardNote: 'Nota: La tarjeta bancaria es información importante, ¡comllénela cuidadosamente!',
    // 银行卡是重要信息, 请仔细填写!
    Submit: 'Entregar', // 提交
    AddBankCardTitle: 'añadir-tarjeta-bancaria',// 添加银行卡
    BankCard: 'tarjeta bancaria', // 银行卡
    Name: 'Asi que', // 姓名
    CardNum: 'Número de tarjeta (ID de AC)', // 卡号
    Reading: 'esta leyendo...', // 正在读取中...
    AllBalance: 'el balance general', // 总余额
    EnterWithdrawalAmount: 'Por favor ingrese el monto del retiro',// 请输入体现金额
    AllOut: 'Completamente',// 全部
    AskNow: 'Pregunta ahora', // 提现
    EnterPwdPlaceholder: 'Por favor ingrese una contraseña',// 请输入密码
    NotHaveCard: 'No ha configurado su tarjeta bancaria, ¿quiere ir a la configuración?',
    // 你尚未绑定银行卡,您要去绑定银行卡吗?
    GetBankInfoFail: 'No se pudo recuperar la información bancaria', // 拉取银行信息失败
    EnterValidPayPwd: '¡Por favor ingrese una contraseña de pago válida!', // 请输入有效的支付密码
    Balance: 'Equilibrio', // 余额
    Total: 'Total', // 总共
    total: 'total', // 总共
    records: 'registros', // 记录
    DisplayPerPage: 'cada página muestra', // 每页显示,
    NullNum: '',// 条
    pages: 'paginas.', // 页
    toAudit: 'realizar una auditoria', // 待审核
    succeed: 'triunfar', // 成功
    RechargeRecordTitle: 'recarga-registro',// 充值记录
    rests: 'descansa', // 其他
    Arrive: 'Llegar', // 到账
    Income: 'Ingreso',// 收入
    Expenditure: 'Gasto',// 支出
    forzenCommission: 'comisión no reclamada',// 冻结佣金     以前的Frozen Commission
    companyIntro: 'Perfil de la empresa', // 公司简介
    companyTitle: 'Tata Mall es parte del Grupo Tata',
    warmTitle: 'aviso cálido',
    companyText: "Tata Group es el grupo empresarial más grande de India, fundado en 1868 y con sede en Mumbai, India. Las operaciones comerciales involucran siete áreas de negocios: tecnologías de la información y las comunicaciones, ingeniería, materiales, servicios, energía, productos de consumo y productos químicos. Como grupo empresarial de rápido crecimiento en India, Tata Group tiene más de 200 subsidiarias, con operaciones en más de 80 países en 6 continentes y exportaciones de productos y servicios a 85 países. Los ingresos totales de Tata Group para el año fiscal 2020-2021 son de 100 090 millones de USD, de los cuales el 58 % proviene de operaciones en el extranjero. El número de empleados del Grupo en todo el mundo es de aproximadamente 450.000. Desde hace 153 años, el nombre Tata en la India ha sido muy respetado por su adhesión a los buenos valores y la ética empresarial. Clasificada como la undécima empresa más respetada del mundo por Forbes.",
    depositAddress: 'dirección de depósito',
    rechargeSteps: 'Pasos de recarga',
    completePayment: 'El pago completo',
    rechargeNow: 'Recargar ahora',
    rechargeCancel: 'Cancelar el pago',
    bankName: 'Nombre del banco',
    payee: 'el beneficiario',
    cardNum: 'número de tarjeta bancaria',
    reviewing: 'En revisión',
    remittance: 'Triunfar',
    notThrough: 'Fallar',
    OrderID: 'Solicitar ID',
    "Amount": "cantidad",
    "toCutScreen": "¡Tome una captura de pantalla si la imagen no está disponible!",
    "cardTabBank": "Banco",
    "cardTabUSDT": "USDT",
    "name": "Nombre",
    "chainType": "Tipo de cadena",
    "newNavTitle": "Enviar petición",
    "shopMoney": "Cantidad de bienes",
    "actualMoney": "Realización",
    "commitBtnText": "Generar pedidos",
    "commitHint": "Incompleto"
  },
  "mine": {
    "servier": "Servidor",
    "integral": "Integral",
    "balance": "Rescatar",
    "record": "Registro",
    "wtdAmt": "Puntos de rescate",
    "avaBlc": "Cantidad de intercambio",
    "allWtd": "Todo",
    "close": "Cancelar",
    "commit": "confirmar",
    "wtdHint": "Ingrese el valor de retiro correcto"
  },
  "add": {
    "Commission": "Cantidad inestable hoy",
    "Yesterday": "Ayer",
    "Cumulative": "Acumulativo",
    "Today": "Hoy",
    "TeamNum": " Tamaño total del equipo",
    "TeamMoney": " Beneficio total del equipo",
    "Personalcenteraccountchangerecord": "Registro de cambio de cuenta",
    "lockedbalance": "cantidad",
    "Cumulativeinvitation": "Invitación acumulativa",
    "Validinvitation": "Invitación válida",
    "Accumulatedrebate": "Descuento acumulado"
  },
  "home.btn.wallet": "Fondos",
  "home.btn.recharge": "Mandar",
  "home.btn.withdraw": "Para eliminar",
  "home.btn.invite": "Invitar",
  "home.btn.message": "Mensaje",
  "page.grab": "Tomar",
  "orderCommission": "Llena de la solicitud",
  "commission": "Ganancia",
  "todayIncome": "Hoy",
  "personalEarnings": "Ganancias personales",
  "completedOrders": "Solicitudes completadas",
  "lockedOrders": "Pedidos bloqueados",
  "unfinishOrder": "Órdenes inacabadas",
  "frozonAmount": "Fondos inestables",
  "currentBalance": "Saldo actual",
  "page.mine": "Detalles de la cuenta",
  "mine.invitationCode": "código de invitación",
  "mine.serverCenter": "Centro de servidor",
  "mine.tools.total": "Total",
  "mine.tools.financial": "Fondos",
  "mine.tools.details": "Detalles",
  "mine.tools.freeze": "Balance",
  "mine.serviceTools.personal": "Tipo",
  "mine.serviceTools.teamReport": "Informe del equipo",
  "mine.serviceTools.invite": "Cuenta familiar",
  "mine.serviceTools.message": "Mensaje",
  "mine.serviceTools.aboutUs": "Sobre nosotros",
  "mine.serviceTools.security": "Seguridad",
  "mine.serviceTools.address": "DIRECCIÓN",
  "mine.serviceTools.inviteTask": "Invitación de tareas",
  "page.team": "Equipo",
  "page.aboutus": "Sobre nosotros",
  "page.financial": "Fondos",
  "page.financialRecord": "Registro financiero",
  "hint.InternetErr": "Error de red, ¡inténtelo de nuevo más tarde!",
  "totalDeposit": "Depósito total",
  "totalRevenue": "Rendimiento total",
  "btn.transferInto": "Transferir a",
  "btn.transferOut": "Transferir",
  "page.transferInto": "Transferir a",
  "page.transferOut": "Transferir",
  "balanceTransfer": "Balance",
  "availableBalance": "Saldo disponible",
  "depositRate": "Tasa de depósito",
  "days": "días",
  "hint.success": "Éxito",
  "hint.fail": "Fallido",
  "status.into": "Transferir a",
  "status.out": "Transferir",
  "label.amount": "Cantidad",
  "label.time": "Tiempo",
  "Button": {
    "cancle": "Cancelar",
    "confirm": "confirme"
  },
  "dialog.title.inputPaypass": "Pagar contraseña",
  "dialog.nopaypass": "La contraseña de pago no se ha configurado, haga clic en Aceptar para ir a la configuración.",
  "mine.serviceTools.lan": "Idioma",
  "checkLans": "Lenguaje de cambio",
  "mine.tools.commission": "Ganancia",
  "page.commissionRecord": "Ganancia",
  "noticeList.all": "Todo",
  "noticeList.unread": "No leído",
  "successWithdrawof": "Retiro exitoso",
  "home.title.notice": "Percibir",
  "home.title.patener": "En alianza con",
  "login.rememberPassword": "Recuerda la contraseña",
  "page": {
    "cart": "Carro",
    "order": "Orden",
    "financing": "Financiación",
    "goods": "Activos",
    "levelUpgrade": "Actualización de nivel",
    "PlatformIntroduction": "Introducción a la plataforma",
    "RuleDescription": "Descripción de la regla",
    "AgentCooperation": "Cooperación del agente",
    "CommonProblem": "Problema comun",
    "MemberUpgrade": "Actualización de miembros",
    "login": "acceso",
    "forgot": "Olvidado",
    "registrasi": "Registro",
    "record": "Registro",
    "Recharge": "Mandar",
    "inviteFriends": "Invitar a amigos",
    "Withdraw": "Para eliminar",
    "Service": "Servicio",
    "DialoguePage": "Página de diálogo",
    "user": "Tipo",
    "RansferIn": "Ransferina",
    "PersonalCenter": "Centro personal",
    "Portrait": "Retrato",
    "Name": "Nombre",
    "Account": "Cuenta",
    "Password": "Contraseña",
    "PaymentCode": "Choque de páginas",
    "Site": "sitio",
    "AccountData": "Cuenta de cuentas",
    "RechargeRecord": "Recargar",
    "WithdrawalRecord": "Retiro",
    "InviteFriends": "Invitar a amigos",
    "Announcement": "Anuncio",
    "TheTeamReport": "El informe del equipo",
    "BillRecord": "Registro de cuentas",
    "BankCardAdd": "Agregar tarjeta bancaria",
    "NewRecharge": "Completar",
    "RechargeList": "Lista de recarga",
    "newWithdraw": "Para eliminar",
    "withdrawList": "Lista de retiro",
    "SettingPayPwd": "Definición de PayPass",
    "MakeMoney": "Ganar dinero",
    "team": "equipo",
    "qrPay": "QRCode-Pago",
    "bankPay": "Paga paga",
    "aboutus": "Sobre nosotros",
    "financial": "Fondos",
    "financialRecord": "Registro financiero",
    "TransferInto": "Transferir a",
    "TransferOut": "Transferir",
    "CommissionRecord": "Ganancia",
    "Security": "Seguridad",
    "Address": "DIRECCIÓN",
    "Message": "Mensaje",
    "InviteTask": "Invitado",
    "Notice": "Percibir",
    "modifyPaymentPassword": "Modificar la contraseña"
  },
  "copyLink": "Copiar link",
  "teamReport": {
    "title": "Informe del equipo",
    "subTitle": "Descripción general del equipo subordinado",
    "taskTitle": "Covid {in} personas para registrarse y completar la solicitud",
    "bonus": "Prima",
    "Unfinished": "Inconcluso",
    "Getted": "Ser tomado"
  },
  "copy": "copia de",
  "address": {
    "name": "Nombre",
    "inputPlaceholder": "Porfavor llene",
    "phone": "Teléfono",
    "areaPlaceholer": "Información de la dirección de entrega",
    "submit": "Ahorrar"
  },
  "password.old": "Contraseña anterior",
  "password.old.placeholder": "Ingrese su contraseña anterior",
  "password.confirmPassword": "Confirmar la contraseña",
  "password.confirmPassword.placeholder": "Ingrese una nueva contraseña nuevamente",
  "hint.passwordDiff": "Ambas contraseñas no coinciden",
  "loginType": {
    "phone": "Teléfono",
    "email": "Correo electrónico"
  },
  "Login.Daftar.email": "Correo electrónico",
  "Login.emailInput": "Escriba su correo electrónico",
  "My.PersonalInformation.Password.email": "correo electrónico",
  "My.PersonalInformation.Password.NewEmail": "Ingrese el correo electrónico de su teléfono celular",
  "mine.serviceTools.certify": "Verificación",
  "Certification": {
    "type": {
      "id": "número de identificación",
      "driver": "Licencia de conducir",
      "passport": "Pasaporte"
    },
    "name": "Nombre",
    "license": "Número de licencia",
    "upload": "Anexar documento",
    "submit": "Mandar",
    "incompleteInformation": "¡Complete la información y vuelva a intentarlo!",
    "submitSuccess": "Se ha enviado información de certificación, ¡espera la revisión!",
    "photo": "Foto de identificación",
    "review": "bajo revisión",
    "verified": "verificado"
  },
  "upload": {
    "uploading": "Enviando ...",
    "failed": "Falla de carga"
  },
  "login.forgetPassword": "¡Olvidé su contraseña, comuníquese con el servicio al cliente!",
  "newAdd.BankNum.placeholder": "Ejemplo: 09XXXXXXXX",
  "page.certification": "Verificación de identidad",
  "newAdd.page": "página",
  "newAdd.record": "registro",
  "emailFormatError": "¡Ingrese la dirección correcta y de correo electrónico!",
  "urlParsePlaceholder": "Ingrese la identificación de la carpeta",
  "btn.Identify": "Identificar inmediatamente",
  "produceStatus.confirming": "Para ser confirmado",
  "produceStatus.paying": "Recibir el pago",
  "produceStatus.paid": "Pagado",
  "produceStatus.done": "Hecho",
  "produceStatus.cancle": "Devuelto",
  "order.old": "Orden de altos ingresos",
  "order.new": "Orden regular",
  "welfareDialogTitle": "Consejos de beneficios",
  "viGrabDesces": {
    "1": "Cada pedido se puede seleccionar como una orden de la suerte y la comisión se incrementará al {tarifa}%. Comuníquese con su mentor de divulgación para solicitar otras tareas de divulgación",
    "2": "Ayuda a los compradores de compradores a completar la creación de pedidos virtuales, aumentar la visibilidad y ganar comisiones",
    "3": "Póngase en contacto con su tutor de promoción lo antes posible después de recibir el pedido, envíe los datos del pedido y complete la tarea",
    "4": "El número de tareas es limitado, preste atención a las sugerencias de promoción del instructor al aceptar pedidos",
    "5": "El monto del reembolso se emitirá dentro de las 5 a 10 minutos posteriores a la finalización de la tarea. Si tiene alguna pregunta, consulte un servicio al cliente o en línea.",
    "6": "Concluir las tareas atribuidas por Shopee, mejorar el nivel VIP y obtener comisiones más altas para las tareas de Fackbook",
    "7": "Completa acumulativamente tareas de compra designadas para comisiones adicionales. Para recompensas específicas, consulte al experto en promoción o en servicio al cliente de línea"
  },
  "expectedEarningsTitle": "Genes esperados",
  "rechargeDesc": {
    "nav": "Recargar la descripción",
    "p1": "Ahora tenemos muchos empleados completando tareas al mismo tiempo.",
    "p2": "Un vendedor no puede proporcionar cuentas suficientes",
    "p3": "Entonces publicamos nuestra cuenta de vendedor en otro espacio en blanco",
    "p4": "Cuando necesite completar la tarea, comuníquese con el soporte de ventas para registrar su canal de pago"
  },
  "chat": {
    "list": {
      "nav": "Mensaje",
      "hours": "{H} horas",
      "minutes": "{m} minutos",
      "ago": "{Tiempo} detrás",
      "image": "imagen"
    },
    "room": {
      "msgPlaceholder": "Ingrese un mensaje ...",
      "send": "Mandar",
      "sender": "Remitente",
      "sendTime": "Entregar",
      "content": "Feliz"
    }
  },
  "Login.Daftar.selectCurrency": "Elija la moneda",
  "mine.totalBalance": "balance total",
  "newBank": {
    "usdt": {
      "name": "Nombre",
      "currency": "Moneda",
      "chainType": "Tipo de cadena",
      "depositAddress": "Dirección de depósito",
      "submit": "mandar"
    },
    "walletList": {
      "pageTitle": "Gestión de retiros",
      "name": "Alias ​​de cartera:",
      "coinType": "Moneda:",
      "chainType": "Tipo actual:",
      "walletAddress": "DIRECCIÓN:",
      "addDate": "fecha:",
      "btnText": "Agregue la dirección de la cartera",
      "deleteConfirmText": "¿Estás seguro de que quieres eliminar la dirección de la cartera?",
      "btnConfirm": "Borrar",
      "btnCancle": "Mancha",
      "successHint": "Exitoso"
    },
    "withdrawNum": "Número de retiros",
    "AllOut": "Todo",
    "balance": "Saldo general",
    "handlingFee": "Tasa de manejo",
    "actualAmount": "Cantidad actual"
  },
  "hashPlaceholder": "Por favor escriba hash",
  "recharge.status.error": "La creación de órdenes virtuales de Amazon falló, envíe el valor correcto del hash y el valor del elemento",
  "recharge.actualAmount": "Cuenta real",
  "login.userAgreement": "Término de acuerdo de usuario",
  "login.title1": "Inscribirse",
  "login.title2": "¡Bienvenido de nuevo!",
  "registe.title1": "Inscribirse",
  "registe.title2": "¡Bienvenido de nuevo!",
  "mine.credit": "Crédito",
  "page.checkin": "Registrarse",
  "checkin.submit": "Registrarse",
  "checkin.success": "Suscribirse con éxito",
  "checkin.fail": "La entrada falló",
  "todayChecked": "Usted firmó, ¡por favor no repita la operación!",
  "submit.checked": "firmado",
  "hint.inviteBanned": "Lo sentimos, ¡su cuenta no ha abierto una cuenta familiar, comuníquese con el servicio al cliente o a su mentor para que se registre para la apertura!",
  "newTab.blindBox": "caja ciega ",
  "blindBox.submit": "Empezar loteria",
  "blindBox.times": "Oportunidad de abrir un cofre: {__times__} veces",
  "blindBox.records.title": "Mi registro de victorias",
  "banEditBankHint": "La tarjeta bancaria es información importante, comuníquese con el servicio al cliente si necesita modificarla.",
  "level.order_num": "mayor logro",
  "drawNum": "Número de caja ciega",
  "recharge.good_title": "productos",
  "recharge.blindBoxIncome": "Receta de caja ciega",
  "recharge.amount": "Cantidad",
  "recharge.bonusRatio": "Tasa de bonificación",
  "submit": {
    "desc": {
      "1": "Después de completar el producto del evento, obtendrá una oportunidad de bonificación de sorteo de caja ciega, y el rango de bonos es del 10-50% del valor del producto.",
      "2": "Basado en la tecnología LBS, el sistema coincide automáticamente con productos y oportunidades de lotería de cajas ciegas a través de la nube.",
      "3": "Para evitar la supervisión de la plataforma, si el pedido no se confirma y envía dentro de los 30 minutos, el sistema congelará el monto del pedido.",
      "sub_1": "Por ejemplo, si paga un monto de pedido de R $ 3000, la relación de bonificación de caja ciega es del 10%-50%, y el rango de bonificación real es de R $ 300-1500, lo que significa que su retiro mínimo es de R $ 3300 y el retiro máximo esR $ 4500.",
      "note": "Lea cuidadosamente el contenido relevante:",
      "note_sub": "Después de hacer coincidir el pedido para el usuario, Shopee enviará la información del pedido a los antecedentes del comerciante.Si el usuario no envía un pedido dentro de los 30 minutos, el sistema se congelará el monto del pedido.Para evitar la supervisión del sistema, acepte después de la confirmación, ¡todas las tareas no se pueden cancelar o reemplazar después de la aceptación!Si hay otros problemas que deben abordarse, haga clic para dejar de realizar un pedido a tiempo para evitar la congelación de horas extras, gracias por su apoyo."
    }
  },
  "drawNine": {
    times: "Oportunidad de abrir el cofre del tesoro",
    prizeHint: "bono de caja ciega",
    percentHint: "Ganador 100%"
  },
  "hint.nullOfDraw": "No tienes oportunidad de dibujar",
  "hint.loginNotRead": "Lea el acuerdo y vuelva a intentarlo!",
  "Home.WithdrawSu.Balance": "Efectivo a cuenta",
  "Hint.updateText": "Actualice a la última versión.",
  "pages.editPhone": "modificación de número de teléfono",
  "editPhone": {
    label: "número de teléfono",
    placeholder: "número de teléfono",
    hint: "Por favor, introduzca su número de teléfono"
  },
  "Button.submit": "Mandar",
  "pages.editEmail": "Buzón de enlace",
  "users.email": "Correo electrónico",
  "users.userphone": "Teléfono",
  "users.username": "Nombre de usuario",
  "editEmail": {
    label: "Correo electrónico",
    placeholder: "Correo electrónico",
    hintRequire: "Por favor, introduzca la dirección de correo electrónico",
    hintCorrect: "por favor ingrese su correo electrónico válido"
  },
  "personal.page.email": "Correo electrónico",
  "personal.page.phone": "Teléfono",
  banEditPhone: "El número de teléfono es información importante, si necesita modificarlo, comuníquese con el servicio al cliente.",
  banEditEmail: "El correo electrónico es información importante, si necesita modificarlo, comuníquese con el servicio al cliente.",
  banEditUserInfo: "La información importante no se puede modificar, si necesita modificarla, comuníquese con el servicio al cliente."
}
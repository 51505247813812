import { $post, $get, domain } from '@/utils/request'
import { cookie } from '@/utils/tools'
import { stringify } from 'qs'
import axios from 'axios'
import { Toast } from 'vant'
import { router as $router } from '../router'
import { getLanguage } from '../i18n'



export const GETGIFT_LIST = () => new Promise(async (resolve, reject) => {
  try {
    const res= await $post('/task/gift/lists',{page_size: 50})
    const {ret} = res.data;
    if(ret == 1){
      return resolve(res.data)
    }
    return reject(res.data)
  } catch (error) {}
})


export const PAY_TASKORDER = (params) => {
  return new Promise(async (resolve, reject) => {
    const res = await $post('/task/payTaskOrder', params);
    const { ret } = res.data;
    if (ret == 1) {
      return resolve(res.data)
    }
    reject(res.data)
  })
}

export const BUY_GIFT = (params) => {
  return new Promise(async (resolve, reject) => {
    const res = await $post('/task/gift/buy ', params);
    const { ret } = res.data
    if (ret == 1) {
      return resolve(res.data)
    }
    return reject(res.data)
  })
}

export const SEND_GIFT = (params) => {
  return new Promise(async (resolve, reject) => {
    const res = await $post('/task/gift/send', params);
    const { ret } = res.data
    if (ret == 1) {
      resolve(res.data)
    }
    reject(res.data)
  })
}

export const GIFT_LOG = (params) => {
  return new Promise(async (resolve, reject) => {
    const res = await $post('/task/gift/log', params);
    const { ret } = res.data
    if (ret == 1) {
      resolve(res.data)
    }
    reject(res.data)
  })
}

export const MY_GIFT = () => {
  return new Promise(async (resolve, reject) => {
    const res = await $post('/task/gift/my');
    const { ret } = res.data;
    if (ret == 1) {
      resolve(res.data)
    }
    reject(res.data)
  })
}

export const SHOP_LIST = () => {
  return new Promise(async (resolve, reject) => {
    const res = await $post('/task/timerand/product');
    const { ret } = res.data;
    if (ret == 1) {
      return resolve(res.data);
    }
    return reject(res.data);
  })
}

export const INIT = (key = '') => {
  return new Promise(async (resolve, reject) => {
    const res = await $get(`/users/third/login?key=${key}`);
    const { ret } = res.data;
    if (ret == 1) {
      resolve(res.data);
    }
    reject(res.data)
  })
}

export const GET_TASK = () => {
  return new Promise((resolve, reject) => {
    $post('/task/tasks').then(res => {
      const { data: { ret } } = res
      if (ret == 1) {
        return resolve(res.data)
      }
      return reject(res.data)
    }).catch(err => {
      return reject(err.data)
    })
  })
}

// 登录
export const LOGIN = (params) => $post('/users/login', params)

// 发验证码
export const SEND_SMS_CODE = (params) => {
  return $get('/users/sms', params)
}

// 余额宝
export const YUEBAO_INFO = () => {
  return $get('/yuebao/transfer/in')
}

export const SET_GIFT = (params) => {
  return $get('/users/time/gift', params)
}

// 获取配置
export const GET_CONFIG_INFO = (params) => {
  return $get('/other/config', params)
}

// 获取银行卡信息
export const GET_USER_BANK_INFO = (params) => {
  return $get('/users/bank', params)
}

// 提交银行卡信息
export const COMMIT_USER_BANK_INFO = (params) => {
  return $post('/users/bank/add', params)
}

// 提现
export const WITHDRAW = (params) => {
  return $post('/users/extract', params)
}

// 设置/修改支付密码
export const SETTING_PAY_PDW = (params) => {
  return $post('/users/edit/paypass', params)
}

// 提现列表
export const GET_WITHDRAW_LISTS = (params) => {
  return $post('/users/extract/record', params)
}

/**
 * 银行卡相关 start 2022-12-03
 */

// 获取银行卡信息
export const GET_USERS_BANK = (params) => $get('/users/bank', params)

// 刪除钱包
export const DELETE_MONEY_WALLET = (params) => $get('/users/bank/del', params)

// 获取分享配置
export const GET_SHARE_GIFT = () => {
  return $get('/users/share/gift')
}

// 充值
export const RECHARGE_COMMIT = (params) => {
  return $get('/pay/submit', params)
}

// 充值列表
export const GET_RECHARGE_LISTS = (params) => {
  return $post('/pay/record', params)
}

export const GET_USER_INFP = () => {
  return $post('/users/info')
}

// 查询用户信息
export const getUserInfoNew = (params) =>
  $post('/users/info', params)
    .then((r) => {
      if (cookie.get('token')) {
        if (r.data.ret === 1) {
          cookie.set('userInfo', JSON.stringify(r.data.data))
          cookie.set('token', r.data.data.token)
          return Promise.resolve(r.data)
        }
      }
      return Promise.reject(r.data)
    })
    .catch((e) => {
      console.error(e)
      return Promise.reject(e)
    })

// 获取充值list
export const GET_RECHARGE_LIST = (params) => {
  return $post('/pay/lists', params)
}

// 账变list
export const GET_MONEY_LIST = (params) => {
  return $post('/users/account/lists', params)
}

// 获取用户协议
export const GetUserAgreement = () => $post("/other/config", { act: "agreement" })

/**
 * 兑换余额
 * @param {*} number 兑换数量
 */
export const withdrawalBalanceApi = (number) =>
  $get('/users/ptomoney', { number })

export const Api = {
  baseUrl: domain + 'api/',
  /**
   * 跳转客服
   */
  toService() {
    function getUser() {
      return new Promise((resolve, reject) => {
        const userLoacl = localStorage.getItem("userInfo")
        if (!!userLoacl) {
          return resolve(JSON.parse(userLoacl))
        }
        getUserInfoNew().then(res => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    }
    const getService = () => new Promise((resolve, reject) => {
      this.getNotice(res => {
        resolve(res)
      }, err => {
        reject(err)
      })
    });
    Promise.all([getUser(), getService()]).then(res => {
      const [userInfo, { service_url }] = res;
      let hasParamsFlag = false;
      if (service_url.indexOf("?") !== -1) {
        hasParamsFlag = true;
      }
      if (!!service_url) window.location.href = `${service_url}${hasParamsFlag ? "&" : "?"}lan=${getLanguage()}&name=${userInfo.username}(id:${userInfo.id})`
    }).catch(err => {
      getService().then(({ service_url }) => {
        location.href = service_url;
      })
      console.error(err);
    })
  },
  /**
   * 获取公告接口
   * @param {*} success
   * @param {*} fail
   */
  getNotice(success, fail) {
    $get('/other/config', { act: 'system' })
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err)
        }
      })
  },
  /**
   * 获取Banner图接口
   * @param {*} success
   * @param {*} fail
   */
  getBanner(success, fail) {
    // if (sessionStorage.getItem('bannerArr')) {
    //   success(JSON.parse(sessionStorage.getItem('bannerArr')))
    // } else {
    $get('/content/slide', { position: 'home' })
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
            // sessionStorage.setItem('bannerArr', JSON.stringify(res.data.data));
          } else {
            if (fail) {
              fail(res.data.msg)
            }
          }
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err)
        }
      })
    // }
  },
  commonApi: {
    get(url, data, success, fail, noneLoading, header) {
      $get(url, data, noneLoading, header)
        .then((res) => {
          if (res.ret == 1) {
            if (success) success(res)
          } else if (res.ret == -1 && noneLoading) {
            uni.showToast({
              title: res.msg,
              duration: 800,
              icon: 'none',
            })
            setTimeout(() => {
              uni.navigateTo({
                url: '/pages/login/login',
              })
            }, 800)
          } else {
            if (fail) fail(res.msg)
          }
        })
        .catch((err) => {
          if (fail) fail(err)
        })
    },
    post(url, data, success, fail, header) {
      $post(url, data, header)
        .then((res) => {
          if (res.ret != 0 && res.ret != -1) {
            console.log(res)
            if (success) success(res)
          } else {
            if (fail) fail(res.msg)
          }
        })
        .catch((err) => {
          if (fail) fail(err)
        })
    },
  },
  // 读信息
  readNotice(ids, success, fail) {
    this.commonApi.post(
      '/users/message/reader',
      {
        ids: ids,
      },
      success,
      fail
    )
  },
  /**
   * 获取等级配置接口
   * @param {*} success
   * @param {*} fail
   */
  getLevel(success, fail) {
    // if (sessionStorage.getItem('levels')) {
    //   success(JSON.parse(sessionStorage.getItem('levels')))
    // } else {
    $post('/task/level', {}, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
            // sessionStorage.setItem('levels', JSON.stringify(res.data.data))
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err)
        }
      })
    // }
  },
  /**
   * 获取用户信息接口
   * @param {*} success
   * @param {*} fail
   * @param {*} wait
   * @param {*} token
   */
  getUserInfo(success, fail, wait, token) {
    Promise.all([GET_USER_INFP(), $post('/task/user', {}, {}, token ? token : cookie.get('token'))]).then((res) => {
      let result = {};
      let flag = true;
      let errorMsg = "";
      for (let i = 0, len = res.length; i < len; i++) {
        const { data: { ret, msg, data } } = res[i];
        if (ret == 1) {
          result = { ...result, ...data }
        } else {
          flag = false;
          errorMsg = msg;
        }
      }
      if (flag) {
        if (success) {
          success(result)
          cookie.set('userInfo', JSON.stringify(result))
          return Promise.resolve(result)
        }
        if (fail) fail(errorMsg)
        return Promise.reject(errorMsg)
      }
    }).then(() => {
      if (wait) {
        wait()
      }
    }).catch((err) => {
      console.error("获取用户信息错误", err)
      if (fail) {
        fail(err)
      }
    })
    return
    if (cookie.get('userInfo')) {
      let userInfo = JSON.parse(cookie.get('userInfo'))
      this.updateUserInfo();
      // if (typeof userInfo.level == 'number') {
      //   if(success){
      //     success(userInfo)
      //   }
      // } else {
      $post('/task/user', {}, {}, token ? token : cookie.get('token'))
        .then((res) => {
          if (res.data.ret == 1) {
            if (success) {
              // userInfo.level = res.data.data.level;
              // userInfo.order_num = res.data.data.order_num;
              // userInfo.complete_order = res.data.data.complete_order;
              // userInfo.money = res.data.data.money;
              // userInfo.task_price = res.data.data.task_price;
              // userInfo.task_status = res.data.data.task_status;
              // userInfo.frozen_price = res.data.data.frozen_price;
              // userInfo.frozen_rate_price = res.data.data.frozen_rate_price;
              // userInfo.complete_total_price = res.data.data.complete_total_price;
              // userInfo.complete_yesterday_price = res.data.data.complete_yesterday_price;
              // userInfo.order_num = res.data.data.order_num;
              // userInfo.money_locked = res.data.data.money_locked
              // userInfo.userimg = res.data.data.userimg
              userInfo = { ...userInfo, ...res.data.data }
              success(userInfo)
              cookie.set('userInfo', JSON.stringify(userInfo))
            }
          } else {
            if (fail) {
              fail(res.data.msg)
            }
          }
          return res
        })
        .then((res) => {
          if (wait) {
            wait()
          }
        })
        .catch((err) => {
          if (fail) {
            fail(err)
          }
        })
      // }
    } else {
      if (fail) {
        fail('Not logged in!')
        // fail();
      }
    }
  },
  // 更新用户信息
  async updateUserInfo(success, fail, token) {
    try {
      const users = await GET_USER_INFP()
      const { ret, msg, data } = users.data
      if (ret == 1) {
        let userInfo = data
        $post('/task/user', {}, {}, token ? token : cookie.get('token'))
          .then((res) => {
            if (res.data.ret == 1) {
              userInfo = { ...userInfo, ...res.data.data }
              cookie.set('userInfo', JSON.stringify(userInfo))
              if (success) {
                !!success && success(userInfo)
              }
            } else {
              if (fail) {
                fail(res.data.msg)
              }
            }
            return res
          })
          .catch((err) => {
            if (fail) {
              fail(err)
            }
          })
      }
    } catch (error) { }
    if (cookie.get('userInfo')) {
      let userInfo = JSON.parse(cookie.get('userInfo'))
      // if (typeof userInfo.level == 'number') {
      //   if(success){
      //     success(userInfo)
      //   }
      // } else {
      $post('/task/user', {}, {}, token ? token : cookie.get('token'))
        .then((res) => {
          if (res.data.ret == 1) {
            if (success) {
              userInfo = { ...userInfo, ...res.data.data }
              success(userInfo)
              cookie.set('userInfo', JSON.stringify(userInfo))
            }
          } else {
            if (fail) {
              fail(res.data.msg)
            }
          }
          return res
        })
        .then((res) => {
          if (wait) {
            wait()
          }
        })
        .catch((err) => {
          if (fail) {
            fail(err)
          }
        })
      // }
    } else {
      if (fail) {
        fail('Not logged in!')
        // fail();
      }
    }
  },

  getUserShareInfo(obj, fail, wait, token) {
    if (cookie.get('userInfo')) {
      let userInfo = JSON.parse(cookie.get('userInfo'))
      $post('/users/share/gift', obj, {}, token ? token : cookie.get('token'))
        .then((res) => {
          console.log(res.data)
          if (res.data.ret == 1) {
            console.log('success')
            localStorage.setItem('teamList', JSON.stringify(res.data))
            if (obj) {
              Toast('success')
              localStorage.setItem('teamList', JSON.stringify(res.data))
            }
          } else {
            if (fail) {
              fail(res.data.msg)
            }
          }
          return res
        })
        .then((res) => {
          if (wait) {
            wait()
          }
        })
        .catch((err) => {
          if (fail) {
            fail(err)
          }
        })
      // }
    } else {
      if (fail) {
        fail('Not logged in!')
        // fail();
      }
    }
  },
  /**
   * 获取订单接口
   * @param {*} data
   * @param {*} success
   * @param {*} fail
   */
  getTask(data, success, fail) {
    $post('/task/getTask', data, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err)
        }
      })
  },
  // 获取列表公用
  getListCom(url, params) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await $post(url, params, {}, cookie.get('token'))
        const { ret, msg, data } = res.data
        if (ret == 1) {
          resolve(data)
          return
        }
        reject(msg)
      } catch (error) { }
    })
  },
  /**
   * 获取订单列表接口
   * @param {*} data
   * @param {*} success
   * @param {*} fail
   */
  getTaskOrder(data, success, fail) {
    if (!data) {
      data = new Object()
    }
    data.page_size = 10
    $post('task/getTaskOrder', data, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        fail(err)
      })
  },
  /**
   * 支付订单接口
   * @param {*} id
   * @param {*} success
   * @param {*} fail
   */
  payTaskOrder(data, success, fail) {
    $post('task/payTaskOrder', data, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data)
          }
        } else {
          if (fail) {
            fail(res.data)
          }
        }
      })
      .catch((err) => {
        if (fail) {
          fail(err)
        }
      })
  },
  /**
   * 修改用户资料接口
   */
  editUserInfo(data, success, fail) {
    $post('users/edit', data, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          this.updateUserInfo()
          if (success) {
            success(res.data.data)
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        if (fail) {
          fail(res.data.msg)
        }
      })
  },
  /**
   * 修改头像的接口
   * @param {*} data
   * @param {*} success
   * @param {*} fail
   */
  editAvatar(data, success, fail) {
    $post('users/edit/avatar', data, {}, cookie.get('token')).then((res) => {
      console.log(res)
      this.updateUserInfo()
      Toast(res.data.msg)
    })
    // $post('users/edit/avatar',data,{},cookie.get('token'),{
    //   'Content-Type': 'multipart/form-data'
    // }).then(res => {
    //   if(res.data.ret == 1){
    //     if(success){
    //       success(res.data.data)
    //     }
    //   }else{
    //     if(fail){
    //       fail(res.data.msg);
    //     }
    //   }
    // }).catch(err => {
    //   if(fail){
    //     fail(err);
    //   }
    // })
  },
  /**
   * 获取短信验证码接口
   * @param {*} data
   * @param {*} success
   * @param {*} fail
   */
  getSMS(data, success, fail) {
    $post('users/sms', data, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        fail(err)
      })
  },
  editPwd(data, success, fail) {
    $post('users/edit/userpass', data, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        fail(err)
      })
  },
  /**
   * 获取首页菜单的参数
   * @param {Function} success
   * @param {Function} fail
   */
  getHomeConfig(success, fail) {
    $post('content/sale', { position: 'home-item' }, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        fail(err)
      })
  },
  /**
   * 获取首页菜单-帮助的参数
   * @param {Function} success
   * @param {Function} fail
   */
  getHomeHelp(success, fail) {
    $post('content/sale', { position: 'home-help' }, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        fail(err)
      })
  },
  getWithdrawRate(success, fail) {
    $post('/users/extract/rate', {}, {}, cookie.get('token'))
      .then((res) => {
        if (res.data.ret == 1) {
          if (success) {
            success(res.data.data)
          }
        } else {
          if (fail) {
            fail(res.data.msg)
          }
        }
      })
      .catch((err) => {
        console.error("获取提现汇率错误: ", err);
        fail && fail(err)
      })
  },
}

/*
 * @Author: your name
 * @Date: 2022-04-22 16:04:09
 * @LastEditTime: 2022-04-26 14:28:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \app\src\main.js
 */
import Vue from 'vue'
import App from './App'
import store from './store'
import { router } from './router'
import i18n, { getLanguage } from './i18n/index'
import VueI18n from 'vue-i18n'
import Module from './utils/Module'
import './styles/index.css'
import VueClipboard from 'vue-clipboard2'
import '@/assets/iconfonts/iconfont.css'
import { Api } from '@/api'
Vue.use(VueClipboard)
import map from './utils/map'
Vue.prototype.$map = map;

import { cookie } from '@/utils/tools'

import { Loading } from 'vant'
import { DropdownMenu, DropdownItem } from 'vant'
import verConfig, { ver } from '@/utils/verConfig'
import toFixed from '@/utils/toFixed'
import hasPaypass from './utils/hasPaypass'
import format from "./utils/format"
import formatMoney from './utils/formatMoney'
import Img from "./utils/ImgFormat"
import fixed from './utils/fixed'

const isDownloadFn = true
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Loading)
Vue.prototype.lan = getLanguage()
Vue.prototype.module = Module
Vue.prototype.$cookie = cookie
Vue.prototype.$api = Api;
Vue.prototype.$store = store;
Vue.prototype.$isDownloadFn = isDownloadFn;
Vue.prototype.verConfig = verConfig;
Vue.prototype.ver = ver;
Vue.prototype.toFixed = toFixed;
Vue.prototype.hasPaypass = hasPaypass;
Vue.prototype.$format = format;
Vue.prototype.$money = formatMoney;
Vue.prototype.$img = Img;
Vue.prototype.fixed = fixed;

Vue.filter("lan", (val) => i18n.t(val));

Vue.use(VueI18n, {
  i18n: (key, value) => i18n.t(key, value),
})

// 动态改标题
document.getElementsByTagName('title')[0].innerText = verConfig.title;
// 动态改图标
const icoLink = document.createElement('link');
icoLink.type = 'image/png';
icoLink.rel = 'icon';
const icon = ver;
icoLink.href = require(`@/assets/ico/${icon}.png`);
document.head.appendChild(icoLink);

// router.beforeEach((to, from, next) => {
//   if (to.meta.login) {
//     if (!cookie.get('token')) {
//       router.replace("login");
//       return
//     }
//   }
//   next()
// })

new Vue({
  router,
  store,
  i18n,
  el: '#app',
  render: (h) => h(App),
})

import { $post} from '@/utils/request'
export * from './tools'
export { default as request, $get, $post, $upload, dealError } from "./newRequest"

// export const emailRule = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const emailRule = /^[\w\d]+@[\w\d]+\.\w+$/;

export const count = (obj) => {
  try {
    if (typeof obj === 'undefined') {
      return 0
    }
    if (typeof obj === 'number') {
      obj += ''
    }
    if (typeof obj.length === 'number') {
      return obj.length
    } else {
      let i = 0,
        key
      for (key in obj) {
        i++
      }
      return i
    }
  } catch (e) {
    return 0
  }
}

export const setTimeoutName = (name, callback, timeout) => {
  let temp = randomString(16);
  let keyName = "setTimeoutName::" + name;
  cookie.set(keyName, temp)
  setTimeout(() => {
    if (temp === cookie.get(keyName)) {
      if (isFunction(callback)) {
        if (callback() === true) {
          setTimeoutName(name, callback, timeout);
        }
      }
    }
  }, timeout);
}

export const isObject = (obj) => {
  return isNullOrUndefined(obj) ? false : typeof obj === 'object'
}


export const isNullOrUndefined = (obj) => {
  return typeof obj === 'undefined' || obj === null
}

// export const getUserInfoNew = () => {
//   let token = cookie.get('token')
//   let platform = 'android'
//   let apiChannel = 'a'
//   let getLocalVersionName = '100'
//   let getImei = ''
//   let getIfa = ''
//   let header = {
//     token,
//     platform,
//     'platform-channel': apiChannel,
//     'platform-release': getLocalVersionName,
//     'platform-imei': getImei,
//     'platform-ifa': getIfa,
//     'content-type': 'application/json',
//   }
//   return $post({
//     url: `${domain}/users/info`,
//     method: 'GET',
//     dataType: 'json',
//     header,
//     timeout: 10000,
//   })
// }
import { $post, $get, dealError } from "@/utils"
import store from "../../../store";
let loading = false;
let reqNum = 0;
const coins = {
  state: {
    coins: [],
    pageSize: 10,
    buy: {
      page: 0,
      total: 0,
      hasMore: true,
      data: []
    },
    sell: {
      page: 0,
      total: 0,
      hasMore: true,
      data: []
    }
  },
  mutations: {
    SET_COINS(state, data) {
      state.coins = data;
    },
    SET_LIST(state, { type, data, hasMore, total }) {
      if (state[type].page === 1) {
        state[type].data = data;
      } else {
        state[type].data = [...state[type].data, ...data];
      }
      state[type].hasMore = hasMore;
      state[type].total = total;
    },
    SET_PAGE(state, { type, isPlus = true }) {
      if (isPlus) {
        state[type].page += 1;
      } else {
        state[type].page--;
      }
    },
    REFRESH_ACCOUNT(state) {
      const initData = {
        page: 0,
        total: 0,
        hasMore: true,
        data: []
      }
      state.buy = { ...initData };
      state.sell = { ...initData };
    }
  },
  getters: {
    coins(state) {
      if (!state.coins.length) {
        store.dispatch("getCoins");
      }
      return state.coins;
    },
    coinList(state) {
      return (type) => {
        return state[type].data;
      }
    },
    coinListHasMore(state) {
      return (type) => {
        return state[type].hasMore;
      }
    }
  },
  actions: {
    getCoins({ commit }) {
      return new Promise((resolve, reject) => {
        if (loading || reqNum > 4) return;
        reqNum++;
        loading = true;
        $get("/ptop/coins").then(({ ret, msg, data }) => {
          commit("SET_COINS", data.coins || [])
        }).catch((err) => {
          reqNum--;
          dealError(err);
        })
      })
    },
    getCoinsLists({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        const { type } = params;
        const typeData = state[type];
        commit("SET_PAGE", { type });
        if (typeData.hasMore) {
          $get("/ptop/lists", {
            trade_method: type,
          }).then(({ ret, data }) => {
            const { currentPage, lists, total, hasMorePages } = data;
            commit("SET_LIST", { type, data: lists, total, hasMore: hasMorePages });
          }).catch(err => {
            console.log(err, ">>>");
            commit("SET_PAGE", { type, isPlus: false });
          }).finally(() => {
            resolve();
          })
        }
      })
    }
  },
}
export default coins

export default {
  // 公共的key
  "common": {
    "unrealized": "아직 구현되지 않음",
    "all": "모두",
    "distributionTip": "0 위안 배송 0 배송비",
    "easeBack": "안도하는",
    "addCarSuccess": "장바구니에 성공적으로 추가",
    "deleteConfirm": "선택한 항목을 삭제하시겠습니까??"
  },
  "goodsDetail": {
    "goodsDetail": "제품 세부 정보",
    "shopping": "찰칵",
    "end": "끝",
    "sold": "판매",
    "goodsTips": "이 제품은 500g/서빙으로 가격이 책정되며 차이는 500g 미만으로 차이로 반환됩니다.",
    "quickTime": "29분 만에 배달",
    "specifications": "사양",
    "preservationConditions": "보관 조건",
    "coldStorage": "냉각",
    "shelfLife": "유통 기한",
    "day": "15 일",
    "addToCar": "쇼핑 카트에 추가하십시오"
  },
  "car": {
    "title": "쇼핑 카트",
    "delete": "삭제",
    "tip": "빈 장바구니~",
    "walk": "쇼핑하러 가라",
    "all": "모두 선택",
    "guess": "당신은 또한 좋아할 수 있습니다"
  },
  // 首页
  home: {
    "HallofFame": "명예의 전당",
    "chooseLocation": "위치를 선택하십시오 ..",
    "more": "더",
    "home": "첫 장",
    "category": "분류",
    "eat": "무엇을 먹을지",
    "cart": "쇼핑 카트",
    "mine": "나의 것",
    "inputGoodsName": "제품명 입력",
    "vipDesc": "가입 회원 · 매년 806 위안을 절약 할 것으로 예상됩니다.",
    "vipSubDesc": "50 % 할인 카드>",
    "specialZone": "특징적인 영역",
    "foodie": "음식 축제",
    "dinner": "저녁",
    "popularity": "인기",
    "goodChoose": "선택",
    "limitBuy": "플래시 세일",
    "bottomTip": "나는 결론이있다",
    "chooseAddress": "주소를 선택하십시오",
    "currentLocation": "현재 위치",
    "nearbyAddress": "주변 주소"
  },
  // 分类
  "category": {
    "tip": "제품 이름을 입력하십시오",
    "bottomTip": "끝, 다른 범주를보십시오"
  },
  "eat": {
    "all": "모두",
    "close": "치워",
    "pleaseHolder": "레시피, 재료 검색",
    "menu": "오늘의 메뉴",
    "menuDevide": "메뉴 분류",
    "recent": "최근에 발견",
    "tip_bottom": "결국 당기지 마세요.!",
    "tip": "즐겨찾기가 아직 구현되지 않았습니다.~"
  },
  // 我的
  mine: {
    "title": "나의 것",
    "phone": "전화 번호",
    "login": "즉시 로그인",
    "myOrder": "내 주문",
    "allOrder": "모든 주문 보기",
    "myBill": "내 할인 쿠폰",
    "myLocation": "내 배송 주소",
    "myCar": "내 영주권",
    "servier": "고객 서비스에 문의",
    "servierTime": "고객 서비스 시간 07 : 00-22 : 00",
    "feedback": "피드백",
    "switchLanguage": "언어",
    "chinese": "중국인",
    "english": "영어",
    "version": "현재 버전",
    "waitingPay": "지불됩니다",
    "waitingReceive": "보류 중인 영수증",
    "waitingComment": "논평",
    "waitingFeedback": "판매/환불 후",
    "unrealized": "아직 환불 페이지가 열리지 않았습니다.!",
    "tip2": "작은 별이 있다는 것을 잊지 마십시오. OH ~ ~",
    "tip3": "💘많은 관심 부탁드립니다💘",
    "tip4": "GitHub에서 검색\nGeek-James/ddBuy\n🦉최적화 제안을 환영합니다🙉",
    "feedbackTip": "아직 관련 주문이 없습니다.",
    "cardPrise": "그린 카드 가격",
    "moreMessage": "더 많은 특권을 얻으려면 계속 지켜봐주십시오 ...",
    "openCard": "그린 카드 열기",
    "recomend": "세 가지 주요 권리와 관심사를 즐기기 위해 영주권을 열면 싱글 당 6.66 위안을 절약 할 것으로 예상됩니다.",
    "cardTip1": "전용쿠폰",
    "cardTip2": "독점 특별 가격",
    "cardTip3": "2 배 포인트",
    "cardTip4": "더 많은 특권",
    "vipType": "50% 할인",
    "vipTime": "연간 카드 365일",
    "vipRecommend": "약 0.24 위안/일에 해당합니다",
    "prise1": "¥88",
    "prise2": "¥188",
    "vipType1": "7% 할인",
    "vipTime1": "지카 90일",
    "vipRecommend1": "약 0.33위안/일에 해당",
    "prise11": "¥30",
    "prise12": "¥45",
    "payMethod": "지불 방법",
    "wechatPay": "위챗페이",
    "aliPay": "Alipay에 의해 지불하십시오",
    "huabeiPay": "화북결제",
    "payImmeatally": "즉시 지불하십시오",
    "cardUeless": "영주권이 열리지 않았습니다",
    "tip": "나는이 단계에 도달 할 수있다! 후속 장관들은 그것을 할 수 없다 ~~",
    "changeNickName": "사용자 이름 변경",
    "confirm": "저장",
    "nickName": "새 닉네임을 입력하십시오",
    "personal": "개인 데이터가 성공적으로 수정되었습니다!",
    "message": "닉네임을 입력해주세요",
    "myBills": "내 할인 쿠폰",
    "persent": "520IT Exchange를 입력하십시오",
    "bill": "쿠폰",
    "condition": "임계 값이 없습니다\n대부분의 할인 1.5 위안",
    "rmb": "원",
    "myOrders": "내 주문",
    "itemsTitle": [
      "全部",
      "지불됩니다",
      "보류 중인 영수증",
      "논평"
    ],
    "personalInfo": "개인 정보",
    "head": "화신",
    "user_name": "별명",
    "userSex": "성별",
    "Brithday": "생신",
    "phoneNumber": "전화 번호",
    "noInput": "채워지지 않은",
    "logout": "로그아웃",
    "infoTip": "개인 데이터 수정이 성공적으로 수정됩니다",
    "loginInfo": "로그인에서 인출 했습니까?",
    "infoSuccess": "성공적으로 종료",
    "year": "년도",
    "month": "달",
    "day": "낮",
    "man": "잘 생긴 남자",
    "woman": "아름다움",
    "greenCard": "그린 카드",
    "greenCardPower": "그린 카드 특전",
    "fiftypecert": "그린카드 50% 할인",
    "cardRecoamnd": "매일 할인받을 수 있는 그린카드 전용 쿠폰",
    "TodayExclusivecoupon": "오늘의 전용 쿠폰",
    "everyUpadate": "매일 0시에 업데이트하십시오",
    "to": "가득한",
    "toUse": "형이상학 적 사용",
    "immeatallyGet": "지금 당장 받으세요",
    "weekBill": "이번주 한정 쿠폰",
    "cardTip7": "지금 당장 받으세요",
    "billTip": "그린 카드 전용 쿠폰 매일 할인 받기",
    "currentGoods": "현재 쇼핑",
    "cardShop": "오픈 그린 카드 쇼핑",
    "cardBack": "더 나은",
    "cardSpecialTip": "가속화 할 포인트를 즉시 열십시오",
    "cardSpecial": "영주권 독점 특별 제안",
    "cardPriseb": "180위안",
    "cardPrisea": "88위안",
    "yearCard": "연간 카드",
    "openCards": "그린 카드 열기",
    "onece": "1번",
    "twice": "2 배"
  },
  // 订单
  order: {
    "outTimeGetMoney": "10분 이상 보상 포인트",
    "inputForm": "주문 채우기",
    "location": "배송지 선택",
    "arrivalTime": "배달 시간",
    "total": "흔한",
    "thing": "조각",
    "wechatPay": "위챗페이",
    "aliPay": "알리페이로 ​​결제",
    "huabeiPay": "화북결제",
    "use": "사용",
    "order12": "상환하다",
    "mark": "주목",
    "tip": "선택 사항, 당신은 우리에게 당신의 특별한 필요를 말할 수 있습니다",
    "goodsList": "상품 목록",
    "totalMoney": "상품의 양",
    "sendMoney": "분배 수수료",
    "point": "완전한",
    "order19": "실제로 지불",
    "pay": "실제로 지불",
    "sendForm": "주문을 제출",
    "selectArrivalTime": "배송 시간을 선택하십시오",
    "comfirm": "확신하는",
    "goods": "상품",
    "all": "흔한",
    "things": "부분",
    "sigalPrice": "단가:",
    "numbers": "수량:",
    "addTip": "아직 주소를 추가하지 않았고 하나를 추가하십시오",
    "addLocation": "주소를 추가하십시오",
    "comfirmAndUse": "저장 및 사용",
    "editLocation": "편집기 주소",
    "myAddress": "내 주소",
    "settlement": "합의",
    "chooseGoods": "결제할 항목을 선택하세요.",
    "deliveryTime": "배달 시간을 선택하십시오",
    "today": "오늘",
    "tomorrow": "내일"
  },
  "Login": {
    "indexTitle": "登录",
    "Daftar": {
      "title": "注册",
      "phone": "전화 번호를 입력하십시오",
      "code": "请输入验证码",
      "password": "비밀번호를 입력하십시오",
      "againPassword": "확인하려면 암호를 다시 입력하십시오"
    },
    "Forgot": {
      "sendCode": "发送",
      "confirm": "提交",
      "goBack": "반품"
    },
    "nameInput": "전화 번호를 입력하십시오",
    "pwdInput": "비밀번호를 입력하십시오",
    "remenber": "记住密码",
    "loginButton": "登录",
    "registrasi": "등록하다"
  },
  "login": {
    "title": "登录",
    "phoneNumber": "전화 번호",
    "phoneNumberNotCorrect": "手机号格式不正确",
    "phoneNumberNotEmpty": "手机号不能为空",
    "passNumberNotEmpty": "비밀번호는 비어있을 수 없습니다",
    "phoneInput": "请输入手机号",
    "pass": "비밀번호",
    "passTip": "비밀번호를 입력하십시오",
    "varify": "请输入验证码",
    "otherMethods": "其他登录方式",
    "wechat": "微信登录",
    "qqchant": "QQ 로그인",
    "tipTile": "친절한 팁 :",
    "tipContent": "未注册的手机号，登录时将自动注册，且代表同意",
    "tip": "사용자 계약",
    "tipProcy": "隐私策略",
    "login": "로그인",
    "resgin": "등록하다",
    "passTip2": "请输入密码(不少于6位)",
    "sendVerify": "发送验证码",
    "hasSend": "已发送",
    "phoneVerify": "账号密码登录",
    "smsMessage": "短信验证码登录",
    "switchLoginMsg": "계정 비밀번호 로그인",
    "messageSuccess": "검증 코드가 성공했습니다. 입력 상자를 입력하십시오.",
    "pleaseInputCorrectPhoneNumber": "请输入正确的手机号",
    "pleaseInputCorrectVerifyumber": "请输入正确的验证码",
    "otherWechat": "微信登录-暂未完成",
    "otherQQ": "QQ 로그인은 아직 완료되지 않았습니다",
    "message": "验证码:"
  },
  "Hint": {
    "notMore": "더 이상은 없어",
    "loading": "加载中",
    "failed": "불합격",
    "SendCodeSuccess": "확인 코드가 성공적으로 전송되었습니다!",
    "phoneNot": "手机号/账号不能为空",
    "pwdInconformity": "两次密码输入不一致",
    "notInput": "입력 콘텐츠가 불완전합니다"
  },
  "newTab": {
    "home": "첫 장",
    "money": "재충전",
    "order": "주문하다",
    "my": "我的",
    "team": "팀"
  },
  "tab": {
    "make_money": "명령"
  },
  "Cart": {
    "zzInfo": {
      "buyIng": "구입",
      "buyFailed": "购买失败",
      "notLevel": "您的会员等级不足",
      "buyJurisdiction1": "금 회원 주문을 구매 해야하는 경우",
      "buyJurisdiction2": "如需购买白金会员订单",
      "buyJurisdiction3": "다이아몬드 회원 주문을 구매 해야하는 경우",
      "invite1": "3 명의 친구를 초대하여 함께 돈을 벌 수 있습니다",
      "invite4": "10 명의 친구를 초대하여 함께 돈을 벌 수 있습니다",
      "invite3": "邀请30个好友一起赚钱",
      "invite2": "邀请好友",
      "myMoney": "您的账户余额",
      "under": "低于",
      "notSendMoney": "不能进行交易",
      "goTopUp": "재충전으로 가십시오",
      "know": "내가 참조",
      "account": "합의"
    },
    "hint": {
      "p1": "1. 회원 수준마다 구매 주문이 다릅니다",
      "p2": "2.会员级别越高，可购买的订单佣金金额越高",
      "p3": "3. 구매 한 주문의 경우 언제든지 주문 상태를 모니터링 할 수 있습니다."
    },
    "state": {
      "all": "모두",
      "going": "进行中",
      "paid": "유급의",
      "frozen": "已冻结",
      "over": "已完成",
      "canceled": "취소 된"
    },
    "info": {
      "allMoney": "我的总资产฿",
      "dayAllOrder": "오늘의 총 주문",
      "dayTrustMoney": "今天委托金",
      "yesterdayMoney": "어제의 수입"
    },
    "text": {
      "one": "주문하다"
    }
  },
  My: {
    "download": "앱 다운로드",
    "personal": "개인 정보",
    "recharge": "재충전 기록",
    "withdrawal": "철회하다",
    "language": "语言设置",
    "logout": "로그 아웃하십시오",
    "MemberInformation": {
      "balance": "계정 잔액",
      "Withdraw": "철회하다",
      "Recharge": "재충전",
      "forzenAll": "총 냉동",
      "Invitation": "초대 코드"
    },
    "PersonalInformation": {
      "Page": {
        "Avatar": "화신",
        "Nickname": "昵称",
        "PhoneNumber": "전화 번호",
        "BankAccounts": "은행 카드",
        "Password": "비밀번호",
        "TransactionPassword": "트랜잭션 비밀번호",
        "title": "개인 정보"
      },
      "portrait": {
        "SetAvatar": "아바타 설정",
        "button": "개정하다"
      },
      "Name": {
        "ModifyNickname": "사용자 이름을 변경하십시오",
        "title": "사용자 이름을 변경하십시오",
        "name": "昵称",
        "Please": "수정하려는 별명을 입력하십시오"
      },
      "Password": {
        "title": "로그인 암호를 수정하십시오",
        "PleaseEnter": "수정하려는 비밀번호를 입력하십시오",
        "phone": "핸드폰 번호",
        "NewPhone": "请输入您的手机号码",
        "verificationCode": "확인 코드",
        "verificationCodeNote": "확인 코드를 입력하십시오",
        "ConfirmPassword": "비밀번호",
        "ConfirmPasswordNote": "수정하려는 비밀번호를 입력하십시오",
        "ButtonCode": "검증 코드를 가져옵니다",
        "Text": "비밀번호를 기억하십시오. 비밀번호를 잊어 버린 경우 고객 서비스에 문의하십시오.",
        "Button": "변경 사항을 확인하십시오"
      },
      "AccountData": {
        "title": "交易详情"
      }
    }
  },
  newAdd: {
    "all": "모두",
    "Completed": "완전한",
    "unfinished": "푼",
    "Income": "收入",
    "Expenditure": "지출",
    "GetCodeBtn": "검증 코드를 가져옵니다",
    "copySuccess": "복제",
    "copyError": "复制失败",
    "noLoginUserName": "请登录",
    "Withdraw": "철회하다",
    "Recharge": "재충전",
    "commission": "佣金",
    "single": "하나",
    "missionHall": "任务大厅",
    "videoTutorial": "비디오 튜토리얼",
    "helpCenter": "지원 센터",
    "inviteFriends": "邀请好友",
    "help1Title": "如何通过CG赚取佣金",
    "help1Context": "전자 상거래 소유자는 매장의 판매를 추가해야하므로 CG에 대한 E 스토어의 요구 사항 만 완료하고 주문을 완료하면됩니다. 소유자는 우리에게 커미션을 줄 것입니다. 각 사용자는 하루에 10 개의 주문 작업을받을 수 있습니다. 하루 안에 10%~ 30%커미션을받을 수 있습니다.",
    "help2Title": "如何增加任务数量",
    "help2Context": "由于兼职员工太多，公司规定所有在线兼职员工使用唯一的whatsapp进行联系和接收任务",
    "help3Title": "如何完成任务",
    "help3Context": "帮助大商家增加销售，并根据交易数量奖励你。对于每一个成功的订单，你赚取佣金，卖家赢得声誉。",
    "referralLink": "推荐链接",
    "copy": "복사",
    "blance": "균형",
    "todayOrder": "今日订单",
    "provided": "이 데이터가 제공됩니다",
    "Transaction": "交易",
    "Frozen": "불안",
    "Done": "完成",
    "Cancel": "取消",
    "ExpectedReturn": "预计返还:",
    "ImmediatePayment": "즉시 지불하십시오",
    "AvailableNum": "可用次数",
    "OrderImmediately": "马上配单",
    "MakeMoneyEveryDay": "매일 돈을 버십시오",
    "TodayRecord": "今日记录",
    "AllocatCommissions": "分配佣金",
    "FrozenAmount": "불편한 금액",
    "TaskMatch": "작업 할당",
    "AvailableBalance": "사용 가능한 잔액",
    "WaitDispatch": "等待分配任务,请联系店长!",
    "OrderDescription": "오늘의 기록",
    "CouldMatch": "작업은 시스템과 무작위로 일치합니다",
    "OrderPerDay": "하나.",
    "DescriptionRate": "주문 금액 주문에 대한 균일 한 커미션",
    "DescriptionPeriod": "。",
    "Description3": "系统基于LBS技术,通过云端自动匹配商品。",
    "Description4": "플랫폼 모니터링을 방지하기 위해 주문이 확인되어 20 분 이내에 전송되면 시스템은 주문 수를 동결합니다.",
    "Note": "注意事项:",
    "DescriptionNote": "当平台为用户匹配订单时,平台将订单信息提交给商家后台。如果用户在20分钟内未提交订单，订单金额将被系统冻结。为避免监管系统，会对用户账号进行评分，直接影响用户下一次撮合的时间和口碑！如有其他需要处理的，请及时点击停止下单，以免超时冻结",
    "OrderNum": "주문 번호:",
    "TotalOrderPrice": "총 가격:",
    "SubmitNow": "즉시 제출하십시오",
    "Record": "기록",
    "MemberAccount": "会员账户",
    "AccountPlaceholder": "계정을 입력하십시오",
    "RechargeAmount": "재충전 금액",
    "RechargeAmountPlaceholder": "재충전 금액을 입력하십시오",
    "SelectRechargeMethod": "选择充值方式",
    "NotBeenSentOrder": "명령을 내리지 않은 경우 직원에게 연락하여 주문을 보내십시오!",
    "TodayOrderOver": "오늘 주문이 완료되었습니다. 내일 다시 시도하십시오!",
    "NotLogin": "您尚未登录,请登录后重试!",
    "HaveNoteMethod": "有限开放，请联系业务团队！",
    "Login": "登录",
    "AccountRecord": "账变记录",
    "RealName": "실제 이름",
    "IFSCCODE": "IFSC",
    "BankName": "银行卡名称",
    "BankNum": "银行卡号",
    "Mobile": "휴대 전화",
    "MobileNum": "핸드폰 번호",
    "Email": "邮箱",
    "EmailRequired": "邮箱必填",
    "AddBankCardNote": "은행 카드는 중요한 정보입니다. 신중하게 작성하십시오!",
    "Submit": "제출하다",
    "AddBankCardTitle": "은행 카드를 추가하십시오",
    "BankCard": "지불 방법",
    "Name": "이름",
    "CardNum": "卡号",
    "Reading": "正在读取中...",
    "AllBalance": "전체 균형",
    "EnterWithdrawalAmount": "请输入提现金额",
    "AllOut": "모두",
    "AskNow": "철회하다",
    "EnterPwdPlaceholder": "비밀번호를 입력하십시오",
    "NotHaveCard": "你尚未绑定银行卡,您要去绑定银行卡吗?",
    "GetBankInfoFail": "은행 정보를 당기는 데 실패했습니다",
    "EnterValidPayPwd": "유효한 결제 비밀번호를 입력하십시오",
    "Balance": "균형",
    "Total": "总共",
    "total": "총",
    "records": "기록",
    "DisplayPerPage": "모든 페이지에 표시하십시오",
    "NullNum": "条",
    "pages": "페이지.",
    "toAudit": "待审核",
    "succeed": "成功",
    "RechargeRecordTitle": "재충전 기록",
    "rests": "其他",
    "Arrive": "到账",
    "forzenCommission": "冻结佣金",
    "companyIntro": "公司简介",
    "companyTitle": "塔塔购物中心是塔塔集团的一部分",
    "warmTitle": "친절한 팁",
    "companyText": "塔塔集团是印度最大的商业集团，成立于1868年，总部设在印度孟买。业务运作涉及七个业务领域:通信和信息技术、工程、材料、服务、能源、消费品和化工产品。作为印度快速发展的商业集团，塔塔集团在六大洲80多个国家拥有200多家子公司，产品和服务出口到85个国家。塔塔集团2020-2021财年的总收入为1009亿美元，其中58%来自海外业务。集团在全球拥有约45万名员工。自153年以来，塔塔这个名字在印度一直受到广泛尊重，因为它坚持良好的价值观和商业道德。在福布斯全球最受尊敬的公司中排名第11位。",
    "depositAddress": "재충전 주소",
    "rechargeSteps": "재충전 단계",
    "completePayment": "재충전",
    "rechargeNow": "立即充值",
    "rechargeCancel": "재충전을 취소하십시오",
    "bankName": "银行名称",
    "payee": "收款人",
    "cardNum": "收款账户",
    "reviewing": "검토 중",
    "remittance": "已汇款",
    "notThrough": "未通过",
    "OrderID": "주문 아이디",
    "toCutScreen": "사진을 사용할 수없는 경우 스크린 샷을 찍으십시오!",
    "cardTabBank": "银行",
    "cardTabUSDT": "USDT",
    "name": "이름",
    "chainType": "钱包类型"
  },
  "add": {
    "Commission": "今日未结算金额",
    "Yesterday": "어제 수익",
    "Cumulative": "历史累计首日",
    "Today": "오늘 예상 소득",
    "TeamNum": "유효한 사람들의 수가 초대되었습니다",
    "TeamMoney": "已获取收益",
    "Personalcenteraccountchangerecord": "变账记录",
    "lockedbalance": "불편한 금액",
    "Cumulativeinvitation": "누적 초대",
    "Validinvitation": "효과적인 초대",
    "Accumulatedrebate": "累计返利"
  },
  "mine": {
    "servier": "고객 서비스",
    "integral": '적분', // 积分展示
    "balance": '교환잔액', // 兑换余额
    "record": '교환 레코드', // 兑换记录
  },
  "Button": {
    "cancle": "取消",
    "confirm": "확신하는"
  },
  "home.btn.wallet": "재무 관리",
  "home.btn.recharge": "재충전",
  "home.btn.withdraw": "철회하다",
  "home.btn.invite": "邀请",
  "home.btn.message": "消息",
  "page.grab": "붙잡다",
  "orderCommission": "佣金",
  "commission": "수수료",
  "todayIncome": "今日收益",
  "personalEarnings": "개인 소득",
  "completedOrders": "已完成订单数",
  "lockedOrders": "锁定订单数",
  "unfinishOrder": "未完成订单数",
  "frozonAmount": "불편한 자금",
  "currentBalance": "균형",
  "page.mine": "账户明细",
  "mine.invitationCode": "초대 코드",
  "mine.serverCenter": "서비스 센터",
  "mine.tools.total": "总计",
  "mine.tools.financial": "재무 관리",
  "mine.tools.details": "계정 변경 기록",
  "mine.tools.freeze": "未结算金额",
  "mine.serviceTools.personal": "개인의",
  "mine.serviceTools.teamReport": "팀",
  "mine.serviceTools.invite": "家族アカウント",
  "mine.serviceTools.message": "정보",
  "mine.serviceTools.aboutUs": "우리에 대해",
  "mine.serviceTools.address": "주소",
  "mine.serviceTools.inviteTask": "작업을 초대하십시오",
  "page.team": "팀",
  "page.aboutus": "우리에 대해",
  "page.financial": "재무 관리",
  "page.financialRecord": "재무 순서",
  "hint.InternetErr": "네트워크 오류, 나중에 시도하십시오!",
  "totalDeposit": "总存款",
  "totalRevenue": "总收入",
  "btn.transferInto": "들어 오세요",
  "btn.transferOut": "转出",
  "page.transferInto": "들어 오세요",
  "page.transferOut": "나오십시오",
  "balanceTransfer": "사용 가능한 잔액",
  "availableBalance": "사용 가능한 잔액",
  "depositRate": "利息",
  "days": "하늘",
  "hint.success": "성공",
  "hint.fail": "불합격",
  "status.into": "들어 오세요",
  "status.out": "转出",
  "label.amount": "수량",
  "label.time": "时间",
  "dialog.title.inputPaypass": "输入支付密码",
  "dialog.nopaypass": "尚未设置支付密码,点击确定去设置支付密码!",
  "mine.serviceTools.lan": "언어",
  "checkLans": "切换语言",
  "mine.tools.commission": "佣金",
  "page.commissionRecord": "수수료",
  "noticeList.all": "모두",
  "noticeList.unread": "무모한",
  "successWithdrawof": "성공적인 철수",
  "home.title.notice": "주의",
  "home.title.patener": "파트너",
  "login.rememberPassword": "비밀번호를 기억하십시오",
  "page": {
    "cart": "订单",
    "order": "订单",
    "financing": "理财",
    "goods": "货物",
    "levelUpgrade": "提升等级",
    "PlatformIntroduction": "平台介绍",
    "RuleDescription": "规则",
    "AgentCooperation": "代理合作",
    "CommonProblem": "常见问题",
    "MemberUpgrade": "会员升级",
    "login": "登录",
    "forgot": "忘记密码",
    "registrasi": "注册",
    "record": "记录",
    "Recharge": "充值",
    "inviteFriends": "邀请",
    "Withdraw": "提现",
    "Service": "客服",
    "DialoguePage": "对话页面",
    "user": "个人",
    "RansferIn": "转入",
    "PersonalCenter": "个人中心",
    "Portrait": "头像",
    "Name": "名字",
    "Account": "账户",
    "Password": "密码",
    "PaymentCode": "支付密码",
    "Site": "网站",
    "AccountData": "账变记录",
    "RechargeRecord": "充值订单",
    "WithdrawalRecord": "提现订单",
    "InviteFriends": "邀请",
    "Announcement": "公告",
    "TheTeamReport": "团队报告",
    "BillRecord": "下注订单",
    "BankCardAdd": "添加银行卡",
    "NewRecharge": "充值",
    "RechargeList": "充值订单",
    "newWithdraw": "提现",
    "withdrawList": "提现订单",
    "SettingPayPwd": "修改支付密码",
    "MakeMoney": "赚钱",
    "team": "团队",
    "qrPay": "二维码支付",
    "bankPay": "银行支付",
    "aboutus": "关于我们",
    "financial": "理财",
    "financialRecord": "理财记录",
    "TransferInto": "转入",
    "TransferOut": "转出",
    "CommissionRecord": "佣金",
    "Security": "安全",
    "Address": "地址",
    "Message": "消息",
    "InviteTask": "要求任务",
    "Notice": "公告",
    "modifyPaymentPassword": "修改支付密码"
  },
  "Home.WithdrawSu.Balance": "余额",
  copyLink: "复制链接",
  "teamReport": {
    title: "团队报告",
    subTitle: "下属团队概览",
    taskTitle: "邀请{num}人注册并完成订单",
    bonus: "奖金",
    Unfinished: "未完成",
    Getted: "已领取"
  },
  copy: "复制",
  address: {
    name: "名字",
    inputPlaceholder: "请输入",
    phone: "电话",
    areaPlaceholer: "收货地址信息",
    submit: "保存"
  },
  "My.PersonalInformation.Name.Modify": "修改",
  "password.old": "旧密码",
  "password.old.placeholder": "请输入您的旧密码",
  "password.confirmPassword": "确认密码",
  "password.confirmPassword.placeholder": "请再次输入新密码",
  "hint.passwordDiff": "两个密码输入不一致",
  "loginType": {
    "phone": "电话",
    "email": "电子邮件"
  },
  "Login.Daftar.email": "电子邮件",
  "Login.emailInput": "请输入你的电子邮箱",
  "My.PersonalInformation.Password.email": "电子邮件",
  "My.PersonalInformation.Password.NewEmail": "请输入您的手机或邮箱",
  "mine.serviceTools.certify": "确认",
  "Certification": {
    "type": {
      "id": "身份证号",
      "driver": "驾驶执照",
      "passport": "护照"
    },
    "name": "姓名",
    "license": "许可证号码",
    "upload": "上传图片",
    "submit": "提交",
    "incompleteInformation": "请完善信息后重试！",
    "submitSuccess": "认证信息已提交，请等待审核！",
    "photo": "身份证照片",
    "review": "审查",
    "verified": "经过验证"
  },
  "upload": {
    "uploading": "上传中...",
    "failed": "上传失败"
  },
  "login.forgetPassword": "忘记密码，请联系客服！",
  "newAdd.BankNum.placeholder": "示例：09XXXXXXXXX",
  "page.certification": "身份验证",
  "newAdd.page": "页",
  "newAdd.record": "记录",
  "emailFormatError": "请输入正确的电子邮件地址!",
  "urlParsePlaceholder": "请输入粘贴标识",
  "btn.Identify": "立即识别",
  "produceStatus.confirming": "待确认",
  "produceStatus.paying": "待支付",
  "produceStatus.paid": "已支付",
  "produceStatus.done": "完成",
  "produceStatus.cancle": "退款",
  "order.old": "高额订单",
  "order.new": "普通订单",
  "welfareDialogTitle": "Benefit Tips",
  "viGrabDesces": {
    "1": "每个订单都可以被选为幸运订单，佣金将提高到 {rate}%。 请联系您的推广导师申请进一步的推广任务",
    2: "帮助 Shopee 卖家完成虚拟订单创建，提高知名度并获得佣金",
    3: "收到订单后请尽快联系您的推广导师，提交订单数据并完成任务",
    4: "任务数量有限，接单时请注意导师的推广建议",
    5: "退款金额将在完成任务后5-10分钟内发放，如有问题请咨询专家或在线客服。",
    6: "完成Shopee分配的任务，提升VIP等级，获得更高的Fackbook任务佣金",
    7: "累计完成指定虾皮任务，可获得额外佣金。 具体奖励请咨询促销专员或在线客服"
  }
}

import { Dialog } from "vant"
import Vue from "vue"
import { Api } from "../api"
import { router } from "../router"

const hasPaypass = () => {
  return new Promise((resolve, reject) => {
    Api.getUserInfo((res) => {
      if (!!res.setpaypass) {
        resolve()
        return
      }
      Dialog.setDefaultOptions({ confirmButtonText: "confirm", cancelButtonText: "cancle" })
      Dialog.confirm({
        message: 'The payment password has not been set, click OK to go to the setting!',
      }).then(() => {
        router.push("PaymentCode")
        resolve()
      }).catch(() => {
        reject()
      })
    })
  })
}

export default hasPaypass